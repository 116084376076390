import { Message } from "@mui/icons-material";
import ProgramToDepartmentDetails from "layouts/CommonLayouts/MyOffices/ProgramToDepartmentDetails";

  
const routesDepartmentOfficeLevelCustom = [
  {
    type: "collapse",
    name: "Program To Department ",
    key: "ProgramToDepartment", 
    icon: <Message/>,
    route: "/my-offices/:officeId/Program-To-Department/:programToDepartmentId",
    component: <ProgramToDepartmentDetails/>,
    noCollapse: true,
  },
];

export default routesDepartmentOfficeLevelCustom;
