/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
// Settings page components
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import MDBadge from "components/MDBadge";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import {
  Add,
  ArrowBackIosNewSharp,
  Book,
  Check,
  Difference,
  ListAlt,
} from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import DashboardNavbar2 from "examples/Navbars/DashboardNavbar2";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { HttpService } from "Api-Services/httpService";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CommonApiServices } from "Api-Services/commonApiServices";
import MDButton from "components/MDButton";
import ListItemComp from "components/common-components/listItemComp";
function AllEmployees() {
  const navigate = useNavigate(); 
  const queryClient = useQueryClient() 

  const [loading, setLoading] = useState(false);
  const [forceRerender, setForceRerender] = useState(true);
  const [mode, setMode] = useState("");
  // const [employees, setEmployees] = useState([]);
  const [itemFormData, setItemFormData] = useState({});
  const [currentContextData, updateContext] = useCurrentUser();
  const { departments, departmentCategories } = currentContextData;
  const [currentConversationWith, setCurrentConversationWith] = useState(null);

  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [addNewEmployeeProfile, setAddNewEmployeeProfile] = useState(null);
  // React.useEffect(() => {
  //   (async () => {
  //     // getStarted();
  //     // console.log("departments", departments);
  //   })();
  // }, [departments]);
 

  const {data:employeeStatuses} = useQuery({
    queryKey: ["/api/employee/employeestatus/all"],
    queryFn: async () => {
      let res = [];
      try {
        // setLoading(true);
        let relativePath = "/api/employee/employeestatus/all";
        let postvacancyRes = await getDocuments("postvacancy", relativePath);
        // setLoading(false);
        if (postvacancyRes.success) {
          if (postvacancyRes?.record?.length) {
            res = postvacancyRes?.record;
          }
        }
      } catch (error) {
        console.log("query error", error);
      }
      return res;
    },
  });


  const {data:employees} = useQuery({
    queryKey: ["/api/employee/all"],
    queryFn: async () => {
      let res = [];
      try { 
        let postvacancyRes = await getDocuments("postvacancy", "/api/employee/all");
          if (postvacancyRes?.record?.length) {
            res = postvacancyRes?.record;
          }
          getCurrentDetailData(res)
      } catch (error) {
        console.log("query error", error);
      }
      return res;
    },
  });

  
  // Mutations
  // const mutation = useMutation({
  //   mutationFn: postTodo,
  //   onSuccess: () => {
  //     // Invalidate and refetch
  //     queryClient.invalidateQueries({ queryKey: ["/api/employee/all"] })
  //   },
  // })

  const initializeForm = (currentItemData) => {
    let data = currentItemData || {};
    try {
      setItemFormData({});
      if (currentItemData) {
        setItemFormData(data);
      }
      setForceRerender(!forceRerender);
    } catch (error) {}
  };
 
  const deleteItem = async (id) => {
    try {
      let relativePath = "/api/department/delete/" + id;
      setLoading(true);
      let postvacancyRes = await getDocuments("postvacancy", relativePath);
      setLoading(false);
      if (postvacancyRes.success) {
        updateContext("departments");
        setMode("");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = async (action, dataD, confirm) => {
    try {
      let data = {};
      let relativePath = "";
      if (action == "addasemployee") {
        relativePath = "/api/employee/initialize"; ///changememberrole  /////removemember
        data = dataD;
        //  data.OfficeId=myOfficeDetails?.Office?.id
      }
      if (action == "addasinstructor") {
        relativePath = "/api/instructor/initialize"; ///changememberrole  /////removemember
        data = dataD;
        //  data.OfficeId=myOfficeDetails?.Office?.id
      }
      setLoading(true);
      let postvacancyRes = await postDocuments(
        "postvacancy",
        relativePath,
        data
      );
      setLoading(false);
      if (postvacancyRes.success) {
        // getStarted();
        
      queryClient.invalidateQueries({ queryKey: ["/api/employee/all"] })
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getDocuments = async (documentName, relativePath) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    documentsResponse = await HttpService.getService(relativePath, {}, "");
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };
 
  const onEditClick = (data) => {
    // initializeForm(data);
    setMode("edit");
  };

  const onAddClick = () => {
    initializeForm();
    setMode("add");
    setCurrentConversationWith(null);
  };

  const onViewClick = (data) => {
    setCurrentConversationWith(data);
    setMode("view");
    setForceRerender(!forceRerender);
  };

  const editBox = () => {
    return (
      <MDBox p={1}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
          }}
          noValidate
          autoComplete="off"
        >
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            {/* <MDTypography variant="h5"> Employee</MDTypography> */}
          </MDBox>

          <MDBox p={1}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // value={currentConversationWith?.sectionCourse?.Instructor}
              // onChange={(event) => {
              //   // setNewInstructor(newValue);
              // }}
              onChange={(e, newValue) => {
                // if(newValue.Employee){
                //  setAddNewEmployeeProfile(null)
                // }
                // else{
                setAddNewEmployeeProfile(newValue);
                // }
              }}
              options={filteredEmployees}
              getOptionLabel={(option) =>
                option?.firstName +
                " " +
                option?.lastName +
                " " +
                option?.middleName
              }
              autoHighlight
              // getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    src={option?.profileImageUrl}
                    alt=""
                  />
                  {option?.firstName +
                    " " +
                    option?.lastName +
                    " " +
                    option?.middleName}
                </Box>
              )}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  placeholder="Search Profile name"
                  onChange={async (e) => {
                    let instD = [];
                    let instDRes =
                      await CommonApiServices?.searchDataByKeywordAndField(
                        "profile",
                        e.target.value,
                        {}
                      );
                    if (instDRes?.length) {
                      instD = instDRes;
                    }
                    setFilteredEmployees(instD);
                    // setForceRerender2(!forceRerender2);
                  }}
                  {...params}
                  label="Profile"
                />
              )}
            />
            {addNewEmployeeProfile && (
              <>
              <MDBox p={3}>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <Divider sx={{ my: 1 }} />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar src={addNewEmployeeProfile?.profileImageUrl}>
                        <ListAlt />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        addNewEmployeeProfile?.firstName +
                        " " +
                        addNewEmployeeProfile?.lastName +
                        " " +
                        addNewEmployeeProfile?.middleName
                      }
                      secondary="Profile"
                    />
                  </ListItem>
                </List>
              </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              {addNewEmployeeProfile?.Employee ?
              <MDButton
                startIcon={  <Check /> 
                }
                autoFocus
                color="success"
                variant="contained"
                fontWeight="medium" 
                  disabled={true } 
              > Already An Employee  
              </MDButton>:
              
              <MDButton
                startIcon={  <Add />
                }
                autoFocus
                color="success"
                variant="contained"
                fontWeight="medium" 
                  disabled={loading? true : false}
                onClick={() => {
                  handleSubmit("addasemployee", {
                    ProfileId: addNewEmployeeProfile?.id,
                  });
                }}
              >
                { "Add As Employee"}
              </MDButton>}
              <Button
                variant="button"
                color="text"
                onClick={()=>{setAddNewEmployeeProfile(null)}}
              >
                Cancel
              </Button>
            </MDBox>
            </>
            )}
          </MDBox>
        </Box>
      </MDBox>
    );
  };

  const getCurrentDetailData=(employees)=>{
        console.log("getCurrentDetailData " )
    if(currentConversationWith&&employees){
        console.log("getCurrentDetailData employees" )
      for(let i=0;i<employees?.length;i++){
        console.log("employees[i]",employees[i])
        if(employees[i]){
          if(employees[i]?.id==currentConversationWith?.id){
        console.log("trueee",currentConversationWith?.id)
              setCurrentConversationWith(employees[i])
              setForceRerender(!forceRerender)
            }
         }
      }
    }
  }

  function DetailBox() {
    let data={}
    if(currentConversationWith&&employees){ 
      for(let i=0;i<employees?.length;i++){ 
        if(employees[i]){
          if(employees[i]?.id==currentConversationWith?.id){ 
            data=employees[i] 
            }
          }
        }
       }  
    return (
      <> 
        <MDBox p={3}>
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >
            <ListItem   component={Link}
                to={"/Academic/Profiles/"+data?.Profile?.id}
               >
              <ListItemAvatar>
                <Avatar
                  src={data.Profile?.profileImageUrl}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  data?.Profile?.firstName +
                  " " +
                  data?.Profile?.lastName +
                  " " +
                  data?.Profile?.middleName
                }
                secondary="Profile"
              />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Book />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={data.idCardNumber}
                secondary="Employee Id"
              />
            </ListItem>
            <Divider sx={{ my: 1 }} /> 
            <ListItemComp
               ////data.dv  ///  display value
    ////data.optiondf  ///option display field
              updateData={async() =>{
                await queryClient.invalidateQueries({ queryKey: ["/api/employee/all"] })
                // getCurrentDetailData()
              }}
              data={{
                table: "employee",
                id: data.id,
                fn: "EmployeeStatusId",
                ft: "Employee Status",
                optiondf: "title",
                optionfn: "id",
                options:employeeStatuses,
                 v: data?.EmployeeStatusId , 
                dv: data?.EmployeeStatus?.title ,
                actions: { edit: true },
                inputType: "select",
              }}
            />
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Difference />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  data.createdAt 
                }
                secondary="Created At "
              />
            </ListItem>
          </List>
        </MDBox>
            <MDBox 
              p={2} 
            >
          <List
            sx={{
              width: "100%",
              minWidth: 360,
              bgcolor: "background.paper",
            }}
          >
          <Divider sx={{ my: 1 }} />
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Difference />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                data.Instructor
                  ? "Instructor"
                  : "Not Instructor"
              }
              secondary="Is Instructor?"
            />
          </ListItem>
            </List> 
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={1} 
            >
             {data?.Instructor? <MDButton
                component={Link}
                to={"/Academic/Profiles/"+data?.Profile?.id}
                autoFocus 
                variant="contained"
                fontWeight="medium"
                
              >
                { "View Account"}
              </MDButton> :<MDButton
                startIcon={  <Add />
                }
                autoFocus
                color="success"
                variant="outlined"
                fontWeight="medium"
                disabled={loading? true : false}
                onClick={() => {
                  handleSubmit("addasinstructor", {
                    EmployeeId: data?.id,
                  });
                }}
              >
                { "Add As Instructor"}
              </MDButton> }
              {/* <Button
                variant="button"
                color="text"
                // onClick={()=>{setOpenedDialog(false)}}
              >
                Cancel
              </Button> */}
            </MDBox>
            </MDBox>
      </>
    );
  }

  function DepartmentList() {
    let rowsD = employees || [];
    let rows = [];

    rowsD.forEach((element) => {
      let rowD = element;
      rowD.firstName = rowD?.Profile?.firstName;
      rowD.lastName = rowD?.Profile?.lastName;
      rowD.middleName = rowD?.Profile?.middleName;
      rows.push(rowD);
    });

    const columns = [
      { field: "id", headerName: "#" },
      {
        field: "firstName",
        headerName: "first name",
        // type: 'date',
        width: 150,
        // valueFormatter: (value) => dateFormatter.format(value),
      },
      // {
      //   field: 'departmentCatagory',
      //   headerName: 'Catagory',
      //   // type: 'singleSelect',
      //   // valueOptions: ['Jalapeño'],
      // },
      {
        field: "lastName",
        headerName: "last name",
        width: 150,
        // type: 'singleSelect',
        // valueOptions: ['Jalapeño'],
      },
      {
        field: "middleName",
        headerName: "middle name",
        // type: 'singleSelect',
        width: 180,
        // valueOptions: ['Jalapeño'],
      },
    ];

    return (
      <>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            {/* <MDTypography variant="h5">description</MDTypography> */}
            {/* <MDBadge
              variant="contained"
              color="success"
              badgeContent={currentConversationWith.createdAt}
              container
            /> */}
          </MDBox>
        </>
        <MDBox p={3}>
          <div style={{ width: "100%" }}>
            <DataGrid
              onCellClick={(rowD) => {
                if (rowD) {
                  if (rowD.row) {
                    onViewClick(rowD.row);
                  }
                }
              }}
              rows={rows}
              columns={columns}
              // filterModel={filterModel}
              // onFilterModelChange={setFilterModel}
              disableColumnSelector
              disableDensitySelector
              hideFooter
              slots={{ toolbar: GridToolbar }}
              slotProps={{ toolbar: { showQuickFilter: true } }}
            />
          </div>
        </MDBox>
      </>
    );
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar2 />
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox mb={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card id="profile">
                      <MDBox p={1}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item>
                            <MDBox height="100%" mt={0.5} lineHeight={1}>
                              <MDTypography variant="h5" fontWeight="medium">
                                {"Employees"}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
                            <MDBox
                              display="flex"
                              justifyContent={{ md: "flex-end" }}
                              alignItems="center"
                              lineHeight={1}
                            >
                              <Button
                                variant="button"
                                color="text"
                                fontWeight="medium"
                                onClick={() => {
                                  onAddClick();
                                }}
                              >
                                Add New
                              </Button>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>

            {!(currentConversationWith && isMobile) && (
              <Grid item xs={12} lg={mode == "" ? 12 : 7}>
                <Card
                  sx={{
                    // maxHeight: isMobile ? "700px" : "420px",
                    overflowY: "auto",
                    borderRadius: ({ borders: { borderRadius } }) =>
                      borderRadius.lg,
                    position: "sticky",
                    top: "1%",
                  }}
                >
                  <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                  >
                    {/* {renderSidenavItems} */}
                    <DepartmentList />
                  </MDBox>
                </Card>
              </Grid>
            )}
            {mode != "" && currentConversationWith && (
              <Grid item xs={12} lg={5}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="profile">
                        <MDBox p={2}>
                          <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <Button
                                  onClick={() => {
                                    setCurrentConversationWith(null);
                                    setMode("");
                                  }}
                                startIcon={
                                <ArrowBackIosNewSharp
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />} >Back</Button>
                              </Grid>
                            }
                            {/* <Grid item>
                              <MDAvatar
                                src={currentConversationWith.image}
                                alt="profile-image"
                                size="xl"
                                shadow="sm"
                              />
                            </Grid> */}
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {currentConversationWith?.Profile?.firstName +" "+
                                  currentConversationWith?.Profile?.lastName
                                  }
                                </MDTypography>
                                {/* <MDTypography
                                  variant="button"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  {"Edit"}
                                </MDTypography>  */}
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )} 

                                {mode != "edit" ? (
                                  <>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      variant="button"
                                      color="text"
                                      fontWeight="medium"
                                      onClick={() => {
                                        handleSubmit();
                                      }}
                                    >
                                      {"Save"}
                                    </Button>
                                    <Button
                                      variant="button"
                                      color="text"
                                      fontWeight="medium"
                                      onClick={() => {
                                        setMode("");
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                )}
                                {/* <MDBox ml={1}>
                            <Switch checked={visible} onChange={handleSetVisible} />
                          </MDBox> */}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card id="2fa" sx={{ overflow: "visible" }}>
                        {mode != "edit" ? (
                          <>
                            <DetailBox />
                          </>
                        ) : (
                          <>{editBox()}</>
                        )}
                      </Card>{" "}
                    </Grid>
                    {forceRerender}
                  </Grid>
                </MDBox>
              </Grid>
            )}
            {mode == "add" && (
              <Grid item xs={12} lg={5}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="profile">
                        <MDBox p={2}>
                          <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <ArrowBackIosNewSharp
                                  onClick={() => {
                                    setCurrentConversationWith(null);
                                    setMode("");
                                  }}
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />
                              </Grid>
                            }
                            {/* <Grid item>
                              <MDAvatar
                                src={currentConversationWith.image}
                                alt="profile-image"
                                size="xl"
                                shadow="sm"
                              />
                            </Grid> */}
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {"Add New Employee"}
                                </MDTypography>
                                {/* <MDTypography
                                  variant="button"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  {"Edit"}
                                </MDTypography>  */}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card id="2fa" sx={{ overflow: "visible" }}>
                        {editBox()}
                      </Card>{" "}
                    </Grid>
                    {forceRerender}
                  </Grid>
                </MDBox>
              </Grid>
            )}
          </Grid>
        </MDBox>
      </DashboardLayout>
      {forceRerender}
    </>
  );
}

export default AllEmployees;
