// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import React, { useState } from "react";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import {
  ArrowBackIosNewSharp,
  Book,
  Cancel,
  CancelOutlined,
  Delete,
  Difference,
  Edit,
  ListAlt,
  Remove,
  Save,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HttpService } from "Api-Services/httpService";
import { green } from "@mui/material/colors";
function ListItemComp(props) {
  const [controller] = useMaterialUIController();
  const [loading, setLoading] = useState(false);
  const [forceRerender, setForceRerender] = useState(true);
  const [response, setResponse] = useState(null);
  const [data, setData] = useState({});
  const [editItemId, setEditItemId] = useState(false);

  React.useEffect(() => {
    (async () => {
      console.log("run");
      let editItemIdD = false;
      let dataD = {};
      if (props) {
        if (props.data) {
          dataD = props.data;
        }
        if (props.editItemId) {
          editItemIdD = props.editItemId;
        }
      }
      setEditItemId(editItemIdD);
      setData(dataD);
      setForceRerender(!forceRerender);
    })();
  }, []);

  const saveItem = async (data, value) => {
    setLoading(true);
    try {
      let postData = {};
      postData[data.fn] = value;
      let relativePath = "/api/" + data?.table + "/edit/" + data?.id;
      let postvacancyRes = await postDocuments(
        "postvacancy",
        relativePath,
        postData
      );
      if (postvacancyRes.success) {
        let datt=data
        datt.v=value
        setData(datt);
        setEditItemId(false);
        props.updateData();
      }
    } catch (error) {}
    setLoading(false);
  };

  const getOptionDefualtValue=(displayField)=>{
    let res=displayField?null:{}
    try {
     let datadds= data?.options?.filter((option)=>option[data.optionfn]==data.v)
     if(datadds.length){
     let res1=datadds[0]
      if(displayField){
        if(res1[data.optiondf]){
          res=res1[data.optiondf]
        }
      }
      else{
        res=res1 
      }
     }
    } catch (error) {
      
    }
    return res
  }

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { succesft: false, message: "", record: null };
    let documentsResponse = { succesft: false };

    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };

  const listItemComp = () => {
    // singleimagepicker // textarea // textfield // textfieldnumber //select
    // data.dv  ///  display value
    // optiondf 
    // table: "employee/employeestatus",
    // id: data.id,
    // fn: "EmployeeStatusId",
    // ft: "Employee Status",
    // optiondf: "id", ///option display field
    // v: currentConversationWith?.EmployeeStatus?.title ,
    // actions: { edit: true },
    // inputType: "select",

    let ItemD = <></>;
    try {
      var listItemId = data.ft;
      // let editCompp=false
      ItemD = (
        <>
          <ListItem sx={{p:0.5}}
            secondaryAction={
              data?.actions ? (
                <>
                  {editItemId != listItemId && (data?.actions?.edit || false) && (
                    <IconButton edge="end" aria-label="delete">
                      <Edit
                        onClick={() => {
                          try {
                            setEditItemId(listItemId);
                          } catch (error) {
                            console.log("errr", error);
                          }
                          // setEditComp(!editComp)
                        }}
                      />
                    </IconButton>
                  )}
                </>
              ) : (
                <></>
              )
            }
          >
            <ListItemAvatar>
              {data.av ? (
                <Avatar src={data?.av} />
              ) : (
                <Avatar>
                  <ListAlt />
                </Avatar>
              )}
            </ListItemAvatar>
            {data.inputType?<>
            {(data.inputType == "boolean") ?
             <ListItemText primary={data?.v?"True":"False"} secondary={data?.ft || ""} />: 
             (data.inputType == "select")? 
              <ListItemText  primary={ getOptionDefualtValue(true)||"-"} secondary={data?.ft || ""} />:
              <ListItemText primary={data?.v || "-"} secondary={data?.ft || ""} />
              }
            </>:
            <><ListItemText primary={data?.v || "-"} secondary={data?.ft || ""} /></>}
          </ListItem>
          {editItemId == listItemId && <>{listItemCompEdit(data)}</>}
          <Divider sx={{ my: 0 }} />
        </>
      );
      {
        forceRerender;
      }
    } catch (error) {
      console.log("listItemId error", error);
    }
    return ItemD;
  };

  const listItemCompEdit = () => {
    let ItemD = <></>;
    let value = data?.v || "";
    try {
      ItemD = (
        <MDBox my={2}>
          <ListItem>
            {/* <ListItemAvatar> 
            <Edit /> 
        </ListItemAvatar> */}
            <ListItemText
              primary={
                <>
                  {data.inputType == "textfield" && (
                    <TextField
                      onChange={(e) => {
                        value = e.target.value;
                      }}
                      fullWidth
                      id="outlined-basic"
                      label={data?.ft}
                      defaultValue={value}
                      variant="outlined"
                    />
                  )}
                  {data.inputType == "textfieldnumber" && (
                    <TextField
                      onChange={(e) => {
                        value = e.target.value;
                      }}
                      type="number"
                      fullWidth
                      id="outlined-basic"
                      label={data?.ft}
                      defaultValue={value}
                      variant="outlined"
                    />
                  )}
                  {data.inputType == "textfielddate" && (
                    <TextField
                      onChange={(e) => {
                        value = e.target.value;
                      }}
                      type="date"
                      fullWidth
                      id="outlined-basic"
                      label={data?.ft}
                      defaultValue={value}
                      variant="outlined"
                    />
                  )}
                  {data.inputType == "textfieldmultiline" && (
                    <TextField
                      onChange={(e) => {
                        value = e.target.value;
                      }}
                      multiline
                      fullWidth
                      id="outlined-basic"
                      label={data?.ft}
                      defaultValue={value}
                      variant="outlined"
                    />
                  )}
                  {data.inputType == "select" && (
                    <>
                      <InputLabel id="demo-simple-select-autowidth-label">
                        {data?.ft}
                      </InputLabel>
                      <Select
                        sx={{ p: 1, mt: 1 }}
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={catagoryId}
                        defaultValue={data.v}
                        // label="Age" 
                        onChange={(e) => (value = e.target.value)}
                      >
                        {data?.options?.map((option) => (
                          <MenuItem value={option[data?.optionfn]}>
                            {option[data?.optiondf]}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                  {data.inputType == "boolean" && (
                    <>  
                      <InputLabel id="demo-simple-select-autowidth-label">
                        {data?.ft}
                      </InputLabel>
                      <Select
                        sx={{ p: 1, mt: 1 }}
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={catagoryId}
                        defaultValue={data?.optionDefaultValue}
                        // label="Age" 
                        onChange={(e) => (value = e.target.value)}
                      >  
                          <MenuItem value={true}>
                            {"True"}
                          </MenuItem> 
                          <MenuItem value={false}>
                            {"False"}
                          </MenuItem> 
                      </Select>
                    </>
                  )}
                </>
              }
              secondary={
                <> 
        {/* <Button
          variant="contained"
          sx={buttonSx}
          disabled={loading}
          onClick={handleButtonClick}
        >
          Accept terms
        </Button> */}
                  <Button
                    edge="end"
                    disabled={loading}
                    aria-label="delete"
                    startIcon={<Save />}
                    onClick={() => {
                      try {
                        saveItem(data, value);
                      } catch (error) {
                        console.log("errr", error);
                      }
                      // setEditComp(!editComp)
                    }}
                  >
                    Save
                  </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )} 
                  <Button
                    edge="end"
                    aria-label="delete"
                    startIcon={<CancelOutlined />}
                    onClick={() => {
                      try {
                        setEditItemId(false);
                      } catch (error) {
                        console.log("errr", error);
                      }
                      // setEditComp(!editComp)
                    }}
                  >
                    Cancel
                  </Button>
                </>
              }
            />
          </ListItem>{" "}
        </MDBox>
      );
    } catch (error) {}
    return ItemD;
  };

  return (
    <>
      {listItemComp()}
      {/* {forceRerender} */}
    </>
  );
}

export default ListItemComp;
