/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

profileNumberd by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context"; 
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { 
  Cancel, 
} from "@mui/icons-material"; 
import DashboardNavbar2 from "examples/Navbars/DashboardNavbar2";
import {
  Avatar,
  Box,
  Button,
  CircularProgress, 
  IconButton,
  InputLabel, 
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {   useNavigate } from "react-router-dom";
import { HttpService } from "Api-Services/httpService";
import MDButton from "components/MDButton";
function AddNewProfile() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const [loading, setLoading] = useState(false);
  const [forceRerender, setForceRerender] = useState(true);
  const [response, setResponse] = useState(null);
  const [mode, setMode] = useState("add");
  const [addUserStep, setAddUserStep] = useState(1);
  const [itemFormData, setItemFormData] = useState({}); 
  const [itemFilterData, setItemFilterData] = useState({});
  const [filteredprofiles, setFilteredprofiles] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [currentContextData, updateContext] = useCurrentUser();
  const { departments, programs } = currentContextData;
  const [currentConversationWith, setCurrentConversationWith] = useState(null);

  React.useEffect(() => {
    (async () => {
      // setFilteredprofiles([]);
      setForceRerender(!forceRerender);
      // console.log("profiles", profiles);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      setForceRerender(!forceRerender);
    })();
  }, [profiles, departments, programs]);

  const initializeForm = (currentItemData) => {
    let data = currentItemData || {};
    try {
      setItemFormData({});
      if (currentItemData) {
        setItemFormData(data);
      }
      setForceRerender(!forceRerender);
    } catch (error) {}
  };

  const deleteItem = async (id) => {
    try {
      let relativePath = "/api/profile/delete/" + id;
      setLoading(true);
      let postvacancyRes = await getDocuments("postvacancy", relativePath);
      setLoading(false);
      if (postvacancyRes.success) {
        updateContext("profiles");
        setMode("");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCreateUser = async () => {
    try {
      let data = itemFormData;
      let relativePath = "/api/authorization/registernewuser";
      setLoading(true);
      let postvacancyRes = await postDocuments(
        "postvacancy",
        relativePath,
        data
      );
      setLoading(false);
      if (postvacancyRes.success) {
        if (postvacancyRes.record) {
          if (postvacancyRes.record.User) {
            setUser(postvacancyRes.record.User);
          }
          if (postvacancyRes.record.Profile) {
            setProfile(postvacancyRes.record.Profile);
            navigate(
              "/Academic/Profiles/" + postvacancyRes?.record?.Profile?.id
            );
          }
        }
      } else {
        setResponse(postvacancyRes);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      let data = itemFormData;
      let relativePath = "/api/authorization/registernewuser";
      // if (mode == "edit") {
      //   if (currentConversationWith) {
      //     relativePath = "/api/profile/edit/" + currentConversationWith.id;
      //   } else return;
      // }
      setLoading(true);
      setResponse(null);
      let postvacancyRes = await postDocuments(
        "postvacancy",
        relativePath,
        data
      ); 
      console.log(postvacancyRes);
      if (postvacancyRes.success) {
        if (postvacancyRes.record) {
          if (postvacancyRes.record.Profile) {
            navigate(
              "/Academic/Profiles/" + postvacancyRes?.record?.Profile?.id
            );
          }
        }
      } else {
        setResponse(postvacancyRes);
      }
      // setVacancySubmittedResponse(postvacancyRes)
      // setVacancyData(vacancyDataD)
      // setLoading(false)
      // setVacancySubmittedResponse(null)
      // setIsReviewprofile(true)
    } catch (error) {
      
      console.log(error,"error");
      setLoading(false);
    }
  };

  const getDocuments = async (documentName, relativePath) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    documentsResponse = await HttpService.getService(relativePath, {}, "");
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };
  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };

  const handleFormChange = (field, value) => {
    let itemFormDataD = itemFormData;
    itemFormDataD[field] = value;
    setItemFormData(itemFormDataD);
  };

  const handleFilterChange = (field, value) => {
    try {
      let itemFormDataD = itemFilterData;
      if (value == "Any" || value == "" || value == null) {
        if (itemFormDataD[field]) {
          delete itemFormDataD[field];
        }
      } else {
        itemFormDataD[field] = value;
      }
      console.log("itemFormDataD", itemFormDataD);
      setItemFormData(itemFormDataD);
      // handleFilterData(itemFormDataD);
    } catch (error) {}
  };

  const handleFilterData = async () => {
    try {
      let itemFormDataD = itemFilterData;
      let profilesN = [];

      await postDocuments(
        "mydetails",
        "/api/profile/getbyfield",
        itemFormDataD
      ).then((mydetailsRes) => {
        if (mydetailsRes.success) {
          profilesN = mydetailsRes.record;
        }
        console.log(profilesN, "getCurrentUser  profilesN");
      });
      console.log(profilesN, "profilesN  profilesN");
      // let itemFormDataArray=Object.entries(itemFormDataD)
      // let profilesD=profiles
      // let profilesN = profilesD.filter((sec)=>{
      //   let res=true
      //   itemFormDataArray.forEach(filterElement => {
      //     if(filterElement[1]!="Any"){
      //       if(sec[filterElement[0]]!=filterElement[1]){
      //         res=false
      //       }
      //     }
      //   });
      // //  console.log("sec",sec,field, value)
      //   return res
      // })
      // console.log("profilesN",profilesN)
      setFilteredprofiles(profilesN);
      // setItemFilterData(data);
      setMode("");
      setForceRerender(!forceRerender);
    } catch (error) {}
  };

  const onEditClick = (data) => {
    initializeForm(data);
    setMode("edit");
  };

  const onAddClick = () => {
    initializeForm();
    setMode("add");
    setCurrentConversationWith(null);
  };

  const onViewClick = (data) => {
    setCurrentConversationWith(data);
    setMode("view");
    setForceRerender(!forceRerender);
  };

  const AddBox = () => {
    return (
      <MDBox p={3}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
          }}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2}>
            <Grid item  lg={6}>
              <TextField
                // error
                fullWidth
                label="First Name"
                id="First Name"
                onChange={(e) => handleFormChange("firstName", e.target.value)}
                defaultValue={itemFormData.firstName}
              />
            </Grid>
            <Grid item  lg={6}>
              <TextField
                // error
                fullWidth
                label="Last Name"
                id="Last Name"
                onChange={(e) => handleFormChange("lastName", e.target.value)}
                defaultValue={itemFormData.lastName}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                // error
                fullWidth
                label="Middle Name"
                id="Middle Name"
                onChange={(e) => handleFormChange("middleName", e.target.value)}
                defaultValue={itemFormData.middleName}
              />
            </Grid>

            <Grid item lg={4}>
              <TextField
                // error
                fullWidth
                label="Email  "
                id="Email  "
                onChange={(e) => handleFormChange("email", e.target.value)}
                defaultValue={itemFormData.email}
              />
            </Grid>
            <Grid item lg={4}> 
                <MDBox sx={{p:2}}>
               <PhoneInput 
                  country={'et'}
                    inputProps={{
                      name: 'phone',
                      required: true,
                      autoFocus: true,
                      width:"100%"
                    }}
                    disableDropdown={true}
                    enableAreaCodes={['us', 'et']}
                    enableAreaCodeStretch
                    countryCodeEditable={false}
                  value={itemFormData.phone}
                  // onlyCountries={['et' ]}
                  // onChange={phone => this.setState({ phone })}
                  onChange={(e,b,c,d) =>{
                    console.log("e",e,b,c,d)
                    handleFormChange("phone", d )}}
                />
                </MDBox> 
            </Grid>
            <Grid item md={4}>
              <MDBox  sx={{mt:-1,pl:1}} >
                <InputLabel id="demo-simple-select-autowidth-label">
                  Gender
                </InputLabel>
                <Select
                  sx={{ p: 1, mt: 1 }}
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={catagoryId}
                  defaultValue={itemFormData.sex}
                  // label="Age"
                  // onChange={handleChange}
                  onChange={(e) => handleFormChange("sex", e.target.value)}
                >
                  <MenuItem value={"male"}>{"Male"}</MenuItem>
                  <MenuItem value={"female"}>{"Female"}</MenuItem>
                </Select>
              </MDBox>
            </Grid>
          </Grid>
          {/* <div>
            <InputLabel id="demo-simple-select-autowidth-label">
              catagory
            </InputLabel>
            <Select
              sx={{ p: 1, mt: 1 }}
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={catagoryId}
              defaultValue={itemFormData.DepartmentCatagoryId}
              // label="Age"
              // onChange={handleChange}
              onChange={(e) =>
                handleFormChange("DepartmentCatagoryId", e.target.value)
              }
            >
              {departmentCategories?.map((catagory) => (
                <MenuItem value={catagory.id}>{catagory.profileNumber}</MenuItem>
              ))}
            </Select>
          </div> */}
          {forceRerender}
        </Box>
      </MDBox>
    );
  };

  const responseMessageBox = () => {
    return (
      <>
        <MDBox p={1}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
            alignItems="center"
          >
            <Grid item>
              {response && (
                <MDBox height="100%" mt={0.5} lineHeight={1}>
                  <MDTypography
                    variant="body2"
                    color={response.success ? "success" : "warning"}
                  >
                    {response.message}
                  </MDTypography>
                </MDBox>
              )}
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <IconButton
                  variant="contained"
                  color="text"
                  size="small"
                  fontWeight="medium"
                  onClick={() => {
                    setResponse(null);
                  }}
                >
                  <Cancel />
                </IconButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </>
    );
  };

  const renderSidenavItems = filteredprofiles?.map((message, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component="a"
          // href={`#${href}`}
          onClick={() => {
            onViewClick(message);
          }}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
            backgroundColor: currentConversationWith
              ? currentConversationWith.profileNumber == message.profileNumber
                ? light.main
                : "none"
              : "none",
          })}
        >
          {/* <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}> */}
          <MDBox mr={2}>
            <Avatar src={message.profileImageUrl} alt="something here" />
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <MDTypography variant="button" fontWeight="medium">
              {message?.firstName} {message?.lastName} {message?.middleName}{" "}
            </MDTypography>
            {/* <MDTypography variant="caption" color="text">
              {currentConversationWith || isMobile
                ? truncateString(message.description, 40)
                : truncateString(message.description, 200)}
            </MDTypography> */}
          </MDBox>
          {/* {message.description} */}
        </MDTypography>
      </MDBox>
    );
  });
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar2 />
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox>
                <Grid container>
                  <Grid item xs={12}>
                    <Card id="profile">
                      <MDBox p={1}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item>
                            <MDBox height="100%" mt={0.5} lineHeight={1}>
                              <MDTypography variant="h5" fontWeight="medium">
                                {"Add New Profile"}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                          <Grid item sx={{ ml: "auto" }}>
                            <MDBox
                              display="flex"
                              justifyContent={{ md: "flex-end" }}
                              alignItems="center"
                              lineHeight={1}
                            >
                              <MDButton
                                sx={{ mx: 2 }}
                                variant="outlined"
                                color="success"
                                fontWeight="medium"
                                onClick={() => {
                                  onAddClick();
                                }}
                              >
                                Add Form
                              </MDButton>
                              <MDButton
                                sx={{ mx: 2 }}
                                variant="outlined"
                                color="success"
                                fontWeight="medium"
                                onClick={() => {
                                  setMode("admision");
                                }}
                              >
                                Get from admision
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>

            {/* {!(currentConversationWith && isMobile) && (
              <Grid item xs={12} lg={mode == "" ? 12 : 5}>
                <Card
                  sx={{
                    maxHeight: isMobile ? "700px" : "420px",
                    overflowY: "auto",
                    borderRadius: ({ borders: { borderRadius } }) =>
                      borderRadius.lg,
                    position: "sticky",
                    top: "1%",
                  }}
                >
                  <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                  >
                    {renderSidenavItems}
                  </MDBox>
                </Card>
              </Grid>
            )}
            {mode != "" && currentConversationWith && (
              <Grid item xs={12} lg={7}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="profile">
                        <MDBox p={2}>
                          <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <ArrowBackIosNewSharp
                                  onClick={() => {
                                    setCurrentConversationWith(null);
                                    setMode("");
                                  }}
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />
                              </Grid>
                            }
                            <Grid item>
                              <MDAvatar
                                src={currentConversationWith.image}
                                alt="profile-image"
                                size="xl"
                                shadow="sm"
                              />
                            </Grid>  
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {currentConversationWith?.firstName}{" "}
                                  {currentConversationWith?.lastName}{" "}
                                  {currentConversationWith?.middleName}{" "}
                                </MDTypography>
                                <MDTypography
                                  variant="button"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  {"Edit"}
                                </MDTypography> 
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )}
                                <MDButton
                                  component={Link}
                                  to={"/Profile/:id"}
                                  variant="button"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  {"View More Details"}
                                </MDButton>
                                <MDBox ml={1}>
                            <Switch checked={visible} onChange={handleSetVisible} />
                          </MDBox>  
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card id="2fa" sx={{ overflow: "visible" }}>
                        {mode != "edit" ? (
                          <>
                            <DetailBox />
                          </>
                        ) : (
                          <>{editBox()}</>
                        )}
                      </Card>{" "}
                    </Grid>
                    {forceRerender}
                  </Grid>
                </MDBox>
              </Grid>
            )} */}
            {mode == "add" && (
              <Grid item xs={12} lg={12}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="profile">
                        <MDBox p={2}>
                          <Grid container spacing={3} alignItems="center">
                            {/* <Grid item>
                              <MDAvatar
                                src={currentConversationWith.image}
                                alt="profile-image"
                                size="xl"
                                shadow="sm"
                              />
                            </Grid> */}
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {"Add Form"}
                                </MDTypography>
                                {/* <MDTypography
                                  variant="button"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  {"Edit"}
                                </MDTypography>  */}
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )}

                                <>
                                  <Button
                                    variant="button"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => {
                                      if (addUserStep != 1) {
                                        handleSubmit();
                                      } else {
                                        handleCreateUser();
                                      }
                                    }}
                                  >
                                    {"Sumbit"}
                                  </Button>
                                  <Button
                                    variant="button"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => {
                                      setMode("");
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </>
                                {/* <MDBox ml={1}>
                            <Switch checked={visible} onChange={handleSetVisible} />
                          </MDBox> */}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card id="2fa" sx={{ overflow: "visible" }}>
                        <AddBox />
                      </Card>{" "}
                    </Grid>
                    {forceRerender}
                  </Grid>
                  {response && (
                    <Grid item xs={12}>
                      <Card id="2fa" sx={{ mt: 2, overflow: "visible" }}>
                        {responseMessageBox()}
                      </Card>{" "}
                    </Grid>
                  )}
                </MDBox>
              </Grid>
            )}
          </Grid>
        </MDBox>
      </DashboardLayout>
      {forceRerender}
    </>
  );
}

export default AddNewProfile;
