/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
sectionNumberd by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Settings page components
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import MDBadge from "components/MDBadge";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { Add, ArrowBackIosNewSharp, Book, ListAlt, Merge, MoreVert, Person, Splitscreen } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import DashboardNavbar2 from "examples/Navbars/DashboardNavbar2";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Icon,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HttpService } from "Api-Services/httpService";
import ListItemComp from "components/common-components/listItemComp";
import { CommonServices } from "Api-Services/commonServices";
import MDButton from "components/MDButton";
import { CommonApiServices } from "Api-Services/commonApiServices";
import NotificationItem from "examples/Items/NotificationItem";
import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";
function ProgramToDepartmentDetails(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [controller] = useMaterialUIController();
  const [loading, setLoading] = useState(false);
  const [forceRerender, setForceRerender] = useState(true);
  const [openedDialog, setOpenedDialog ] = useState(false);
  const [mode, setMode] = useState("");
  const [itemFormData, setItemFormData] = useState({});
  const [addMemberFormData, setAddMemberFormData] = useState({}); 
  const [catalogCourses, setCatalogCourses] = useState([]);
  const [catalog, setCatalog] = useState({});
  const [sectionTab, setSectionTab] = useState("acadamicyears");
  const [currentContextData, updateContext] = useCurrentUser();
  const { myCurrentProfile,officeMemeberRoles,courses, departments, programs } = currentContextData;
  const [currentConversationWith, setCurrentConversationWith] = useState(null);
    
  const [myOfficeDetails, setMyOfficeDetails] = useState({});
  const [programToDepartment , setProgramToDepartmentId] = useState({});
  // const [officeRoles, setOfficeRoles] = useState([]);
  const [myRole, setMyRole] = useState({});
  // const [openMemberMenu, setOpenMemberMenu] = useState(false);
 
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [addNewEmployeeProfile, setAddNewEmployeeProfile] = useState(null);
  const [forceRerender2, setForceRerender2] = useState(false);
   
  React.useEffect(() => {
    (async () => { 
      getStarted()
    })();
  }, [programs,myCurrentProfile]);
 
  React.useEffect(() => {
    (async () => {
      setForceRerender(!forceRerender);
    })();
  }, [catalogCourses, departments, programs, courses]);

  const initializeForm = (currentItemData) => {
    let data = currentItemData || {};
    try {
      setItemFormData({});
      if (currentItemData) {
        setItemFormData(data);
      }
      setForceRerender(!forceRerender);
    } catch (error) {}
  };

  const getStarted = async () => {
    try {
      setOpenedDialog("")
      setAddMemberFormData({})
      setAddNewEmployeeProfile(null)
      let details={}
      if (params) {
        if (params.officeId) {
          let areUMember=false
          let memberid=false
          // console.log("myOffices", myCurrentProfile?.Employee?.OfficeMembers);
            myCurrentProfile?.Employee?.OfficeMembers?.forEach(officeMember => {
              if(officeMember?.OfficeId==params.officeId){
                // console.log("officeMember",officeMember);
                memberid=officeMember?.id
                areUMember=true
              }
            });

            if (areUMember) {
              // office/"+params.id+"/
        if (params.programToDepartmentId) {
              let relativePath = "/api/programtodepartment/find/" + params.programToDepartmentId;
              // setLoading(true);
              let postvacancyRes = await getDocuments("postvacancy", relativePath);
              if (postvacancyRes.success) {
                details = postvacancyRes.record;
              } 
            }
          }

        }
      }
      setProgramToDepartmentId(details)
      setForceRerender(!forceRerender); 
    } catch (error) { 
    }
  };
 
  const getData = async (tableName, id) => {
    let details = {};
    try {
      if (tableName && id) {
        let relativePath = "/api/" + tableName + "/find/" + id;
        // setLoading(true);
        let postvacancyRes = await getDocuments("postvacancy", relativePath);
        if (postvacancyRes.success) {
          details = postvacancyRes.record;
        }
      }
    } catch (error) {}
    // setLoading(false);
    if (tableName == "programtodepartmentyear") {
      setCatalog(details);
    }
    setForceRerender(!forceRerender);
  };

  const handleSubmit = async () => {
    try {
      let data = itemFormData;
      let relativePath = "/api/catalogcourse/initialize";
      if (mode == "edit") {
        if (currentConversationWith) {
          relativePath =
            "/api/catalogcourse/edit/" + currentConversationWith.id;
        } else return;
      } else if (catalog.id) {
        if (data.creditHour && data.acadamicSemister && data.CourseId) {
          data.ProgramToDepartmentYearId = catalog.id;
        } else {
          return;
        }
      } else {
        return;
      }
      setLoading(true);
      let postvacancyRes = await postDocuments(
        "postvacancy",
        relativePath,
        data
      );
      setLoading(false);
      if (postvacancyRes.success) {
        setOpenedDialog("")
        getData("programtodepartmentyear", catalog?.id);
        setMode(""); 
        if (mode == "add" && postvacancyRes.record) {
          setCurrentConversationWith(postvacancyRes.record);
        } else { 
          setMode("");
        }
      } 
    } catch (error) {
      setLoading(false);
    }
  };


  const handleOfficeMemberSubmit = async (action,dataD) => {
    console.log("myOfficeDetails",myOfficeDetails)
    try {
      let data = {};
      let relativePath = "";
      if(action=="addmember"){
          relativePath = "/api/office/"+myOfficeDetails?.Office?.id+"/addmember";   ///changememberrole  /////removemember
           data = addMemberFormData;
          //  data.OfficeId=myOfficeDetails?.Office?.id
      }
      if(action=="changememberrole"){
          relativePath = "/api/office/"+myOfficeDetails?.Office?.id+"/changememberrole";   ///changememberrole  /////removemember
           data = dataD;
          //  data.OfficeId=myOfficeDetails?.Office?.id
      }
      if(action=="removemember"){
          relativePath = "/api/office/"+myOfficeDetails?.Office?.id+"/removemember";   ///changememberrole  /////removemember
           data = dataD;
          //  data.OfficeId=myOfficeDetails?.Office?.id
      }
      // else if(tableName=="programtodepartment"){
      //      data = addDepartmentFormData;
      // }

      
      // if (action == "edit") {
      //   if (currentConversationWith) {
      //     relativePath =
      //       "/api/officemember/edit/" + currentConversationWith.id;
      //   } else return;
      // } else { 
      //     data.ProgramToDepartmentYearId = catalog.id;
      // } 
      setLoading(true);
      let postvacancyRes = await postDocuments(
        "postvacancy",
        relativePath,
        data
      );
      if (postvacancyRes.success) {
        getStarted() 
      } 
    } catch (error) { 
    }
      setLoading(false);
  };

  const getDocuments = async (documentName, relativePath) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    documentsResponse = await HttpService.getService(relativePath, {}, "");
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };
 

  const handleAddMemberFormChange = (field, value) => {
    let itemFormDataD = addMemberFormData;
    itemFormDataD[field] = value;
    setAddMemberFormData(itemFormDataD);
    setForceRerender(!forceRerender);
  };
  
  const onAddClick = () => {
    initializeForm();
    setMode("");
    setCurrentConversationWith(null); 
    setOpenedDialog("addmember")
  };
 
 
  const addmemberBox =()=> {
    
    return (
      <MDBox p={3}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <MDBox p={1}>
            <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      // defaultValue={addNewEmployeeProfile}
                      // onChange={(event) => {
                      //   // setNewInstructor(newValue);
                      // }}
                      onChange={(e, newValue) =>{
                         setAddNewEmployeeProfile(newValue)
                         handleAddMemberFormChange("EmployeeId", newValue?.id)
                         setForceRerender2(!forceRerender2)
                         console.log("ddNewEmployeeProfile",addNewEmployeeProfile)
                        }}
                      options={filteredEmployees} 
                      getOptionLabel={(option) =>
                        option?.Profile?.firstName +
                        " " +
                        option?.Profile?.lastName +
                        " " +
                        option?.Profile?.middleName
                      }
                      autoHighlight 
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            src={option?.Profile?.profileImageUrl}
                            alt=""
                          />
                          {option?.Profile?.firstName +
                            " " +
                            option?.Profile?.lastName +
                            " " +
                            option?.Profile?.middleName}
                        </Box>
                      )}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          onChange={async (e) => {
                            let instD = await CommonApiServices?.searchDataByKeywordAndField(
                                "employee",
                                e.target.value,{}
                              );
                            setFilteredEmployees(instD);
                            setForceRerender2(!forceRerender2);
                          }}
                          {...params}
                          label="Employee"
                        />
                      )}
                    /> 

            </MDBox>{addNewEmployeeProfile&&
            <MDBox p={3}>
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      > 
                        <Divider sx={{ my: 1 }} />
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar src={addNewEmployeeProfile?.Profile?.profileImageUrl}>
                              <ListAlt />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              addNewEmployeeProfile?.Profile?.firstName+" "+
                              addNewEmployeeProfile?.Profile?.lastName+" "+
                              addNewEmployeeProfile?.Profile?.middleName
                            }
                            secondary="Profile"
                          />
                        </ListItem>
                      </List>
            </MDBox>}
         
          </div>
          {forceRerender2}
        </Box>
      </MDBox>
    );
  };
  

  function StudentsListBox(dataD) {
    let data = dataD?.data || [];
    const [openMemberMenu, setOpenMemberMenu] = useState(false);
    const [forceRerenderMembers, setForceRerenderMembers] = useState(false);
    const [currentMenuData, setCurrentMenuData] = useState(null);
     
    const handleOpenMemberMenu = (event) => setOpenMemberMenu(event.currentTarget);
    const handleCloseMemberMenu = () => setOpenMemberMenu(false);
  
    const renderMemberMenu=()=>{
      return <>
              <Menu
                id="openMemberMenu1"
                anchorEl={openMemberMenu}
                anchorReference={null}
                // anchorEl={anchorEl}
                open={openMemberMenu}
                onClose={handleCloseMemberMenu}
                MenuListProps={{
                  'aria-labelledby': 'openMemberMenu1',
                }}
              >
               <MenuItem  
                  component={Link}  
                  to={"/Academic/Profiles/" + currentMenuData?.Profile?.id}>
                  view {currentMenuData?.Profile?.firstName} profile
               </MenuItem> 
                {officeMemeberRoles?.map((officeMemeberRole)=>
                (officeMemeberRole.id!=currentMenuData?.OfficeMemberRoleId)&&
                 <MenuItem 
                 onClick={()=>
                       handleOfficeMemberSubmit("changememberrole",
                       {EmployeeId:currentMenuData?.Employee?.id,
                        OfficeMemberRoleId:officeMemeberRole.id})
                      } 
                 >{"make as "+ officeMemeberRole.title}</MenuItem>)}
                <MenuItem 
                       onClick={()=>
                        handleOfficeMemberSubmit("removemember",
                        {EmployeeId:currentMenuData?.Employee?.id})
                      } 
                >{"remove " +currentMenuData?.Employee?.Profile?.firstName}</MenuItem> 
         </Menu>    
      </>
    }
    return (
      <>
      {forceRerenderMembers}
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={1}
        >
          <MDTypography variant="h5">{data?.length} Member(s) </MDTypography> 
          {/* <Typography>
            Section number: {filteredSections.length?(filteredSections[filteredSections.length-1]?.sectionNumber+1):1}
              </Typography> */}
        </MDBox>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {data?.map((officeMember, key) => {
            const itemKey = `item-${key}`;

            return (
              <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
                <MDTypography
                  component="a"
                  // href={`#${href}`}
                  // onClick={() => {
                  //   onViewClick(message);
                  // }}
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                  sx={({
                    borders: { borderRadius },
                    functions: { pxToRem },
                    palette: { light },
                    transitions,
                  }) => ({
                    display: "flex",
                    alignItems: "center",
                    borderRadius: borderRadius.md,
                    padding: `${pxToRem(10)} ${pxToRem(16)}`,
                    transition: transitions.create("background-color", {
                      easing: transitions.easing.easeInOut,
                      duration: transitions.duration.shorter,
                    }),

                    "&:hover": {
                      backgroundColor: light.main,
                    },
                    backgroundColor: currentConversationWith
                      ? currentConversationWith.sectionNumber ==
                        message.sectionNumber
                        ? light.main
                        : "none"
                      : "none",
                  })}
                >
                  {/* <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}> */}
                  <MDBox mr={2}>
                    <Avatar alt="something here" src={officeMember?.Profile?.profileImageUrl} /> 
                  </MDBox>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <MDBox
                      mr={2}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="center"
                    >
                      <MDTypography variant="button" fontWeight="medium">
                      {officeMember?.Profile?.firstName+" "}
                      {officeMember?.Profile?.lastName+" "}
                      {officeMember?.Profile?.middleName+" "}
                      </MDTypography>
                      <MDTypography variant="caption" color="text">
                        {officeMember?.StudentStatus?.title}
                      </MDTypography>
                    </MDBox>
                    <MDBox mr={2}>
                      <Button startIcon={<MoreVert/>}
                        size={"small"}
                        
                          id="basic-button"
                          aria-controls={openMemberMenu ? 'openMemberMenu1' : undefined}
                          aria-haspopup="true"
                          aria-expanded={openMemberMenu ? 'true' : undefined}
                          onClick={(e)=>{
                            setCurrentMenuData (officeMember) 
                            handleOpenMemberMenu(e)
                            setForceRerenderMembers(!forceRerenderMembers)
                          }}
                      >
                         More
                      </Button>    
                      {renderMemberMenu()}        
                    </MDBox>
                  </Grid>
                </MDTypography>
              </MDBox>
            );
          })}
        </MDBox>
      </>
    );
  }

  function SectionsListBox(dataD) {
    let data = dataD?.data || [];
    return (
      <>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={1}
        >
          <MDTypography variant="h5">{data?.length} Acadamic Year(s) </MDTypography> 
          {/* <Typography>
            Section number: {filteredSections.length?(filteredSections[filteredSections.length-1]?.sectionNumber+1):1}
              </Typography> */}
        </MDBox>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {data?.map((message, key) => {
            const itemKey = `item-${key}`;

            return (
              <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
                <MDTypography
                  component="a"
                  // href={`#${href}`}
                  // onClick={() => {
                  //   onViewClick(message);
                  // }}
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                  sx={({
                    borders: { borderRadius },
                    functions: { pxToRem },
                    palette: { light },
                    transitions,
                  }) => ({
                    display: "flex",
                    alignItems: "center",
                    borderRadius: borderRadius.md,
                    padding: `${pxToRem(10)} ${pxToRem(16)}`,
                    transition: transitions.create("background-color", {
                      easing: transitions.easing.easeInOut,
                      duration: transitions.duration.shorter,
                    }),

                    "&:hover": {
                      backgroundColor: light.main,
                    },
                    backgroundColor: currentConversationWith
                      ? currentConversationWith.sectionNumber ==
                        message.sectionNumber
                        ? light.main
                        : "none"
                      : "none",
                  })}
                >
                  {/* <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}> */}
                  <MDBox mr={2}>
                    <Avatar alt="something here">
                      <Book />
                    </Avatar>
                  </MDBox>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <MDBox
                      mr={2}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="center"
                    >
                      <MDTypography variant="button" fontWeight="medium">
                      {CommonServices.ordinal_suffix_of(message.acadamicYear)+ " year"}
                      </MDTypography>
                      {/* <MDTypography variant="caption" color="text">
                      {CommonServices.ordinal_suffix_of(message.acadamicYear)+ " year"}
                      </MDTypography> */}
                      {/* <MDTypography variant="caption" color="text">
                     {message?.ProgramToDepartmentYears.map((depYear)=> 
                     <MDButton sx={{mr:2}}
                        component={Link}
                        to={"/Academic/SectionGroups/" + depYear?.id}
                        size={"small"}
                      >
                        {CommonServices.ordinal_suffix_of(depYear.acadamicYear)+ " year"}
                      </MDButton>)} 
                      </MDTypography> */}
                    </MDBox>
                    <MDBox>
                      <MDButton 
                        component={Link}
                        to={"/Academic/SectionGroups/" + message?.id}
                        size={"small"}
                      >
                        View More
                      </MDButton>
                    </MDBox>
                  </Grid>
                </MDTypography>
                    <Divider sx={{py:0,my:0}} />
              </MDBox>
            );
          })}
        </MDBox>
      </>
    );
  }
 
  function CatalogDetailsBox() {
    let data = programToDepartment || {};
    return (
      <Card sx={{ overflow: "visible" }}>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5">Program To Department Details</MDTypography>
            <MDBadge
              disabled={data?.finishedAssigningCourses}
              component={Button}
              // to={"/Organizational-Unit/Sections/" + data.id}
              variant="contained"
              onClick={()=>{setOpenedDialog("sectiongroupmenu")}}
              color="success"
              badgeContent={"Menu"}
              container
            />
          </MDBox>
        </>
        <MDBox p={2}>
          <List
            dense
            sx={{
              width: "100%",
              // maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >
            {/* <ListItemComp
              data={{
                ft: "Section Number ",
                v: "Section " + data?.sectionNumber,
                a: null,
              }}
            /> */}

            <Grid container  >
              <Grid item md={12}> 
                <ListItemComp
                  data={{
                    ft: "department ",
                    v:
                      data?.Department?.title  
                  }}
                />
                <ListItemComp
                  data={{
                    ft: "classification ",
                    v:
                      data?.EducationClassification?.title  
                  }}
                />
                <ListItemComp
                  data={{
                    ft: "program ",
                    v:
                      data?.Program?.title  
                  }}
                /> 
              </Grid>  
            </Grid>
          </List>
        </MDBox>
      </Card>
    );
  }
 

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar2 />
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox mb={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card id="profile">
                      <CatalogDetailsBox data={catalog} />
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>


            
            <Grid item xs={12}>
              <MDBox>
                <Grid container>
                  <MDBox sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      <Tabs value={sectionTab} onChange={(e,v)=>setSectionTab(v)} centered>
                        <Tab label="Acadamic years" value="acadamicyears"/>
                     { (myOfficeDetails?.Office?.OfficeTypeId==3)&&  <Tab label="Departments" value="departments"/>}
                        <Tab label="Students" value="students"/>
                      </Tabs>
                  </MDBox>
                </Grid>
              </MDBox>
            </Grid>
   
          {sectionTab=="acadamicyears"&&<>
            <Grid item xs={12}>
              <MDBox mb={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card id="departments"> 
                      <SectionsListBox data={programToDepartment?.ProgramToDepartmentYears} />
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
            </>}
            
          {sectionTab=="students"&&<>
            <Grid item xs={12}>
              <MDBox mb={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card id="ections">
                      <StudentsListBox data={programToDepartment?.Students}/>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
            </>} 
          </Grid>
        </MDBox>
      </DashboardLayout>
      {forceRerender}

      <Dialog
       onClose={()=>{setOpenedDialog("")}} 
      //  maxWidth={"lg"}
       open={openedDialog=="sectiongroupmenu"}>
        <DialogTitle>Menu</DialogTitle>
        <List sx={{ pt: 0 }}  subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Office Member
        </ListSubheader>
      }> 
                        
          <ListItem disableGutters>
            <ListItemButton disabled={catalog?.finishedAssigningCourses}
              autoFocus
              onClick={() =>{ 
                onAddClick()}}
            >
              <ListItemAvatar>
                <Avatar>
                  <Add />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add Employee" />
            </ListItemButton>
          </ListItem>
          <Divider /> 
        </List>
        {/* <List sx={{ pt: 0 }}   subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Section
        </ListSubheader>
      }>  
          <ListItem disableGutters>
            <ListItemButton
              autoFocus
              component={Link}
              
              to={"/Academic/SectionGroups/"+catalog?.id+"/Add-Section"}
            >
              <ListItemAvatar>
                <Avatar>
                  <Add />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add Section" />
            </ListItemButton>
          </ListItem>
          <Divider sx={{my:1}}/>
          <ListItem disableGutters>
            <ListItemButton
              autoFocus
              component={Link}
              
              to={"/Academic/SectionGroups/"+catalog?.id+"/Merge-Sections"}
              // onClick={() => setOpenedDialog("addsection")}
            >
              <ListItemAvatar>
                <Avatar>
                  <Merge />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Merge Sections" />
            </ListItemButton>
          </ListItem>
          <Divider sx={{my:1}}/>
          <ListItem disableGutters>
            <ListItemButton
              autoFocus
              // onClick={() => setOpenedDialog("addsection")}
              component={Link}
              
              to={"/Academic/SectionGroups/"+catalog?.id+"/Split-Section"}
            >
              <ListItemAvatar>
                <Avatar>
                  <Splitscreen />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Split Section" />
            </ListItemButton>
          </ListItem>
        </List> */}
      </Dialog>

      <Dialog 
       sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
       onClose={()=>{setOpenedDialog("")}} 
       open={openedDialog=="addmember"}>
        <DialogTitle> 
        
        <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <ArrowBackIosNewSharp
                                  onClick={() => { setOpenedDialog("sectiongroupmenu")}} 
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />
                              </Grid>
                            }
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {"Add New Member"}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )} 
                              </MDBox>
                            </Grid>
                          </Grid>
          </DialogTitle>

        {addmemberBox()}
        
        <DialogActions>
          
        <Button autoFocus
            fontWeight="medium"
            onClick={() => {
              handleOfficeMemberSubmit("addmember");
            }}
          >
            {"Sumbit"}
          </Button>
          <Button 
            variant="button"
            color="text"
            onClose={()=>{setOpenedDialog(false)}}  >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog 
       sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
       onClose={()=>{setOpenedDialog("")}} 
       open={openedDialog=="addsection"}>
        <DialogTitle> 
        
        <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <ArrowBackIosNewSharp
                                  onClick={() => { setOpenedDialog("sectiongroupmenu")}} 
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />
                              </Grid>
                            }
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {"Add New Section"}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )} 
                              </MDBox>
                            </Grid>
                          </Grid>
          </DialogTitle>
                                  <DialogContent>
                                    
                                  <DialogContentText>
                                  {"Do you want to add one extra section in this group?"}</DialogContentText>
                                  </DialogContent>
        {/* {addSectionBox()}  */}
        
        <DialogActions>
          
        <Button autoFocus
            fontWeight="medium"
            onClick={() => {
              handleSubmit();
            }}
          >
            {"Yes"}
          </Button>
          <Button 
            variant="button"
            color="text"
            onClose={()=>{setOpenedDialog(false)}}  >
            No
          </Button>
        </DialogActions>
      </Dialog>
      {forceRerender}
    </>
  );
}

export default ProgramToDepartmentDetails;
