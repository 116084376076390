 
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

// Material Dashboard 2 PRO React components 

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import JobSeekerFullDetail from "layouts/Employment/Job-Seekers/components/JobSeekerDetail/jobSeekerFullDetail";
import { Message } from "@mui/icons-material";
import PaymentMethod from "layouts/pages/account/billing/components/PaymentMethod";
import BillConfirmationRequests from "layouts/pages/account/billing/components/BillingInformation";
import NewVacancy from "layouts/Employment/job-vacancies/new-vacancy";
import JobVacancyApplications from "layouts/Employment/job-vacancies/job-vacancy-applications";
import CatalogCourses from "layouts/Academic/Catalog/CatalogCourses";
import AddNewProfile from "layouts/Academic/Profile/addNewProfile";
import ProfileDetails from "layouts/Academic/Profile/profileDetails";
import RequestStudentAccount from "layouts/Academic/Profile/Components/requestStudentAccount"; 
import StudentDetails from "layouts/Academic/Student/studentDetails";
import StudentEnrollments from "layouts/Academic/Enrollment/studentEnrollments";
import StudentEnrollmentDetails from "layouts/Academic/Enrollment/studentEnrollmentDetails";
import SectionDetails from "layouts/Academic/Section/SectionDetails";
import AddSection from "layouts/Academic/Catalog/AddSection";
import SplitSection from "layouts/Academic/Catalog/SplitSection";
import MergeSections from "layouts/Academic/Catalog/MergeSections";
import OfficeDetails from "layouts/CommonLayouts/MyOffices/OfficeDetails";
import MyOffices from "layouts/CommonLayouts/MyOffices";

const routesCommonCustom = [ 
 
  {
    type: "collapse",
    name: "New Vacancy",
    key: "New Vacancy",
    icon: <Message/>,
    route: "/Employment/Job-Vacancy-Applications",
    component: <JobVacancyApplications />,
    noCollapse: true,
  },  
  {
    type: "collapse",
    name: "Payment Method",
    key: "Payment Method",
    icon: <Message/>,
    route: "/pages/account/payment/payment-method",
    component: <PaymentMethod />,
    noCollapse: true,
  },  
  {
    type: "collapse",
    name: "Bill Confirmation Requests",
    key: "Bill Confirmation Requests",
    icon: <Message/>,
    route: "/pages/account/payment/bill-confirmation-requests",
    component: <BillConfirmationRequests />,
    noCollapse: true,
  },  
  {
    type: "collapse",
    name: "Bill Confirmation Requests",
    key: "Bill Confirmation Requests",
    icon: <Message/>,
    route: "/Carriculum/Catalog-Courses/:CatalogId",
    component: <CatalogCourses/>,
    noCollapse: true,
  },  


  /////profile
  {
    type: "collapse",
    name: "Add New Profile",
    key: "Add New Profile",
    icon: <Message/>,
    route: "/Academic/Profiles/Add-New",
    component: <AddNewProfile/>,
    noCollapse: true,
  },  
  {
    type: "collapse",
    name: "Profile Details",
    key: "Profile Details", 
    icon: <Message/>,
    route: "/Academic/Profiles/:id",
    component: <ProfileDetails/>,
    noCollapse: true,
  }, 
  {
    type: "collapse",
    name: "Profile Details",
    key: "Profile Details", 
    icon: <Message/>,
    route: "/Academic/Profiles/:id/Request-Student-Account",
    component: <RequestStudentAccount/>,
    noCollapse: true,
  }, 
  {
    type: "collapse",
    name: "Profile Details",
    key: "Profile Details", 
    icon: <Message/>,
    route: "/Academic/Students/:id",
    component: <StudentDetails/>,
    noCollapse: true,
  }, 
  {
    type: "collapse",
    name: "Profile Details",
    key: "Profile Details", 
    icon: <Message/>,
    route: "/Academic/Students/:studentId/Enrollments",
    component: <StudentEnrollments/>,
    noCollapse: true,
  }, 
  {
    type: "collapse",
    name: "Profile Details",
    key: "Profile Details", 
    icon: <Message/>,
    route: "/Academic/Students/:studentId/Enrollments/:id",
    component: <StudentEnrollmentDetails/>,
    noCollapse: true,
  }, 
  {
    type: "collapse",
    name: "Section Group Details",
    key: "Section Group Details", 
    icon: <Message/>,
    route: "/Academic/SectionGroups/:id",
    component: <CatalogCourses/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Add Section",
    key: "Add Section", 
    icon: <Message/>,
    route: "/Academic/SectionGroups/:id/Add-Section",
    component: <AddSection/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Merge Sections",
    key: "Merge Sections", 
    icon: <Message/>,
    route: "/Academic/SectionGroups/:id/Merge-Sections",
    component: <MergeSections/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Split Section",
    key: "Split Section", 
    icon: <Message/>,
    route: "/Academic/SectionGroups/:id/Split-Section",
    component: <SplitSection/>,
    noCollapse: true,
  },

  ////////////////////////////////////////
  ////////////////////////////////////
  ////////////////////////////////////////
  ////////////////////////////////////////
  {
    type: "collapse",
    name: "Office Details",
    key: "Office Details", 
    icon: <Message/>,
    route: "/my-offices/:id",
    component: <OfficeDetails/>,
    noCollapse: true,
  },  
  {
    type: "collapse",
    name: "Office Details",
    key: "Office Details", 
    icon: <Message/>,
    route: "/my-offices/:officeType",
    component: <MyOffices/>,
    noCollapse: true,
  },
];

export default routesCommonCustom;
