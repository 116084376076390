 
// Material Dashboard 2 PRO React layouts
 
 import SignInBasic from "layouts/authentication/sign-in/basic";
 
// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";
 

// Images 
import { Deck, List, ListAltOutlined, Logout  } from "@mui/icons-material";
  import React from "react";
import { DataManagementService } from "Api-Services/dataManagementService"; 
import SwitchProfile from "layouts/authentication/profile";
const routesD=()=>{
       
      return [
        
      ];
        // }; 

}

const routes =routesD()
// const routes=[]

export default routes;
