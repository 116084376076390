/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
sectionNumberd by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Settings page components
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import MDBadge from "components/MDBadge";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import {
  ArrowBackIosNewSharp,
  Book,
  Cancel,
  Edit,
  ListAlt,
  Save,
} from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import DashboardNavbar2 from "examples/Navbars/DashboardNavbar2";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HttpService } from "Api-Services/httpService";
import ListItemComp from "components/common-components/listItemComp";
import { CommonServices } from "Api-Services/commonServices";
import { CommonApiServices } from "Api-Services/commonApiServices";
function SectionDetails(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [controller] = useMaterialUIController();
  const [loading, setLoading] = useState(false);
  const [forceRerender, setForceRerender] = useState(true);
  const [mode, setMode] = useState("");
  const [itemFormData, setItemFormData] = useState({});
  const [itemFilterData, setItemFilterData] = useState({});
  const [filteredcatalogCourses, setFilteredcatalogCourses] = useState([]);
  const [catalogCourses, setCatalogCourses] = useState([]);
  const [catalog, setCatalog] = useState({});
  const [sectionTab, setSectionTab] = useState("courses");
  const [currentContextData, updateContext] = useCurrentUser();
  const { catalogs, courses, departments, programs } = currentContextData;
  const [currentConversationWith, setCurrentConversationWith] = useState(null);

  React.useEffect(() => {
    (async () => {
      if (params) {
        if (params.id) {
          let secdfd = await getData("section", params.id);
          if (secdfd) {
            if (secdfd) {
            }
          }
        }
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      setForceRerender(!forceRerender);
    })();
  }, [departments, programs, courses]);

  const initializeForm = (currentItemData) => {
    let data = currentItemData || {};
    try {
      setItemFormData({});
      if (currentItemData) {
        setItemFormData(data);
      }
      setForceRerender(!forceRerender);
    } catch (error) {}
  };
 

  const initializeItem = async (tableName, data) => {
    try {
      if (tableName && data) {
        let relativePath = "/api/" + tableName + "/initialize";
        setLoading(true);
        let postvacancyRes = await postDocuments(
          "postvacancy",
          relativePath,
          data
        );
        setLoading(false);
        if (postvacancyRes.success) {
          getData("section", catalog?.id);
          setMode("");
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getData = async (tableName, id) => {
    let details = null;
    try {
      if (tableName && id) {
        let relativePath = "/api/" + tableName + "/find/" + id;
        // setLoading(true);
        let postvacancyRes = await getDocuments("postvacancy", relativePath);
        if (postvacancyRes.success) {
          details = postvacancyRes.record;
        }
      }
    } catch (error) {}
    // setLoading(false);
    if (tableName == "section") {
      console.log("section",details)
      setCatalog(details);
    }
    setForceRerender(!forceRerender);
    return details;
  };

  const editData = async (tableName, id, data) => {
    let details = null;
    try {
      if (tableName && id) {
        let relativePath = "/api/" + tableName + "/edit/" + id;
        // setLoading(true);
        let postvacancyRes = await postDocuments(
          "postvacancy",
          relativePath,
          data
        );
        if (postvacancyRes.success) {
          details = postvacancyRes.record;
          getData("section", catalog?.id);
          setMode("");
        }
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    try {
      let data = itemFormData;
      let relativePath = "/api/catalogcourse/initialize";
      if (mode == "edit") {
        if (currentConversationWith) {
          relativePath =
            "/api/catalogcourse/edit/" + currentConversationWith.id;
        } else return;
      } else if (catalog.id) {
        data.ProgramToDepartmentYearId = catalog.id;
      } else {
        return;
      }
      setLoading(true);
      let postvacancyRes = await postDocuments(
        "postvacancy",
        relativePath,
        data
      );
      setLoading(false);
      if (postvacancyRes.success) {
        getData("catalog", catalog?.id);
        setMode("view");
        if (mode == "add" && postvacancyRes.record) {
          setCurrentConversationWith(postvacancyRes.record);
        } else {
          // object.entries()
          setMode("");
        }
      }
      // setVacancySubmittedResponse(postvacancyRes)
      // setVacancyData(vacancyDataD)
      // setLoading(false)
      // setVacancySubmittedResponse(null)
      // setIsReviewSection(true)
    } catch (error) {
      setLoading(false);
    }
  };

  const getDocuments = async (documentName, relativePath) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    documentsResponse = await HttpService.getService(relativePath, {}, "");
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };

  const handleFormChange = (field, value) => {
    let itemFormDataD = itemFormData;
    itemFormDataD[field] = value;
    setItemFormData(itemFormDataD);
    setForceRerender(!forceRerender);
  };

  const handleFilterChange = (field, value) => {
    try {
      let itemFormDataD = itemFilterData;
      itemFormDataD[field] = value;
      handleFilterData(itemFormDataD);
    } catch (error) {}
  };

  const handleFilterData = (data) => {
    try {
      let itemFormDataD = data || {};
      let itemFormDataArray = Object.entries(itemFormDataD);
      let catalogCoursesD = catalogCourses;
      let catalogCoursesN = catalogCoursesD.filter((sec) => {
        let res = true;
        itemFormDataArray.forEach((filterElement) => {
          if (filterElement[1] != "Any") {
            if (sec[filterElement[0]] != filterElement[1]) {
              res = false;
            }
          }
        });
        return res;
      });
      setFilteredcatalogCourses(catalogCoursesN);
      setItemFilterData(data);
      setMode("");
      setForceRerender(!forceRerender);
    } catch (error) {}
  };

  const onEditClick = (data) => {
    initializeForm(data);
    setMode("edit");
  };

  const onAddClick = () => {
    initializeForm();
    setMode("add");
    setCurrentConversationWith(null);
  };

  const onViewClick = (data) => {
    setCurrentConversationWith(data);
    setMode("view");
    setForceRerender(!forceRerender);
  };

  const editBox = () => {
    return (
      <MDBox p={3}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <div>
              <InputLabel id="demo-simple-select-autowidth-label">
                Course
              </InputLabel>
              <Select
                sx={{ p: 1, mt: 1 }}
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={catagoryId}
                defaultValue={itemFormData.CourseId}
                // label="Age"
                // onChange={handleChange}
                onChange={(e) => handleFormChange("CourseId", e.target.value)}
              >
                {courses?.map((catagory) => (
                  <MenuItem value={catagory.id}>
                    {catagory.title + " || " + catagory.code}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <TextField
              // error
              fullWidth
              id="outlined-error"
              label="Credit Hour"
              type="number"
              onChange={(e) => handleFormChange("creditHour", e.target.value)}
              defaultValue={itemFormData.creditHour}
            />
            {/* <TextField
              multiline
              fullWidth
              rows={3}
              onChange={(e) => handleFormChange("description", e.target.value)}
              // error
              id="outlined-error-helper-text"
              label={"Description"}
              defaultValue={itemFormData.description}
              // helperText="Incorrect entry."
            /> */}
          </div>
          {forceRerender}
        </Box>
      </MDBox>
    );
  };

  function DetailBox() {
    const [instructorsSearched, setInstructorsSearched] = useState([]);
    const [newInstructor, setNewInstructor] = useState({});
    const [changeInstructorMode, setChangeInstructorMode] = useState(false);
    const [forceRerender2, setForceRerender2] = useState(false);

    const changeInstructor = () => {
      console.log("change ", newInstructor);
      console.log("currentConversationWith ", currentConversationWith);
      editData("sectioncourse", currentConversationWith?.sectionCourse?.id, {
        InstructorId: newInstructor.id,
      });
    };

    return (
      <>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
          >
            <MDBadge
              variant="contained"
              color="success"
              badgeContent={currentConversationWith.createdAt}
              container
            />
          </MDBox>
        </>
        <MDBox p={2}>
          <List
            sx={{
              width: "90%",
              // maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ListAlt />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={currentConversationWith?.catalogCourse?.Course?.title}
                secondary="Course title"
              />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ListAlt />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={currentConversationWith?.catalogCourse?.Course?.code}
                secondary="Course code"
              />
            </ListItem>

            <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ListAlt />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  currentConversationWith?.catalogCourse?.creditHour +
                  " hour(s)"
                }
                secondary="Credit Hour"
              />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ListAlt />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={currentConversationWith?.catalogCourse?.preRequest}
                secondary="Pre-request Course"
              />
            </ListItem>
            <Divider sx={{ my: 1 }} />
          {currentConversationWith?.sectionCourse&& <> {changeInstructorMode ? (
              <ListItem
                secondaryAction={
                  <>
                    <IconButton
                      onClick={() => {
                        setChangeInstructorMode(false);
                        changeInstructor();
                      }}
                      edge="end"
                      aria-label="delete"
                    >
                      <Save />
                    </IconButton>
                    <IconButton
                      sx={{ ml: 3 }}
                      onClick={() => {
                        setChangeInstructorMode(false);
                      }}
                      edge="end"
                      aria-label="delete"
                    >
                      <Cancel />
                    </IconButton>
                  </>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <ListAlt />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      value={currentConversationWith?.sectionCourse?.Instructor}
                      onChange={(event, newValue) => {
                        setNewInstructor(newValue);
                      }}
                      options={instructorsSearched}
                      getOptionLabel={(option) =>
                        option?.Employee?.Profile?.firstName +
                        " " +
                        option?.Employee?.Profile?.lastName +
                        " " +
                        option?.Employee?.Profile?.middleName
                      }
                      autoHighlight
                      // getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            src={option?.Employee?.Profile?.profileImageUrl}
                            alt=""
                          />
                          {option?.Employee?.Profile?.firstName +
                            " " +
                            option?.Employee?.Profile?.lastName +
                            " " +
                            option?.Employee?.Profile?.middleName}
                        </Box>
                      )}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          onChange={async (e) => {
                            let instD =
                              await CommonApiServices.searchDataByKeyword(
                                "instructor",
                                e.target.value
                              );
                            setInstructorsSearched(instD);
                            setForceRerender2(!forceRerender2);
                          }}
                          {...params}
                          label="Instructor"
                        />
                      )}
                    />
                  }
                  secondary="Change Instructor"
                />
              </ListItem>
            ) : (
              <ListItem
                secondaryAction={
                  <IconButton
                    onClick={() => {
                      setChangeInstructorMode(true);
                    }}
                    edge="end"
                    aria-label="delete"
                  >
                    <Edit />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <ListAlt />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    currentConversationWith?.sectionCourse?.Instructor
                      ? currentConversationWith?.sectionCourse?.Instructor
                          ?.Employee?.Profile?.firstName +
                        " " +
                        currentConversationWith?.sectionCourse?.Instructor
                          ?.Employee?.Profile?.lastName +
                        " " +
                        currentConversationWith?.sectionCourse?.Instructor
                          ?.Employee?.Profile?.middleName
                      : "Not assigned"
                  }
                  secondary="Instructor"
                />
              </ListItem>
            )}</> }
          </List>
          {forceRerender2}
        </MDBox>
      </>
    );
  }

  function CatalogDetailsBox(dataD) {
    let data = dataD?.data || {};
    let catalogD = dataD?.data?.ProgramToDepartmentYear || {};
    return (
      <Card sx={{ overflow: "visible" }}>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5">Section Details</MDTypography>
            <MDBadge
              disabled={catalogD?.finishedAssigningCourses}
              component={Link} 
              to={"/Academic/SectionGroups/"+catalogD?.id}
              variant="contained" 
              color="success"
              badgeContent={"Back"}
              container
            />
          </MDBox>
        </>
        <MDBox p={2}>
          <List
            dense
            sx={{
              width: "100%",
              // maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >
            {/* <ListItemComp
              data={{
                ft: "Section Number ",
                v: "Section " + data?.sectionNumber,
                a: null,
              }}
            /> */}

            <Grid container spacing={3}>
              <Grid item md={8}>
                <ListItemComp
                  data={{
                    ft: "Section Group ",
                    v: catalogD?.ProgramToDepartment?.Program?.title+" || " +catalogD?.ProgramToDepartment?.EducationClassification?.title+" || "+ catalogD?.ProgramToDepartment?.Department?.title+" || "+ 
                    CommonServices.ordinal_suffix_of(catalogD?.acadamicYear) + " year" ,
                    a: null,
                  }}
                />  
              </Grid>
              <Grid item md={4}>  
                <ListItemComp
                  data={{
                    ft: "Section Number",
                    v: " section - " + data?.sectionNumber,
                    a: null,
                  }}
                /> 
              </Grid>
            </Grid>
          </List>
        </MDBox>
      </Card>
    );
  }

  const getSectionCourseByCatalogCourseId = (ccid) => {
    let res = null;
    try {
      if (catalog && ccid) {
        if (catalog.SectionCourses) {
          if (catalog.SectionCourses.length) {
            let resD = catalog.SectionCourses.filter(
              (sc) => sc.CatalogCourseId == ccid
            );
            if (resD) {
              if (resD.length) {
                res = resD[0];
              }
            }
          }
        }
      }
    } catch (error) {}
    return res;
  };

  const renderSidenavItems = catalog?.ProgramToDepartmentYear?.CatalogCourses?.map(
    (message, key) => {
      const itemKey = `item-${key}`;
      console.log("getSectionCourseByCatalogCourseId",message)
      // console.log(" Section Course Data", )
      let sectionCourse = getSectionCourseByCatalogCourseId(message.id) || null;
      return (
        <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
          <MDTypography
            component="a"
            // href={`#${href}`}
            onClick={() => {
              onViewClick({
                catalogCourse: message,
                sectionCourse: sectionCourse,
              });
            }}
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            sx={({
              borders: { borderRadius },
              functions: { pxToRem },
              palette: { light },
              transitions,
            }) => ({
              display: "flex",
              alignItems: "center",
              borderRadius: borderRadius.md,
              padding: `${pxToRem(10)} ${pxToRem(16)}`,
              transition: transitions.create("background-color", {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.shorter,
              }),

              "&:hover": {
                backgroundColor: light.main,
              },
              backgroundColor: currentConversationWith
                ? currentConversationWith.id == message.id
                  ? light.main
                  : "none"
                : "none",
            })}
          >
            {/* <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}> */}
            <MDBox mr={2}>
              <Avatar alt="something here">
                <Book />
              </Avatar>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <MDTypography variant="button" fontWeight="medium">
                {message?.Course?.title} {" | "}
                {message?.Course?.code}
              </MDTypography>
              <MDTypography variant="caption" color="text">
                {" Credit Hour: " + message?.creditHour}
              </MDTypography>
            </MDBox>
          </MDTypography>
        </MDBox>
      );
    }
  );
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar2 />
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox mb={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card id="profile">
                      <CatalogDetailsBox data={catalog } />
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
           
            <Grid item xs={12}>
              <MDBox>
                <Grid container>
                  <MDBox sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      <Tabs value={sectionTab} onChange={(e,v)=>setSectionTab(v)} centered>
                        <Tab label="Courses" value="courses"/>
                        <Tab label="Students" value="students"/>
                      </Tabs>
                  </MDBox>
                </Grid>
              </MDBox>
            </Grid>
   
          {sectionTab=="courses"&&<>
            <Grid item xs={12}>
              <MDBox>
                <Grid container>
                  <Grid item xs={12}>
                    <Card id="profile">
                      <MDBox p={1}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item>
                            <MDBox height="100%" mt={0.5} lineHeight={1}>
                              <MDTypography variant="h5" fontWeight="medium">
                                {catalog?.SectionCourses?.length +
                                  " Course(s) Enrolled"}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
                            <MDBox
                              display="flex"
                              justifyContent={{ md: "flex-end" }}
                              alignItems="center"
                              lineHeight={1}
                            >
                              {/* {  (catalog?.finishedAssigningCourses)?null:<Button
                                variant="button"
                                color="text"
                                fontWeight="medium"
                                onClick={() => {
                                  onAddClick();
                                }}
                              >
                                Add New
                              </Button>} */}
                            </MDBox>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>

            {!(currentConversationWith && isMobile) && (
              <Grid item xs={12} lg={mode == "" ? 12 : 5}>
                <Card
                  sx={{
                    maxHeight: isMobile ? "700px" : "420px",
                    overflowY: "auto",
                    borderRadius: ({ borders: { borderRadius } }) =>
                      borderRadius.lg,
                    position: "sticky",
                    top: "1%",
                  }}
                >
                  <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                  >
                    {renderSidenavItems}
                  </MDBox>
                </Card>
              </Grid>
            )}

            {mode != "" && currentConversationWith && (
              <Grid item xs={12} lg={7}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="profile">
                        <MDBox p={2}>
                          <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <ArrowBackIosNewSharp
                                  onClick={() => {
                                    setCurrentConversationWith(null);
                                    setMode("");
                                  }}
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />
                              </Grid>
                            }
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {
                                    currentConversationWith?.catalogCourse
                                      ?.Course?.title
                                  }
                                </MDTypography>
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )}

                                {mode != "edit" ? (
                                  <>
                                    {catalog?.finishedAssigningCourses ? null : (
                                      <>
                                        {currentConversationWith?.sectionCourse ? (
                                          <Button
                                            variant="button"
                                            color="text"
                                            fontWeight="medium"
                                            // onClick={() => {
                                            //   deleteItem("sectioncourse",currentConversationWith.id);
                                            // }}
                                          >
                                            {"Inactivate"}
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="button"
                                            color="text"
                                            fontWeight="medium"
                                            onClick={() => {
                                              try {
                                                let SectionId = catalog?.id;
                                                let CatalogCourseId =
                                                  currentConversationWith
                                                    ?.catalogCourse?.id;

                                                if (
                                                  SectionId &&
                                                  CatalogCourseId
                                                ) {
                                                  initializeItem(
                                                    "sectioncourse",
                                                    {
                                                      SectionId: SectionId,
                                                      CatalogCourseId:
                                                        CatalogCourseId,
                                                    }
                                                  );
                                                }
                                              } catch (error) {}
                                            }}
                                          >
                                            {"Start"}
                                          </Button>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      variant="button"
                                      color="text"
                                      fontWeight="medium"
                                      onClick={() => {
                                        handleSubmit();
                                      }}
                                    >
                                      {"Save"}
                                    </Button>
                                    <Button
                                      variant="button"
                                      color="text"
                                      fontWeight="medium"
                                      onClick={() => {
                                        setMode("");
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                )}
                                {/* <MDBox ml={1}>
                            <Switch checked={visible} onChange={handleSetVisible} />
                          </MDBox> */}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card id="2fa" sx={{ overflow: "visible" }}>
                        {mode != "edit" ? (
                          <>
                            <DetailBox />
                          </>
                        ) : (
                          <>{editBox()}</>
                        )}
                      </Card>{" "}
                    </Grid>
                    {forceRerender}
                  </Grid>
                </MDBox>
              </Grid>
            )}

            {mode == "add" && (
              <Grid item xs={12} lg={7}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="profile">
                        <MDBox p={2}>
                          <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <ArrowBackIosNewSharp
                                  onClick={() => {
                                    setCurrentConversationWith(null);
                                    setMode("");
                                  }}
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />
                              </Grid>
                            }
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {"Add New"}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )}

                                <>
                                  <Button
                                    variant="button"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => {
                                      handleSubmit();
                                    }}
                                  >
                                    {"Sumbit"}
                                  </Button>
                                  <Button
                                    variant="button"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => {
                                      setMode("");
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card id="2fa" sx={{ overflow: "visible" }}>
                        {editBox()}
                      </Card>{" "}
                    </Grid>
                    {forceRerender}
                  </Grid>
                </MDBox>
              </Grid>
            )}</>}
          </Grid>
        </MDBox>
      </DashboardLayout>
      {forceRerender}
    </>
  );
}

export default SectionDetails;
