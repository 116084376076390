/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

sectionNumberd by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import MDBadge from "components/MDBadge";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import {
  ArrowBackIosNewSharp,
  Book,
  CarRentalOutlined,
  Difference,
  ListAlt,
} from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import DashboardNavbar2 from "examples/Navbars/DashboardNavbar2";
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HttpService } from "Api-Services/httpService";
import { DataGrid, GridLogicOperator, GridToolbar } from "@mui/x-data-grid";
import { CommonServices } from "Api-Services/commonServices";
import MDButton from "components/MDButton";
import ListItemComp from "components/common-components/listItemComp";
import Profile from "layouts/pages/users/new-user/components/Profile";
function RequestStudentAccount() {
  const navigate = useNavigate();
  const params = useParams();
  const [firstLoading, setFirstLoading] = useState(false);
  const [controller] = useMaterialUIController();
  const [show, setShow] = useState(true);
  const [showFiltered, setShowFiltered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forceRerender, setForceRerender] = useState(true);
  const [mode, setMode] = useState(""); 
  const [filteredCatalogs, setFilteredCatalogs] = useState([]);
  const [currentContextData, updateContext] = useCurrentUser();
  const {catalogs,programToDepartments,educationClassifications, departments,programs } = currentContextData; 
  const [itemFormData, setItemFormData] = useState({});
  const [profile, setProfile] = useState({});
  const [itemFilterData, setItemFilterData] = useState({});
  const [catalogsData , setCatalogsData] = useState([]);
  const [currentConversationWith, setCurrentConversationWith] = useState(null);

  React.useEffect(() => {
    (async () => {
      // setFilteredprofiles([]);
      console.log("params", params);
      if (params) {
        if (params.id) {
          setForceRerender(!forceRerender);
          setFirstLoading(true);
          await getData("profile", params.id);
          setFirstLoading(false);
        }
      }
      // console.log("profiles", profiles);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      let rows=[]
      if(programToDepartments){
        if(programToDepartments.length){
          programToDepartments.forEach(element => {
              let newEl=element
              // newEl.id= element.id, 
              // newEl.catalogSemister= element?.catalogSemister  
              // newEl.catalogYear= element?.catalogYear  
              //  section: element?.SectionCourse?.Section?.sectionNumber,  
              newEl.department= element?.Department?.title  
              newEl.educationClassification= element?.EducationClassification?.title  
              newEl.program= element?.Program?.title
              // newEl.acadamicYear= element?.acadamicYear   
              //  status: element?.status,  
              //  grade: element?.grade, 
              newEl.data=element , 
            rows.push(newEl)
          });
        }

      }


      // setFilteredCatalogs(rows); 
      setCatalogsData(rows)
      setForceRerender(!forceRerender);
      console.log("programToDepartments", rows);
    })();
  }, [programToDepartments]);

  React.useEffect(() => {
    (async () => { 
      setForceRerender(!forceRerender); 
    })();
  }, [catalogs,departments,programs]);
  const initializeForm = (currentItemData) => {
    let data = currentItemData || {};
    try {
      setItemFormData({});
      if (currentItemData) {
        setItemFormData(data);
      }
      setForceRerender(!forceRerender);
    } catch (error) {}
  };
 
  const handleSubmit = async () => {
    try {
      let data = itemFormData;
      let relativePath = "/api/studentaccountrequest/initialize";
      if (mode == "edit") {
        if (currentConversationWith) {
          relativePath = "/api/studentaccountrequest/edit/" + currentConversationWith.id;
        } else return;
      }
      setLoading(true);
      let postvacancyRes = await postDocuments(
        "postvacancy",
        relativePath,
        data
      );
      setLoading(false);
      if (postvacancyRes.success) {
        updateContext("catalogs");
        setMode("view");
        if (mode == "add" && postvacancyRes.record) {
          setCurrentConversationWith(postvacancyRes.record);
        } else {
          // object.entries()
          setMode("");
        }
      }
      // setVacancySubmittedResponse(postvacancyRes)
      // setVacancyData(vacancyDataD)
      // setLoading(false)
      // setVacancySubmittedResponse(null)
      // setIsReviewSection(true)
    } catch (error) {
      setLoading(false);
    }
  };

  const sendRequest = async (catD,programToDepartmentYearId) => {
    try {
      let data = {
        ProgramToDepartmentId:catD?.id,
        ProgramToDepartmentYearId:programToDepartmentYearId,
        ProfileId:profile?.id
      };

      if(data.ProfileId&&data.ProgramToDepartmentId&&data.ProgramToDepartmentYearId){
        let relativePath = "/api/studentaccountrequest/initialize"; 
        setLoading(true);
        let postvacancyRes = await postDocuments(
          "postvacancy",
          relativePath,
          data
        ); 
        if (postvacancyRes.success) {
          navigate("/Academic/Profiles/"+params.id)
        } 
    }
    } catch (error) {
    }
      setLoading(false);
  };

  const getDocuments = async (documentName, relativePath) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    documentsResponse = await HttpService.getService(relativePath, {}, "");
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };
 
  const handleFormChange = (field, value) => {
    let itemFormDataD = itemFormData;
    itemFormDataD[field] = value;
    setItemFormData(itemFormDataD);
    setForceRerender(!forceRerender);
  };

  const handleFilterChange = (field, value) => {
    try {
      let itemFormDataD = itemFilterData;
      itemFormDataD[field]=value 
      setShowFiltered(false)
      setItemFilterData(itemFormDataD)
      } catch (error) {
    }
  };

  
  const handleFilterData = () => {
    try {
       
      let itemFormDataD = itemFilterData||{}; 
      console.log("itemFormDataD",itemFormDataD)
      console.log("catalogsData",catalogsData)
      let itemFormDataArray=Object.entries(itemFormDataD)
      let CatalogsD=catalogsData
      let CatalogsN = CatalogsD.filter((sec)=>{
        let res=true
        itemFormDataArray.forEach(filterElement => {
          if(filterElement[1]!="Any"){
            if(sec[filterElement[0]]!=filterElement[1]){
              res=false
            }
          }
        });
      //  console.log("sec",sec,field, value) 
        return res
      })
      console.log("CatalogsN",CatalogsN)
      setFilteredCatalogs(CatalogsN)
      setShowFiltered(true)
      // setItemFilterData(data)
      setMode("")
      setForceRerender(!forceRerender);
    } catch (error) {
      
    }
  };

  
  const getData = async (tableName, id) => {
    let details = {};
    try {
      if (tableName && id) {
        let relativePath = "/api/" + tableName + "/find/" + id;
        // setLoading(true);
        let postvacancyRes = await getDocuments("postvacancy", relativePath);
        if (postvacancyRes.success) {
          details = postvacancyRes.record;
        }
      }
    } catch (error) {}
    // setLoading(false);
    if(tableName=="catalog"){
    // setCatalogsData(details);
    }
    if(tableName=="profile"){
      setProfile(details);
    }
  };
 

 
  const onAddClick = () => {
    initializeForm();
    setMode("add");
    setCurrentConversationWith(null);
  };

  const onViewClick = (data) => {
    // console.log("data",data)
    setCurrentConversationWith(data);
    setMode("view");
    setForceRerender(!forceRerender);
  };

  function ProfileBox(dataD) {
    let data = dataD?.data || {};
 

    return (
      <Card id="2fa" sx={{ overflow: "visible" }}>
        {/* <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
          >
            <MDTypography variant="h5">Profile  </MDTypography>
          </MDBox>
       
        </> */}
        <MDBox p={1}>
          <List
            dense
            sx={{
              width: "100%",
              minWidth: 360,
              bgcolor: "background.paper",
            }}
          > 
            <ListItemComp 
              data={{ 
                v: data.firstName +" "+ 
                  data.lastName +" "+  
                 data.middleName  ,
                av: data?.profileImageUrl, 
                // ft: "Profile ",
              }}
            /> 
          </List>
        </MDBox>
      </Card>
    );
  }

  function CatalogList(dataD) {
    let data = dataD?.data || [];

    const columns = [
      { field: 'id', headerName: '#', width: 30 },
        
      // {
      //   field: 'catalogYear',
      //   headerName: 'Year',
      //   // type: 'number',
      //   width: 70,
      // },
      // {
      //   field: 'catalogSemister',
      //   headerName: 'Semister',
      //   // type: 'number',
      //   // width: 210,
      // },
      {
        field: 'program',
        headerName: 'Program',
        // type: 'number',
        // width: 210,
      },
      {
        field: 'educationClassification',
        headerName: 'Classification',
        // type: 'number',
        width: 150,
      },
      {
        field: 'department',
        headerName: 'Department',
        // type: 'number',
        width: 300,
      },
      // {
      //   field: 'acadamicYear',
      //   headerName: 'Acadamic Year',
      //   // type: 'number',
      //   width: 150,
      // },
      // {
      //   field: 'CatalogCoursesCount',
      //   headerName: 'Courses',
      //   // type: 'number',
      //   width: 110,
      // },
    ];
    
    const columnGroupingModel = [
    {
    groupId: 'Internal',
    description: '',
    children: [{ field: 'id' }],
    },
    {
    groupId: 'Basic info',
    children: [
      {
        groupId: 'Full name',
        children: [{ field: 'lastName' }, { field: 'firstName' }],
      },
      { field: 'age' },
    ],
    },
    ];
    // const result = Object.groupBy(data, ({  }) => id);
    console.log("data",data)
    let rows=data
    // const rows = [
    //   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
    //   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
    //   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
    //   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
    //   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    //   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    //   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    //   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    //   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    // ];

    console.log("rows",rows)
    return (
      <Card id="2fa" sx={{ overflow: "visible" }}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          <MDTypography variant="h5">Select Section Groups  </MDTypography>
          {/* <MDBadge
            component={Link}
            // to={"/Academic/Students/"+student?.id+"/Enrollments/"}
            variant="contained"
            color="success"
            badgeContent={"Manage"}
            container
          /> */}
        </MDBox> 
        <MDBox p={2}>
          <List
            dense
            sx={{
              width: "100%",
              // maxWidth: 360,
              bgcolor: "background.paper",
            }}
          > 
            <div style={{ 
              // height: 400, 
              // width: '100%'
               }}>
          <DataGrid 
          
          rows={rows}
          columns={columns}
            slots={{
              toolbar: GridToolbar,
              // Use custom FilterPanel only for deep modification
              // FilterPanel: MyCustomFilterPanel,
            }}
            onCellClick={ (params, event, details) =>{
              console.log("params, event, details",params, event, details)
              if(params){
                if(params.row){ 
                    onViewClick(params.row);
              }
              }
            }}
            
            disableColumnSelector
            disableDensitySelector
            hideFooter
            // slots={{ toolbar: GridToolbar }}
            // slotProps={{  }} 

            slotProps={{
              toolbar: { showQuickFilter: true },
              filterPanel: {
                // Force usage of "And" operator
                logicOperators: [GridLogicOperator.And],
                // Display columns by ascending alphabetical order
                columnsSort: 'asc',
                filterFormProps: {
                  // Customize inputs by passing props
                  logicOperatorInputProps: {
                    variant: 'outlined',
                    size: 'small',
                  },
                  columnInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: { mt: 'auto' },
                  },
                  operatorInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: { mt: 'auto' },
                  },
                  valueInputProps: {
                    InputComponentProps: {
                      variant: 'outlined',
                      size: 'small',
                    },
                  },
                  deleteIconProps: {
                    sx: {
                      '& .MuiSvgIcon-root': { color: '#d32f2f' },
                    },
                  },
                },
                sx: {
                  // Customize inputs using css selectors
                  '& .MuiDataGrid-filterForm': { p: 2 },
                  '& .MuiDataGrid-filterForm:nth-child(even)': {
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                  },
                  '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                  '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                  '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                  '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                },
              },
            }}

            initialState={{
              ...data.initialState,
              filter: {
                ...data.initialState?.filter,
                filterModel: {
                  items: [
                    {
                      id: 1,
                      field: 'name',
                      operator: 'contains',
                      value: 'D',
                    },
                    {
                      id: 2,
                      field: 'name',
                      operator: 'contains',
                      value: 'D',
                    },
                    {
                      id: 3,
                      field: 'rating',
                      operator: '>',
                      value: '0',
                    },
                  ],
                },
              },
            }}
          />
          </div>  
          </List>
        </MDBox>
      </Card>
    );
  }

  const editBox = () => {
    return (
      <MDBox p={3}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
          }}
          noValidate
          autoComplete="off"
        > 
          {/* < MDBox sx={{p:2}}> 

          <InputLabel id="demo-simple-select-autowidth-label">
              Catalog Year
            </InputLabel>
            <Select
              sx={{ p: 1, mt: 1 }}
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={catagoryId}
              defaultValue={itemFormData.catalogYear}
              // label="Age"
              // onChange={handleChange}
              onChange={(e) =>
                handleFormChange("catalogYear", e.target.value)
              }
            >
              {[...Array(5)].map((n,i) =>{ 
                let d =new Date()
                let dy= d.getFullYear()
               return <MenuItem value={dy+i}>{dy+i +" G.C"}</MenuItem>
              })}
            </Select>

          </MDBox>
          < MDBox sx={{p:2}}> 

          <InputLabel id="demo-simple-select-autowidth-label">
             Catalog Semister
            </InputLabel>
            <Select
              sx={{ p: 1, mt: 1 }}
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={catagoryId}
              defaultValue={itemFormData.catalogSemister}
              // label="Age"
              // onChange={handleChange}
              onChange={(e) =>
                handleFormChange("catalogSemister", e.target.value)
              }
            >
              {[...Array(3)].map((n,i) =>{  
               return <MenuItem value={1+i}>{CommonServices.ordinal_suffix_of( 1+i) +" Semister "}</MenuItem>
              })}
            </Select>

          </MDBox> */}
          < MDBox sx={{p:2}}> 

          <InputLabel id="demo-simple-select-autowidth-label">
              Program
            </InputLabel>
            <Select
              sx={{ p: 1, mt: 1 }}
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={catagoryId}
              defaultValue={itemFormData.ProgramId}
              // label="Age"
              // onChange={handleChange}
              onChange={(e) =>
                handleFormChange("ProgramId", e.target.value)
              }
            >
              {programs?.map((catagory) => (
                <MenuItem value={catagory.id}>{catagory.title}</MenuItem>
              ))}
            </Select>

          </MDBox>
          < MDBox sx={{p:2}}> 

          <InputLabel id="demo-simple-select-autowidth-label">
          Education Classification 
            </InputLabel>
            <Select
              sx={{ p: 1, mt: 1 }}
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={catagoryId}
              defaultValue={itemFormData.EducationClassificationId}
              // label="Age"
              // onChange={handleChange}
              onChange={(e) =>
                handleFormChange("EducationClassificationId", e.target.value)
              }
            >
              {educationClassifications?.map((catagory) => (
                <MenuItem value={catagory.id}>{catagory.title}</MenuItem>
              ))}
            </Select>

          </MDBox>
          <MDBox sx={{p:2}}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Department
            </InputLabel>
            <Select
              sx={{ p: 1, mt: 1 }}
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={catagoryId}
              defaultValue={itemFormData.DepartmentId}
              // label="Age"
              // onChange={handleChange}
              onChange={(e) =>
                handleFormChange("DepartmentId", e.target.value)
              }
            >
              {departments?.map((catagory) => (
                <MenuItem value={catagory.id}>{catagory.title}</MenuItem>
              ))}
            </Select>
          </MDBox>
          < MDBox sx={{p:2}}> 

          <InputLabel id="demo-simple-select-autowidth-label">
            Acadamic Year
            </InputLabel>
            <Select
              sx={{ p: 1, mt: 1 }}
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={catagoryId}
              defaultValue={itemFormData.acadamicYear}
              // label="Age"
              // onChange={handleChange}
              onChange={(e) =>
                handleFormChange("acadamicYear", e.target.value)
              }
            >
              {[...Array(7)].map((n,i) =>{  
               return <MenuItem value={1+i}>{CommonServices.ordinal_suffix_of( 1+i) +" Year"}  </MenuItem>
              })}
            </Select>

          </MDBox>  
            {forceRerender}
        </Box>
      </MDBox>
    );
  };

    const filterBox =()=> {
    return (<>
   <>
          
   <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={1}
            py={0}
            onClick={() => {setShow(!show) 
            }}
          >
            <MDTypography variant="h5">Filter </MDTypography>
 
              <MDBadge
                component={Button}
                variant="outlined" 
                color="success"
                onClick={() => {setShow(!show) 
                }}
                badgeContent={show?"Hide":"Show"}
                container
              /> 
          </MDBox>
       
        </>
               { show&&  <MDBox p={1}>
                        <Grid container spacing={3} alignItems="center">
                          
                          <Grid item md={2}>
                            <MDBox height="100%" mt={0.5} lineHeight={1}>
                            <InputLabel id="demo-simple-select-autowidth-label">
                              Program
                            </InputLabel>
                            <Select
                              sx={{ p: 1, mt: 1 }}
                              fullWidth
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              // value={catagoryId}
                              defaultValue={"Any"}
                              // label="Age"
                              // onChange={handleChange}
                              onChange={(e) =>{
                                handleFilterChange("program", e.target.value)
                              }
                              }
                            >
                                <MenuItem value={"Any"}>{"Any"}</MenuItem>
                              {programs?.map((program) => (
                                <MenuItem value={program.title}>{program.title}</MenuItem>
                              ))}
                            </Select>
                          
                            </MDBox>
                          </Grid> 
                          <Grid item md={2}>
                            <MDBox height="100%" mt={0.5} lineHeight={1}>
                            <InputLabel id="demo-simple-select-autowidth-label">
                             Classification 
                            </InputLabel>
                            <Select
                              sx={{ p: 1, mt: 1 }}
                              fullWidth
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              // value={catagoryId}
                              defaultValue={"Any"}
                              // label="Age"
                              // onChange={handleChange}
                              onChange={(e) =>{
                                handleFilterChange("educationClassification", e.target.value)
                              }
                              }
                            >
                                <MenuItem value={"Any"}>{"Any"}</MenuItem>
                              {educationClassifications?.map((program) => (
                                <MenuItem value={program.title}>{program.title}</MenuItem>
                              ))}
                            </Select>
                          
                            </MDBox>
                          </Grid> 
                          <Grid item md={3}>
                            <MDBox height="100%" mt={0.5} lineHeight={1}>
                            <InputLabel id="demo-simple-select-autowidth-label">
                              Department
                            </InputLabel>
                            <Select
                              sx={{ p: 1, mt: 1 }}
                              fullWidth
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              // value={catagoryId}
                              defaultValue={"Any"}
                              // label="Age"
                              // onChange={handleChange}
                              onChange={(e) =>{
                                handleFilterChange("department", e.target.value)
                              }
                              }
                            >
                                <MenuItem value={"Any"}>{"Any"}</MenuItem>
                              {departments?.map((department) => (
                                <MenuItem value={department.title}>{department.title}</MenuItem>
                              ))}
                            </Select>
                          
                            </MDBox>
                          </Grid> 
                          {/* <Grid item md={3}>
                            <MDBox height="100%" mt={0.5} lineHeight={1}>
                            <InputLabel id="demo-simple-select-autowidth-label">
                              Acadamic Year
                            </InputLabel>
                            <Select
                              sx={{ p: 1, mt: 1 }}
                              fullWidth
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              // value={catagoryId}
                              defaultValue={"Any"}
                              // label="Age"
                              // onChange={handleChange}
                              onChange={(e) =>{
                                handleFilterChange("acadamicYear", e.target?.value?.title)
                              }
                              }
                            >  
                                <MenuItem value={"Any"}>{"Any"}</MenuItem>
                              {[...Array(5)].map((n,i) => ( 
                 <MenuItem value={1+i}>{CommonServices.ordinal_suffix_of( 1+i) +" Year"}  </MenuItem>
                              ))}
                            </Select>
                          
                            </MDBox>
                          </Grid>  */}
                          <Grid item md={2}>
                            <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDButton 
                                  variant="contained"
                                  disable={loading}
                                  color="success"
                                  onClick={() => {
                                    handleFilterData()
                                    // initializeTableData({
                                    //   data: { ProfileId: profile?.id },
                                    //   tableName: "address",
                                    // });
                                  }} 
                                >{"Search"}</MDButton> </MDBox>
                                  </Grid>
                                  </Grid>
                      </MDBox>}


 
    </>);
  };

  function DetailBox() { 
  const [programToDepartmentYearId, setProgramToDepartmentYearId] = useState(null);
    return (
      <>
        {/* <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5">{""}</MDTypography>
            <MDBadge
              variant="contained"
              color="success"
              badgeContent={currentConversationWith.createdAt}
              container
            />
          </MDBox>
  
        </> */}
        <MDBox p={3}>
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >   
           
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ListAlt />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  currentConversationWith?.
                  program 
                }
                secondary="Program"
              />
            </ListItem> 
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ListAlt />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  currentConversationWith?.EducationClassification?.title 
                }
                secondary="Education Classification"
              />
            </ListItem>    
            <Divider sx={{ my: 1 }} />    
          </List>
          
          <MDBox 
            alignItems="center"
            p={2} spacing={2}
          > 
          <MDTypography my={2} variant="h5">{"Choose Acadamic Year"}</MDTypography>
          <Stack my={2} direction="row" spacing={1}>
          { currentConversationWith?.ProgramToDepartmentYears?.map((departmentYear)=>
         (departmentYear?.id==programToDepartmentYearId) ?
            <Chip color="primary" label={ CommonServices.ordinal_suffix_of(departmentYear?.acadamicYear)+" year" } />:
            <Chip   onClick={()=>setProgramToDepartmentYearId(departmentYear?.id)}
              variant="outlined" label={ CommonServices.ordinal_suffix_of(departmentYear?.acadamicYear)+" year" } /> 
              )}
          </Stack>
  </MDBox>
          <MDBox 
            alignItems="center"
            p={2} 
          > 
          
          <MDButton my={2}
                                    // component={Link}
                                    // to={"/Academic/SectionGroups/"+currentConversationWith?.id}
                                      variant="contained"
                                      color="success"
                                      size={"small"}
                                      disabled={programToDepartmentYearId==null}
                                      onClick={() => {
                                        sendRequest(currentConversationWith,programToDepartmentYearId);
                                      }}
                                      // fontWeight="medium" 
                                    >
                                      {"Send Request"}
                                    </MDButton> 
          </MDBox>
        </MDBox>
      </>
    );
  }
 
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar2 />
        <MDBox mt={2}>
          <Grid container spacing={3}> 
            <Grid item xs={12}>
              <MDBox mb={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <ProfileBox data={profile}/>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>

            <Grid item xs={12}>
              <MDBox mb={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card id="profile">
                    {filterBox()}
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>

            {!(currentConversationWith && isMobile) && (
              <Grid item xs={12} display={{xs: mode == "" ? 12 :"none",}}  >
                <Card
                  sx={{
                    maxHeight: isMobile ? "700px" : "420px",
                    overflowY: "auto",
                    borderRadius: ({ borders: { borderRadius } }) =>
                      borderRadius.lg,
                    position: "sticky",
                    top: "1%",
                  }}
                >
                  <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                  >
                    {/* {renderSidenavItems} */}
                  { showFiltered&& <CatalogList data={filteredCatalogs}/>}
                  </MDBox>
                </Card>
              </Grid>
            )}
            {mode != "" && currentConversationWith && (
              <Grid item xs={12} lg={12}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="profile">
                        <MDBox p={2}>
                          <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <ArrowBackIosNewSharp
                                  onClick={() => {
                                    setCurrentConversationWith(null);
                                    setMode("");
                                  }}
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />
                              </Grid>
                            }
                            {/* <Grid item>
                              <MDAvatar
                                src={currentConversationWith.image}
                                alt="profile-image"
                                size="xl"
                                shadow="sm"
                              />
                            </Grid> */}
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {currentConversationWith.department}
                                </MDTypography>
                                {/* <MDTypography
                                  variant="button"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  {"Edit"}
                                </MDTypography>  */}
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={6}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )}
                                {/* <MDTypography
                                  variant="caption"
                                  fontWeight="regular"
                                > 
                                  {currentConversationWith.createdAt}
                                </MDTypography> */}

                                {mode != "edit" ? (
                                  <>
                                    {/* <MDButton
                                    // component={Link}
                                    // to={"/Academic/SectionGroups/"+currentConversationWith?.id}
                                      variant="contained"
                                      color="success"
                                      size={"small"}
                                      onClick={() => {
                                        sendRequest(currentConversationWith);
                                      }}
                                      // fontWeight="medium" 
                                    >
                                      {"Send Request"}
                                    </MDButton>  */}
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      variant="button"
                                      color="text"
                                      fontWeight="medium"
                                      onClick={() => {
                                        handleSubmit();
                                      }}
                                    >
                                      {"Save"}
                                    </Button>
                                    <Button
                                      variant="button"
                                      color="text"
                                      fontWeight="medium"
                                      onClick={() => {
                                        setMode("");
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                )}
                                {/* <MDBox ml={1}>
                            <Switch checked={visible} onChange={handleSetVisible} />
                          </MDBox> */}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card id="2fa" sx={{ overflow: "visible" }}>
                        {mode != "edit" ? (
                          <>
                            <DetailBox />
                          </>
                        ) : (
                          <>{editBox()}</>
                        )}
                      </Card>{" "}
                    </Grid>
                    {forceRerender}
                  </Grid>
                </MDBox>
              </Grid>
            )}
            {mode == "add" && (
              <Grid item xs={12} lg={12}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="profile">
                        <MDBox p={2}>
                          <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <ArrowBackIosNewSharp
                                  onClick={() => {
                                    setCurrentConversationWith(null);
                                    setMode("");
                                  }}
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />
                              </Grid>
                            }
                            {/* <Grid item>
                              <MDAvatar
                                src={currentConversationWith.image}
                                alt="profile-image"
                                size="xl"
                                shadow="sm"
                              />
                            </Grid> */}
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {"Add New"}
                                </MDTypography>
                                {/* <MDTypography
                                  variant="button"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  {"Edit"}
                                </MDTypography>  */}
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )}

                                <>
                                  <Button
                                    variant="button"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => {
                                      handleSubmit();
                                    }}
                                  >
                                    {"Sumbit"}
                                  </Button>
                                  <Button
                                    variant="button"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => {
                                      setMode("");
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </>
                                {/* <MDBox ml={1}>
                            <Switch checked={visible} onChange={handleSetVisible} />
                          </MDBox> */}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card id="2fa" sx={{ overflow: "visible" }}>
                        {editBox()}
                      </Card>{" "}
                    </Grid>
                    {forceRerender}
                  </Grid>
                </MDBox>
              </Grid>
            )}
          </Grid>
        </MDBox>
      </DashboardLayout>
      {forceRerender}
    </>
  );
}
 
export default RequestStudentAccount; 
