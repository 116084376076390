// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import MDBadge from "components/MDBadge";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { Cancel, ListAlt, ViewAgenda } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import DashboardNavbar2 from "examples/Navbars/DashboardNavbar2";
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Skeleton,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HttpService } from "Api-Services/httpService";
import MDButton from "components/MDButton";
import ListItemComp from "components/common-components/listItemComp";
import { CommonApiServices } from "Api-Services/commonApiServices";
import { CommonServices } from "Api-Services/commonServices";
function ProfileDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const [firstLoading, setFirstLoading] = useState(false);

  const [controller] = useMaterialUIController();
  const [loading, setLoading] = useState(false);
  const [forceRerender, setForceRerender] = useState(true);
  const [response, setResponse] = useState(null);
  const [mode, setMode] = useState("add");
  const [editItemId, setEditItemId] = useState(false);
  const [profile, setProfile] = useState({});
  const [currentConversationWith, setCurrentConversationWith] = useState(null);

  React.useEffect(() => {
    (async () => {
      getStarted()
      // console.log("profiles", profiles);
    })();
  }, []);
  
  const getStarted = async () => {
      // setFilteredprofiles([]);
      console.log("params", params);
      if (params) {
        if (params.id) {
          setFirstLoading(true);
          await getData("profile", params.id);
          setFirstLoading(false);
        }
      }
     setForceRerender(!forceRerender);
  };

  const getData = async (tableName, id) => {
    let details = {};
    try {
      if (tableName && id) {
        let relativePath = "/api/" + tableName + "/find/" + id;
        // setLoading(true);
        let postvacancyRes = await getDocuments("postvacancy", relativePath);
        if (postvacancyRes.success) {
          details = postvacancyRes.record;
        }
      }
    } catch (error) {}
    // setLoading(false);
    setProfile(details);
  };

  const initializeTableData = async (data) => {
    try {
      setLoading(true);
      let postvacancyRes = await CommonApiServices.initializeTableData(data);
      if (postvacancyRes.success) {
        getStarted();
      }
    } catch (error) {}
    setLoading(false);
  };

  // const deleteItem = async (id) => {
  //   setLoading(true);
  //   try {
  //     let relativePath = "/api/" +data?.table+"/edit/" +id;
  //     let postvacancyRes = await getDocuments("postvacancy", relativePath);
  //     if (postvacancyRes.success) {
  //       // updateContext("profiles");
  //       // setMode("");
  //     }
  //   } catch (error) {
  //   }
  //   setLoading(false);
  // };

  // };

  const getDocuments = async (documentName, relativePath) => {
    let responseD = { succesft: false, message: "", record: null };
    let documentsResponse = { succesft: false };

    documentsResponse = await HttpService.getService(relativePath, {}, "");
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };

  function ProfileBox(dataD) {
    let data = dataD?.data || {};

    // age
    // birthDate
    // coverImageUrl
    // createdAt
    // description
    // educationalDocumentAttachementUrl
    // email
    // emergencyContactName
    // emergencyContactPhone
    // firstName
    // healthCondition
    // healthConditionDetail
    // id
    // maritalStatus
    // phone
    // profileImageUrl
    // sex
    // statusId
    // title
    // updatedAt
    // userName

    return (
      <Card id="2fa" sx={{ overflow: "visible" }}>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5">Profile Info</MDTypography>
          </MDBox>
          {/* 
          <MDBox px={3}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{
                xft: "flex-start",
                sm: "center",
              }}
              flexDirection={{ xft: "column", sm: "row" }}
            >
              <MDTypography variant="body2" color="text">
                {currentConversationWith.description}
              </MDTypography>
            </MDBox>
          </MDBox> */}
        </>
        <MDBox p={3}>
          <List
            dense
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >
            {/* <Divider sx={{ my: 1 }} /> */}
            <ListItemComp
              updateData={() => getData("profile", data.id)}
              data={{
                table: "profile",
                id: data.id,
                fn: "profileImageUrl",
                ft: "Profile Image",
                v: null,
                av: data?.profileImageUrl,
                actions: { edit: true },
                inputType: "singleimagepicker",
              }}
            />
            <ListItemComp
              updateData={() => getData("profile", data.id)}
              data={{
                table: "profile",
                id: data.id,
                fn: "firstName",
                ft: "First Name ",
                v: data.firstName,
                actions: { edit: true },
                inputType: "textfield",
              }}
            />
            <ListItemComp
              updateData={() => getData("profile", data.id)}
              data={{
                table: "profile",
                id: data.id,
                fn: "lastName",
                ft: "Last Name ",
                v: data.lastName,
                actions: { edit: true },
                inputType: "textfield",
              }}
            />
            <ListItemComp
              updateData={() => getData("profile", data.id)}
              data={{
                table: "profile",
                id: data.id,
                fn: "middleName",
                ft: "Middle Name ",
                v: data.middleName,
                actions: { edit: true },
                inputType: "textfield",
              }}
            />
            <ListItemComp
              updateData={() => getData("profile", data.id)}
              data={{
                table: "profile",
                id: data.id,
                fn: "birthDate",
                ft: "Birth Date ",
                v: data.birthDate,
                actions: { edit: true },
                inputType: "textfielddate",
              }}
            />
            <ListItemComp
              updateData={() => getData("profile", data.id)}
              data={{
                table: "profile",
                id: data.id,
                fn: "sex",
                ft: "Gender ",
                v: data.sex,
                actions: { edit: true },
                inputType: "textfield",
              }}
            />
            <ListItemComp
              updateData={() => getData("profile", data.id)}
              data={{
                table: "profile",
                id: data.id,
                fn: "maritalStatus",
                ft: "Marital Status ",
                v: data.maritalStatus,
                actions: { edit: true },
                inputType: "textfield",
              }}
            />
            <ListItemComp
              data={{
                ft: "Profile Created At",
                v: data.createdAt,
              }}
            />
            {/* <ListItemComp
              updateData={() => getData("profile", data.id)}
              data={{
                table: "profile",
                id: data.id,
                fn: "isEmployee",
                ft: "is Employee ",
                v: data.isEmployee,
                actions: { edit: true },
                inputType: "boolean",
              }}
            /> */}
          </List>
        </MDBox>
      </Card>
    );
  }

  function AddressBox(dataD) {
    let data = dataD?.data || {};
    return (
      <Card id="2fa" sx={{ overflow: "visible" }}>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5">Address </MDTypography>

            {!data && (
              <MDBadge
                component={Button}
                variant="contained"
                disable={loading}
                color="success"
                onClick={() => {
                  initializeTableData({
                    data: { ProfileId: profile?.id },
                    tableName: "address",
                  });
                }}
                badgeContent={"Intialize"}
                container
              />
            )}
          </MDBox>
        </>
        <MDBox p={3}>
          <List
            dense
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >
            <ListItemComp
              updateData={() => getData("profile", profile?.id)}
              data={{
                table: "address",
                id: data.id,
                actions: { edit: true },
                inputType: "textfield",
                fn: "country",
                ft: "Country ",
                v: data.country,
              }}
            />
            <ListItemComp
              updateData={() => getData("profile", profile?.id)}
              data={{
                table: "address",
                id: data.id,
                actions: { edit: true },
                inputType: "textfield",
                fn: "region",
                ft: "Region ",
                v: data.region,
              }}
            />
            <ListItemComp
              updateData={() => getData("profile", profile?.id)}
              data={{
                table: "address",
                id: data.id,
                actions: { edit: true },
                inputType: "textfield",
                fn: "zone",
                ft: "Zone ",
                v: data.zone,
              }}
            />
            <ListItemComp
              updateData={() => getData("profile", profile?.id)}
              data={{
                table: "address",
                id: data.id,
                actions: { edit: true },
                inputType: "textfield",
                fn: "woreda",
                ft: "Woreda ",
                v: data.woreda,
              }}
            />
            <ListItemComp
              updateData={() => getData("profile", profile?.id)}
              data={{
                table: "address",
                id: data.id,
                actions: { edit: true },
                inputType: "textfield",
                fn: "city",
                ft: "City ",
                v: data.city,
              }}
            />
            <ListItemComp
              updateData={() => getData("profile", profile?.id)}
              data={{
                table: "address",
                id: data.id,
                actions: { edit: true },
                inputType: "textfield",
                fn: "subCity",
                ft: "SubCity ",
                v: data.subCity,
              }}
            />
            <ListItemComp
              updateData={() => getData("profile", profile?.id)}
              data={{
                table: "address",
                id: data.id,
                actions: { edit: true },
                inputType: "textfield",
                fn: "specificArea",
                ft: "Specific Area ",
                v: data.specificArea,
                a: null,
              }}
            />
          </List>
        </MDBox>
      </Card>
    );
  }

  function FamilyInformation(dataD) {
    let data = dataD?.data || {};
    return (
      <Card sx={{ overflow: "visible" }}>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5">Family Information </MDTypography>

            {!data.id && (
              <MDBadge
                component={Button}
                variant="contained"
                disable={loading}
                color="success"
                onClick={() => {
                  initializeTableData({
                    data: { ProfileId: profile.id },
                    tableName: "familyinformation",
                  });
                }}
                badgeContent={"Intialize"}
                container
              />
            )}
          </MDBox>
        </>
        {data?.id && (
          <Grid container spacing={2}>
            <Grid item md={6}>
              <MDBox p={2}>
                <List
                  dense
                  sx={{
                    width: "100%",
                    // maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <ListItemComp
                    updateData={() => getData("profile", profile?.id)}
                    data={{
                      table: "FamilyInformation",
                      id: data?.id,
                      actions: { edit: true },
                      inputType: "textfield",
                      fn: "fatherName",
                      ft: "Father Full Name",
                      v: data?.fatherName,
                    }}
                  />

                  <ListItemComp
                    updateData={() => getData("profile", profile?.id)}
                    data={{
                      table: "FamilyInformation",
                      id: data?.id,
                      actions: { edit: true },
                      inputType: "textfield",
                      fn: "motherName",
                      ft: "Mother Full Name",
                      v: data?.motherName,
                    }}
                  />
                  <ListItemComp
                    updateData={() => getData("profile", profile?.id)}
                    data={{
                      table: "profile",
                      id: profile?.id,
                      actions: { edit: true },
                      inputType: "textfield",
                      fn: "emergencyContactName",
                      ft: "Emergency Contact Name",
                      v: profile?.emergencyContactName,
                    }}
                  />
                  <ListItemComp
                    updateData={() => getData("profile", profile?.id)}
                    data={{
                      table: "profile",
                      id: profile?.id,
                      actions: { edit: true },
                      inputType: "textfield",
                      fn: "emergencyContactPhone",
                      ft: "Emergency Contact Phone",
                      v: profile?.emergencyContactPhone,
                    }}
                  />

                  <Divider orientation="vertical" flexItem />
                </List>
              </MDBox>
            </Grid>
            <Grid item md={6}>
              <MDBox p={2}>
                <List
                  dense
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        px={3}
                      >
                        <MDTypography variant="h6">Family Address</MDTypography>

                        {!data.Address && (
                          <MDBadge
                            component={Button}
                            variant="contained"
                            disable={loading}
                            color="success"
                            onClick={() => {
                              initializeTableData({
                                data: { FamilyInformationId: data?.id },
                                tableName: "address",
                              });
                            }}
                            badgeContent={"Intialize"}
                            container
                          />
                        )}
                      </MDBox>
                    </ListSubheader>
                  }
                  sx={{
                    width: "100%",
                    // maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  {data?.Address && (
                    <>
                      <ListItemComp
                        updateData={() => getData("profile", profile?.id)}
                        data={{
                          table: "address",
                          id: data?.Address?.id,
                          actions: { edit: true },
                          inputType: "textfield",
                          fn: "country",
                          ft: "Country ",
                          v: data?.Address?.country,
                        }}
                      />
                      <ListItemComp
                        updateData={() => getData("profile", profile?.id)}
                        data={{
                          table: "address",
                          id: data?.Address?.id,
                          actions: { edit: true },
                          inputType: "textfield",
                          fn: "region",
                          ft: "Region ",
                          v: data?.Address?.region,
                        }}
                      />
                      <ListItemComp
                        updateData={() => getData("profile", profile?.id)}
                        data={{
                          table: "address",
                          id: data?.Address?.id,
                          actions: { edit: true },
                          inputType: "textfield",
                          fn: "zone",
                          ft: "Zone ",
                          v: data?.Address?.zone,
                        }}
                      />
                      <ListItemComp
                        updateData={() => getData("profile", profile?.id)}
                        data={{
                          table: "address",
                          id: data?.Address?.id,
                          actions: { edit: true },
                          inputType: "textfield",
                          fn: "woreda",
                          ft: "Woreda ",
                          v: data?.Address?.woreda,
                        }}
                      />
                      <ListItemComp
                        updateData={() => getData("profile", profile?.id)}
                        data={{
                          table: "address",
                          id: data?.Address?.id,
                          actions: { edit: true },
                          inputType: "textfield",
                          fn: "city",
                          ft: "City ",
                          v: data?.Address?.city,
                        }}
                      />
                      <ListItemComp
                        updateData={() => getData("profile", profile?.id)}
                        data={{
                          table: "address",
                          id: data?.Address?.id,
                          actions: { edit: true },
                          inputType: "textfield",
                          fn: "subCity",
                          ft: "SubCity ",
                          v: data?.Address?.subCity,
                        }}
                      />
                      <ListItemComp
                        updateData={() => getData("profile", profile?.id)}
                        data={{
                          table: "address",
                          id: data?.Address?.id,
                          actions: { edit: true },
                          inputType: "textfield",
                          fn: "specificArea",
                          ft: "Specific Area ",
                          v: data?.Address?.specificArea,
                          a: null,
                        }}
                      />
                    </>
                  )}

                  <Divider orientation="vertical" flexItem />
                </List>
              </MDBox>
            </Grid>
          </Grid>
        )}
      </Card>
    );
  }

  function InstructorBox(dataD) {
    let data = dataD?.data || null;
    return (
      <Card sx={{ overflow: "visible" }}>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5">Instructor Account </MDTypography>
            {!data && (
              <MDBadge
                component={Button}
                variant="contained"
                disable={loading}
                color="success"
                onClick={() => {
                  initializeTableData({
                    data: { ProfileId: profile?.id },
                    tableName: "instructor",
                  });
                }}
                badgeContent={"Intialize"}
                container
              />
            )}
          </MDBox>
        </>
        {data && (
          <MDBox p={2}>
            <List
              dense
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                  >
                    <MDTypography variant="h6">Account </MDTypography>
                    <MDBadge
                      component={Link}
                      to={"/Academic/Instructors/" + data.id}
                      variant="contained"
                      color="success"
                      badgeContent={"Manage"}
                      container
                    />
                  </MDBox>
                </ListSubheader>
              }
              sx={{
                width: "100%",
                // maxWidth: 360,
                bgcolor: "background.paper",
              }}
            >
              <ListItemComp
                data={{
                  ft: "Acadamic Year ",
                  v: data?.acadamicYear + " year",
                  a: null,
                }}
              />
              <ListItemComp
                data={{
                  ft: "Department ",
                  v:
                    data?.Department?.title +
                    " || " +
                    data?.Department?.major +
                    " || " +
                    data?.Department?.minor,
                  a: null,
                }}
              />
              <ListItemComp
                data={{
                  ft: "Program ",
                  v: data?.Program?.title,
                  a: null,
                }}
              />
              <ListItemComp
                data={{
                  ft: "Registered date",
                  v: data?.createdAt,
                  a: null,
                }}
              />

              <Divider orientation="vertical" flexItem />
            </List>
          </MDBox>
        )}
      </Card>
    );
  }

  function StudentsBox(dataD) {
    
  const [studentTab, setStudentTab] = useState("account");
    let data = dataD?.data || [];
    let studentAccountRequests = dataD?.studentAccountRequests || [];
    return (
      <Card sx={{ overflow: "visible" }}>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5">Student Accounts </MDTypography>
           
            <MDBadge
                          component={Link}
                          to={"/Academic/Profiles/" + profile?.id+"/Request-Student-Account"}
                          variant="contained"
                          color="success"
                          badgeContent={"Request New Account"}
                          container
                        />
          </MDBox>
        </>
        
        <MDBox
            display="flex"
            // justifyContent="space-between"
            alignItems="center"   
            justifyContent="center"  >
          <Tabs value={studentTab} onChange={(e,v)=>setStudentTab(v)} aria-label="disabled tabs example">
            <Tab label="Accounts" value={"account"} />
            <Tab label="Requests" value={"request"}  /> 
          </Tabs>
         </MDBox>
        {studentTab=="request"&&<Grid container spacing={2}>
            <Grid item xs={12}>
              <MDBox p={2}>
                <List
                  dense 
                  sx={{
                    width: "100%",
                    // maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                > 
          {studentAccountRequests?.map((request, i) => (
                <>
                <Divider/>
                  <ListItem sx={{p:1}}
                            // secondaryAction={
                            //   <IconButton component={Link} 
                            //   to={"/Academic/Students/" + request?.id} edge="end" aria-label="comments">
                            //     <ViewAgenda />
                            //   </IconButton>
                            // }
                            disablePadding
                          > 
                          <ListItemAvatar> 
                              <Avatar>
                                <ListAlt />
                              </Avatar> 
                          </ListItemAvatar>  
                              <ListItemText   
                                primary={CommonServices?.ordinal_suffix_of(request?.ProgramToDepartmentYear?.acadamicYear) + " year || "+
                                request?.ProgramToDepartmentYear?.ProgramToDepartment?.Department?.title+ " || "+
                                request?.ProgramToDepartmentYear?.ProgramToDepartment?.Program?.title+ " || "+
                                request?.ProgramToDepartmentYear?.ProgramToDepartment?.EducationClassification?.title
                                  }

                                  secondary={"Request : "+request?.status||"" } /> 
                   </ListItem> 
                </>
          ))}
                </List>
              </MDBox>
            </Grid>
        </Grid>} 

        {studentTab=="account"&&<Grid container spacing={2}>
       
            <Grid item  xs={12}>
              <MDBox p={2}>
                <List
                  dense 
                  sx={{
                    width: "100%",
                    // maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                > 
              {data?.map((student, i) => (<>
                <Divider/>
                  <ListItem sx={{p:1}} component={Link} 
                              to={"/Academic/Students/" + student.id}
                            // secondaryAction={
                            //   <IconButton edge="end" aria-label="comments">
                            //     <ViewAgenda />
                            //   </IconButton>
                            // }
                            disablePadding
                          > 
                          
                <ListItemButton role={undefined}   dense>
                          <ListItemAvatar> 
                              <Avatar>
                                <ListAlt />
                              </Avatar> 
                          </ListItemAvatar>  
                              <ListItemText   
                                primary={CommonServices?.ordinal_suffix_of(student?.ProgramToDepartmentYear?.acadamicYear) + " year || "+
                                    student?.ProgramToDepartment?.Department?.title+ " || "+
                                    student?.ProgramToDepartment?.Program?.title+ " || "+
                                    student?.ProgramToDepartment?.EducationClassification?.title
                                  }
                                  secondary={"Student "} />
                             </ListItemButton>
                      </ListItem> </>
                ))}
                </List>
              </MDBox>
            </Grid>
        </Grid>} 
      </Card>
    );
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar2 />
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              {!firstLoading ? (
                <MDBox mb={3}>
                  {profile ? (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Card id="profile">
                          <MDBox p={2}>
                            <Grid container spacing={3} alignItems="center">
                              {/* <Grid item>
                              <MDAvatar
                                src={currentConversationWith.image}
                                alt="profile-image"
                                size="xl"
                                shadow="sm"
                              />
                            </Grid> */}
                              <Grid item>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                  <MDTypography
                                    variant="h5"
                                    fontWeight="medium"
                                  >
                                    {"Profile Details"}
                                  </MDTypography>
                                  {/* <MDTypography
                                  variant="button"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  {"Edit"}
                                </MDTypography>  */}
                                </MDBox>
                              </Grid>
                              {/* <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )}

                                <>
                                  <Button
                                    variant="button"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => {
                                      if(addUserStep!=1){
                                        handleSubmit();
                                      }
                                      else{
                                        handleCreateUser()
                                      }
                                    }}
                                  >
                                    {"Sumbit"}
                                  </Button>
                                  <Button
                                    variant="button"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => {
                                      setMode("");
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </> 
                              </MDBox>
                            </Grid> */}
                            </Grid>
                          </MDBox>
                        </Card>
                      </Grid>
                      <Grid item md={6}>
                        <ProfileBox data={profile} />
                      </Grid>
                      <Grid item md={6}>
                        <AddressBox data={profile?.Address} />
                      </Grid>
                      <Grid item md={12}>
                        <StudentsBox data={profile?.Students} studentAccountRequests={profile?.StudentAccountRequests} />
                      </Grid>
                      <Grid item md={12}>
                        <InstructorBox data={profile?.Instructor} />
                      </Grid>
                      <Grid item md={12}>
                        <FamilyInformation data={profile?.FamilyInformation} />
                      </Grid>
                      {forceRerender}
                    </Grid>
                  ) : (
                    <Grid container spacing={3}></Grid>
                  )}

                  {/* {response&& <Grid item xs={12}>
                      <Card  id="2fa" sx={{mt:2, overflow: "visible" }}>
                       {responseMessageBox()}
                      </Card>{" "}
                    </Grid>} */}
                </MDBox>
              ) : (
                <MDBox m={3}>
                  <Grid container justifyContent={"center"} spacing={2}>
                    <Grid item xs={12}>
                      <MDBox sx={{ width: "100%" }}>
                        <Skeleton height={58} animation="wave" />
                      </MDBox>
                    </Grid>
                    <Grid item md={6}>
                      <MDBox sx={{ width: "100%" }}>
                        {/* <Skeleton height={58} /> */}
                        <Skeleton
                          height={558}
                          animation="wave"
                          variant="rectangular"
                        />
                      </MDBox>
                    </Grid>
                    <Grid item md={6}>
                      <MDBox sx={{ width: "100%" }}>
                        {/* <Skeleton height={58} /> */}
                        <Skeleton
                          height={558}
                          animation="wave"
                          variant="rectangular"
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox sx={{ width: "100%" }}>
                        <Skeleton
                          height={558}
                          animation="wave"
                          variant="rectangular"
                        />
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
      {forceRerender}
    </>
  );
}

export default ProfileDetails;
