/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
sectionNumberd by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Settings page components
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import MDBadge from "components/MDBadge";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { Add, ArrowBackIosNewSharp, Book, ListAlt, Merge, Person, Splitscreen } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import DashboardNavbar2 from "examples/Navbars/DashboardNavbar2";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HttpService } from "Api-Services/httpService";
import ListItemComp from "components/common-components/listItemComp";
import { CommonServices } from "Api-Services/commonServices";
import MDButton from "components/MDButton";
function CatalogCourses(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [controller] = useMaterialUIController();
  const [loading, setLoading] = useState(false);
  const [forceRerender, setForceRerender] = useState(true);
  const [openedDialog, setOpenedDialog ] = useState(false);
  const [mode, setMode] = useState("");
  const [itemFormData, setItemFormData] = useState({});
  const [itemFilterData, setItemFilterData] = useState({});
  const [filteredcatalogCourses, setFilteredcatalogCourses] = useState([]);
  const [catalogCourses, setCatalogCourses] = useState([]);
  const [catalog, setCatalog] = useState({});
  const [studentsTab,setStudentsTab]=useState("")
  const [sectionTab, setSectionTab] = useState("courses");
  const [currentContextData, updateContext] = useCurrentUser();
  const { catalogs, courses, departments, programs } = currentContextData;
  const [currentConversationWith, setCurrentConversationWith] = useState(null);
  const [studentStatuses, setStudentStatuses] = useState([]);
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openedMenu=Boolean(anchorEl)

  React.useEffect(() => {
    (async () => {
      getStarted()
      // console.log("catalogCourses", catalogCourses);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      setForceRerender(!forceRerender);
    })();
  }, [catalogCourses, departments, programs, courses]);
  
  const getStarted = async () => {
    try {
      setLoading(true);
      if (params) {
        if (params.id) {
          getData("programtodepartmentyear", params.id);
        }
      }
      let relativePath = "/api/student/studentstatus/all";
      let postvacancyRes = await getDocuments("postvacancy", relativePath);
      if (postvacancyRes.success) {
          if (postvacancyRes?.record?.length) {
            setStudentsTab(postvacancyRes?.record[0]?.title)
            setStudentStatuses(postvacancyRes?.record); 
        }
      }
    } catch (error) { 
    }
      setLoading(false);
    setForceRerender(!forceRerender);
  };

  const initializeForm = (currentItemData) => {
    let data = currentItemData || {};
    try {
      setItemFormData({});
      if (currentItemData) {
        setItemFormData(data);
      }
      setForceRerender(!forceRerender);
    } catch (error) {}
  };

  const deleteItem = async (id) => {
    try {
      let relativePath = "/api/catalogcourse/delete/" + id;
      setLoading(true);
      let postvacancyRes = await getDocuments("postvacancy", relativePath);
      setLoading(false);
      if (postvacancyRes.success) {
        // getCatalogCourses(1);
        setMode("");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getData = async (tableName, id) => {
    let details = {};
    try {
      if (tableName && id) {
        let relativePath = "/api/" + tableName + "/find/" + id;
        // setLoading(true);
        let postvacancyRes = await getDocuments("postvacancy", relativePath);
        if (postvacancyRes.success) {
          details = postvacancyRes.record;
        }
      }
    } catch (error) {}
    // setLoading(false);
    if (tableName == "programtodepartmentyear") {
      setCatalog(details);
    }
    setForceRerender(!forceRerender);
  };

  const handleSubmit = async () => {
    try {
      let data = itemFormData;
      let relativePath = "/api/catalogcourse/initialize";
      if (mode == "edit") {
        if (currentConversationWith) {
          relativePath =
            "/api/catalogcourse/edit/" + currentConversationWith.id;
        } else return;
      } else if (catalog.id) {
        if (data.creditHour && data.acadamicSemister && data.CourseId) {
          data.ProgramToDepartmentYearId = catalog.id;
        } else {
          return;
        }
      } else {
        return;
      }
      setLoading(true);
      let postvacancyRes = await postDocuments(
        "postvacancy",
        relativePath,
        data
      );
      setLoading(false);
      if (postvacancyRes.success) {
        setOpenedDialog("")
        getData("programtodepartmentyear", catalog?.id);
        setMode(""); 
        if (mode == "add" && postvacancyRes.record) {
          setCurrentConversationWith(postvacancyRes.record);
        } else { 
          setMode("");
        }
      } 
    } catch (error) {
      setLoading(false);
    }
  };

  const getDocuments = async (documentName, relativePath) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    documentsResponse = await HttpService.getService(relativePath, {}, "");
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };
  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };

  const handleFormChange = (field, value) => {
    let itemFormDataD = itemFormData;
    itemFormDataD[field] = value;
    setItemFormData(itemFormDataD);
    setForceRerender(!forceRerender);
  };

  const handleFilterChange = (field, value) => {
    try {
      let itemFormDataD = itemFilterData;
      itemFormDataD[field] = value;
      handleFilterData(itemFormDataD);
    } catch (error) {}
  };

  const handleFilterData = (data) => {
    try {
      let itemFormDataD = data || {};
      let itemFormDataArray = Object.entries(itemFormDataD);
      let catalogCoursesD = catalogCourses;
      let catalogCoursesN = catalogCoursesD.filter((sec) => {
        let res = true;
        itemFormDataArray.forEach((filterElement) => {
          if (filterElement[1] != "Any") {
            if (sec[filterElement[0]] != filterElement[1]) {
              res = false;
            }
          }
        });
        //  console.log("sec",sec,field, value)
        return res;
      });
      // console.log("catalogCoursesN",catalogCoursesN)
      setFilteredcatalogCourses(catalogCoursesN);
      setItemFilterData(data);
      setMode("");
      setForceRerender(!forceRerender);
    } catch (error) {}
  };

  const onEditClick = (data) => {
    initializeForm(data);
    setMode("edit");
  };

  const onAddClick = () => {
    initializeForm();
    setMode("");
    setCurrentConversationWith(null); 
    setOpenedDialog("addcourse")
  };

  const onViewClick = (data) => {
    setCurrentConversationWith(data);
    setMode("view");
    setForceRerender(!forceRerender);
  };

  const editBox = () => {
    return (
      <MDBox p={3}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <MDBox p={2}>
              <InputLabel id="demo-simple-select-autowidth-label">
                Course
              </InputLabel>
              <Select
                sx={{ p: 1, mt: 1 }}
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={catagoryId}
                defaultValue={itemFormData.CourseId}
                // label="Age"
                // onChange={handleChange}
                onChange={(e) => handleFormChange("CourseId", e.target.value)}
              >
                {courses?.map((catagory) => (
                  <MenuItem value={catagory.id}>
                    {catagory.title + " || " + catagory.code}
                  </MenuItem>
                ))}
              </Select>
            </MDBox>
            <MDBox p={1}>
              <TextField
                // error
                fullWidth
                id="outlined-error"
                label="Credit Hour"
                type="number"
                onChange={(e) => handleFormChange("creditHour", e.target.value)}
                defaultValue={itemFormData.creditHour}
              />
            </MDBox>
            <MDBox p={2}>
              <InputLabel id="demo-simple-select-autowidth-label">
                Acadamic Semister
              </InputLabel>
              <Select
                sx={{ p: 1, mt: 1 }}
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={catagoryId}
                defaultValue={itemFormData.acadamicSemister}
                // label="Age"
                // onChange={handleChange}
                onChange={(e) =>
                  handleFormChange("acadamicSemister", e.target.value)
                }
              >
                {[...Array(3)]?.map((n, i) => (
                  <MenuItem value={i + 1}>
                    {CommonServices.ordinal_suffix_of(i + 1)} Semister
                  </MenuItem>
                ))}
              </Select>
            </MDBox>
            {/* <TextField
              multiline
              fullWidth
              rows={3}
              onChange={(e) => handleFormChange("description", e.target.value)}
              // error
              id="outlined-error-helper-text"
              label={"Description"}
              defaultValue={itemFormData.description}
              // helperText="Incorrect entry."
            /> */}
          </div>
          {forceRerender}
        </Box>
      </MDBox>
    );
  };

  const addCourseBox = () => {
    return (
      <MDBox p={3}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <MDBox p={2}>
              <InputLabel id="demo-simple-select-autowidth-label">
                Course
              </InputLabel>
              <Select
                sx={{ p: 1, mt: 1 }}
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={catagoryId}
                // defaultValue={itemFormData.CourseId}
                // label="Age"
                // onChange={handleChange}
                onChange={(e) => handleFormChange("CourseId", e.target.value)}
              >
                {courses?.map((catagory) => (
                  <MenuItem value={catagory.id}>
                    {catagory.title + " || " + catagory.code}
                  </MenuItem>
                ))}
              </Select>
            </MDBox>
            <MDBox p={1}>
              <TextField
                // error
                fullWidth
                id="outlined-error"
                label="Credit Hour"
                type="number"
                onChange={(e) => handleFormChange("creditHour", e.target.value)}
                // defaultValue={itemFormData.creditHour}
              />
            </MDBox>
            <MDBox p={2}>
              <InputLabel id="demo-simple-select-autowidth-label">
                Acadamic Semister
              </InputLabel>
              <Select
                sx={{ p: 1, mt: 1 }}
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={catagoryId}
                // defaultValue={itemFormData.acadamicSemister}
                // label="Age"
                // onChange={handleChange}
                onChange={(e) =>
                  handleFormChange("acadamicSemister", e.target.value)
                }
              >
                {[...Array(3)]?.map((n, i) => (
                  <MenuItem value={i + 1}>
                    {CommonServices.ordinal_suffix_of(i + 1)} Semister
                  </MenuItem>
                ))}
              </Select>
            </MDBox>
            {/* <TextField
              multiline
              fullWidth
              rows={3}
              onChange={(e) => handleFormChange("description", e.target.value)}
              // error
              id="outlined-error-helper-text"
              label={"Description"}
              defaultValue={itemFormData.description}
              // helperText="Incorrect entry."
            /> */}
          </div>
          {forceRerender}
        </Box>
      </MDBox>
    );
  };
 
  const addSectionBox = () => {
    return (
      <MDBox p={3}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
          }}
          noValidate
          autoComplete="off"
        >
          <MDBox px={1.5}  py={1}>
            <Typography>
                   Add New Section 
                   {/* {filteredSections.length?(filteredSections[filteredSections.length-1]?.sectionNumber+1):1} */}
              </Typography>
            {/* <TextField
              // error
              disabled={true}
              fullWidth
              type="number"
              id="outlined-error"
              label="sectionNumber"
              onChange={(e) => handleFormChange("sectionNumber", e.target.value)}
              defaultValue={filteredSections.length?(filteredSections[filteredSections.length-1]?.sectionNumber+1):1}
            /> */}
            {/* <TextField
              multiline
              fullWidth
              rows={3}
              onChange={(e) => handleFormChange("description", e.target.value)}
              // error
              id="outlined-error-helper-text"
              label={"Description"}
              defaultValue={itemFormData.description}
              // helperText="Incorrect entry."
            /> */}
          </MDBox>  
            {forceRerender}
        </Box>
      </MDBox>
    );
  };

  const studentsListBox=(dataD)=> {
    let data = dataD|| [];
    return (
      <>
        
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={1}
        >
          <MDTypography variant="h5">{data?.length} Student(s) </MDTypography> 
          {/* <Typography>
            Section number: {filteredSections.length?(filteredSections[filteredSections.length-1]?.sectionNumber+1):1}
              </Typography> */}
        </MDBox>
        <MDBox>
          <Grid container>
            <MDBox sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Tabs value={studentsTab} onChange={(e,v)=>setStudentsTab(v)} centered>
                 {studentStatuses?.map((studentStatus)=><Tab label={studentStatus.title} value={studentStatus.title}/>)  }
                </Tabs>
            </MDBox>
          </Grid>
        </MDBox> 
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {data?.map((message, key) => {
            const itemKey = `item-${key}`;

            return (
              <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
                <MDTypography
                  component="a"
                  // href={`#${href}`}
                  // onClick={() => {
                  //   onViewClick(message);
                  // }}
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                  sx={({
                    borders: { borderRadius },
                    functions: { pxToRem },
                    palette: { light },
                    transitions,
                  }) => ({
                    display: "flex",
                    alignItems: "center",
                    borderRadius: borderRadius.md,
                    padding: `${pxToRem(10)} ${pxToRem(16)}`,
                    transition: transitions.create("background-color", {
                      easing: transitions.easing.easeInOut,
                      duration: transitions.duration.shorter,
                    }),

                    "&:hover": {
                      backgroundColor: light.main,
                    },
                    backgroundColor: currentConversationWith
                      ? currentConversationWith.sectionNumber ==
                        message.sectionNumber
                        ? light.main
                        : "none"
                      : "none",
                  })}
                >
                  {/* <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}> */}
                  <MDBox mr={2}>
                    <Avatar alt="something here" src={message?.Profile?.profileImageUrl} /> 
                  </MDBox>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <MDBox
                      mr={2}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="center"
                    >
                      <MDTypography variant="button" fontWeight="medium">
                      {message?.Profile?.firstName+" "}
                      {message?.Profile?.lastName+" "}
                      {message?.Profile?.middleName+" "}
                      </MDTypography>
                      <MDTypography variant="caption" color="text">
                        {"Students Count: "  }
                      </MDTypography>
                    </MDBox>
                    <MDBox mr={2}>
                      <MDButton
                        
                        size={"small"}
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(e)=>{
                          setAnchorEl(e?.currentTarget);
                        }}
                      >
                          More
                      </MDButton>
                      
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openedMenu}
                        onClose={(e)=> setAnchorEl(null)}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem  component={Link}
                          to={"/Academic/Students/" + message?.id} >View Profile</MenuItem>
                        <MenuItem  >My account</MenuItem>
                        <MenuItem >Logout</MenuItem>
                      </Menu>
                    </MDBox>
                  </Grid>
                </MDTypography>
              </MDBox>
            );
          })}
        </MDBox>
      </>
    );
  }

  function SectionsListBox(dataD) {
    let data = dataD?.data || [];
    return (
      <>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={1}
        >
          <MDTypography variant="h5">{data?.length} Section(s) </MDTypography> 
          {/* <Typography>
            Section number: {filteredSections.length?(filteredSections[filteredSections.length-1]?.sectionNumber+1):1}
              </Typography> */}
        </MDBox>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {data?.map((message, key) => {
            const itemKey = `item-${key}`;

            return (
              <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
                <MDTypography
                  component="a"
                  // href={`#${href}`}
                  // onClick={() => {
                  //   onViewClick(message);
                  // }}
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                  sx={({
                    borders: { borderRadius },
                    functions: { pxToRem },
                    palette: { light },
                    transitions,
                  }) => ({
                    display: "flex",
                    alignItems: "center",
                    borderRadius: borderRadius.md,
                    padding: `${pxToRem(10)} ${pxToRem(16)}`,
                    transition: transitions.create("background-color", {
                      easing: transitions.easing.easeInOut,
                      duration: transitions.duration.shorter,
                    }),

                    "&:hover": {
                      backgroundColor: light.main,
                    },
                    backgroundColor: currentConversationWith
                      ? currentConversationWith.sectionNumber ==
                        message.sectionNumber
                        ? light.main
                        : "none"
                      : "none",
                  })}
                >
                  {/* <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}> */}
                  <MDBox mr={2}>
                    <Avatar alt="something here">
                      <Book />
                    </Avatar>
                  </MDBox>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <MDBox
                      mr={2}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="center"
                    >
                      <MDTypography variant="button" fontWeight="medium">
                        Section {message.sectionNumber}
                      </MDTypography>
                      <MDTypography variant="caption" color="text">
                        {"Students Count: " + message.StudentsCount}
                      </MDTypography>
                    </MDBox>
                    <MDBox mr={2}>
                      <MDButton
                        component={Link}
                        to={"/Academic/Sections/" + message?.id}
                        size={"small"}
                      >
                        View More
                      </MDButton>
                    </MDBox>
                  </Grid>
                </MDTypography>
              </MDBox>
            );
          })}
        </MDBox>
      </>
    );
  }

  function DetailBox() {
    return (
      <>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
          >
            <MDBadge
              variant="contained"
              color="success"
              badgeContent={currentConversationWith.createdAt}
              container
            />
          </MDBox>
        </>
        <MDBox p={3}>
          <List
            sx={{
              width: "100%",
              // maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >
            {/* <Divider sx={{ my: 1 }} /> */}
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ListAlt />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={currentConversationWith?.Course?.title}
                secondary="Course title"
              />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ListAlt />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={currentConversationWith?.Course?.code}
                secondary="Course code"
              />
            </ListItem>

            <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ListAlt />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={currentConversationWith?.creditHour + " hour(s)"}
                secondary="Credit Hour"
              />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ListAlt />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={currentConversationWith?.preRequest}
                secondary="Pre-request catalog"
              />
            </ListItem>
          </List>
        </MDBox>
      </>
    );
  }

  function CatalogDetailsBox(dataD) {
    let data = dataD?.data || {};
    return (
      <Card sx={{ overflow: "visible" }}>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5">Sections Group Details</MDTypography>
            <MDBadge
              disabled={data?.finishedAssigningCourses}
              component={Button}
              // to={"/Organizational-Unit/Sections/" + data.id}
              variant="contained"
              onClick={()=>{setOpenedDialog("sectiongroupmenu")}}
              color="success"
              badgeContent={"Menu"}
              container
            />
          </MDBox>
        </>
        <MDBox p={2}>
          <List
            dense
            sx={{
              width: "100%",
              // maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >
            {/* <ListItemComp
              data={{
                ft: "Section Number ",
                v: "Section " + data?.sectionNumber,
                a: null,
              }}
            /> */}

            <Grid container spacing={3}>
              <Grid item md={12}> 
                <ListItemComp
                  data={{
                    ft: "Department ",
                    v:
                      data?.ProgramToDepartment?.Department?.title +
                      " || " +
                      data?.ProgramToDepartment?.Department?.major +
                      " || " +
                      data?.ProgramToDepartment?.Department?.minor,
                    a: null,
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <ListItemComp
                  data={{
                    ft: "Program ",
                    v: data?.ProgramToDepartment?.Program?.title,
                    a: null,
                  }}
                />
                <ListItemComp
                  data={{
                    ft: "Education Classification ",
                    v: data?.ProgramToDepartment?.EducationClassification?.title,
                    a: null,
                  }}
                />
                </Grid>
                <Grid item md={4}> 
              
                <ListItemComp
                  data={{
                    ft: "Acadamic Year ",
                    v:
                      CommonServices.ordinal_suffix_of(data?.acadamicYear) +
                      " year",
                    a: null,
                  }}
                />
                <ListItemComp
                  data={{
                    ft: "All Students",
                    v:
                       data?.StudentsCount +
                      " students",
                    a: null,
                  }}
                /></Grid>
              <Grid item md={4}>
                <ListItemComp
                  data={{
                    ft: "Courses",
                    v: data?.CatalogCoursesCount + " course(s) ",
                    a: null,
                  }}
                />
                <ListItemComp
                  data={{
                    ft: "Joined Sections ",
                    v: data?.SectionsCount + " section(s)",
                    a: null,
                  }}
                />
                {/* <ListItemComp
              data={{
                ft: "Registered date",
                v: data?.createdAt,
                a: null,
              }}
            />    */}
              </Grid>
            </Grid>
          </List>
        </MDBox>
      </Card>
    );
  }

  const renderSidenavItems = catalog?.CatalogCourses?.map((message, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component="a"
          // href={`#${href}`}
          onClick={() => {
            onViewClick(message);
          }}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
            backgroundColor: currentConversationWith
              ? currentConversationWith.id == message.id
                ? light.main
                : "none"
              : "none",
          })}
        >
          {/* <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}> */}
          <MDBox mr={2}>
            <Avatar alt="something here">
              <Book />
            </Avatar>
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <MDTypography variant="button" fontWeight="medium">
              {message?.Course?.title} {" || "}
              {message?.Course?.code}
            </MDTypography>
            <MDTypography variant="caption" color="text">
              {" Credit Hour: " + message?.creditHour}
            </MDTypography>
          </MDBox>
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar2 />
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox mb={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card id="profile">
                      <CatalogDetailsBox data={catalog} />
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>


            
            <Grid item xs={12}>
              <MDBox>
                <Grid container>
                  <MDBox sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      <Tabs value={sectionTab} onChange={(e,v)=>setSectionTab(v)} centered>
                        <Tab label="Courses" value="courses"/>
                        <Tab label="Sections" value="sections"/>
                        <Tab label="Students" value="students"/>
                      </Tabs>
                  </MDBox>
                </Grid>
              </MDBox>
            </Grid>
   
          {sectionTab=="sections"&&<>
            <Grid item xs={12}>
              <MDBox mb={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card id="ections">
                      <SectionsListBox data={catalog?.Sections} />
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
            </>}
            
          {sectionTab=="students"&&<>
            <Grid item xs={12}>
              <MDBox mb={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card id="ections">
                       {studentsListBox(catalog?.Students)} 
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
            </>}
          {sectionTab=="courses"&&<>
            <Grid item xs={12}>
              {/* <MDBox > */}
              {/* <Grid container > */}
              <Grid item xs={12}>
                <Card id="profile">
                  <MDBox p={1}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item>
                        <MDBox height="100%" mt={0.5} lineHeight={1}>
                          <MDTypography variant="h5" fontWeight="medium">
                            {catalog?.CatalogCourses?.length + " Course(s)"}
                          </MDTypography>
                        </MDBox>
                      </Grid> 
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>
              {/* </Grid> */}
              {/* </MDBox> */}
            </Grid>

            {!(currentConversationWith && isMobile) && (
              <Grid item xs={12} lg={mode == "" ? 12 : 5}>
                <Card
                  sx={{
                    // maxHeight: isMobile ? "700px" : "420px",
                    overflowY: "auto",
                    borderRadius: ({ borders: { borderRadius } }) =>
                      borderRadius.lg,
                    position: "sticky",
                    top: "1%",
                  }}
                >
                  <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                  >
                    {renderSidenavItems}
                  </MDBox>
                </Card>
              </Grid>
            )}

            {mode != "" && currentConversationWith && (
              <Grid item xs={12} lg={7}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="profile">
                        <MDBox p={2}>
                          <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <ArrowBackIosNewSharp
                                  onClick={() => {
                                    setCurrentConversationWith(null);
                                    setMode("");
                                  }}
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />
                              </Grid>
                            }
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {currentConversationWith?.Course?.title}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )}

                                {mode != "edit" ? (
                                  <>
                                    {catalog?.finishedAssigningCourses ? null : (
                                      <>
                                        <Button
                                          variant="button"
                                          color="text"
                                          fontWeight="medium"
                                          onClick={() => {
                                            onEditClick(
                                              currentConversationWith
                                            );
                                          }}
                                        >
                                          {"Edit"}
                                        </Button>
                                        <Button
                                          variant="button"
                                          color="text"
                                          fontWeight="medium"
                                          onClick={() => {
                                            deleteItem(
                                              currentConversationWith.id
                                            );
                                          }}
                                        >
                                          {"Delete"}
                                        </Button>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      variant="button"
                                      color="text"
                                      fontWeight="medium"
                                      onClick={() => {
                                        handleSubmit();
                                      }}
                                    >
                                      {"Save"}
                                    </Button>
                                    <Button
                                      variant="button"
                                      color="text"
                                      fontWeight="medium"
                                      onClick={() => {
                                        setMode("");
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                )}
                                {/* <MDBox ml={1}>
                            <Switch checked={visible} onChange={handleSetVisible} />
                          </MDBox> */}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card id="2fa" sx={{ overflow: "visible" }}>
                        {mode != "edit" ? (
                          <>
                            <DetailBox />
                          </>
                        ) : (
                          <>{editBox()}</>
                        )}
                      </Card>{" "}
                    </Grid>
                    {forceRerender}
                  </Grid>
                </MDBox>
              </Grid>
            )}
 </>}
          </Grid>
        </MDBox>
      </DashboardLayout>
      {forceRerender}

      <Dialog
       onClose={()=>{setOpenedDialog("")}} 
      //  maxWidth={"lg"}
       open={openedDialog=="sectiongroupmenu"}>
        <DialogTitle>Menu</DialogTitle>
        <List sx={{ pt: 0 }}  subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Course
        </ListSubheader>
      }> 
                        
          <ListItem disableGutters>
            <ListItemButton disabled={catalog?.finishedAssigningCourses}
              autoFocus
              onClick={() =>{ 
                onAddClick()}}
            >
              <ListItemAvatar>
                <Avatar>
                  <Add />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add Course" />
            </ListItemButton>
          </ListItem>
          <Divider /> 
        </List>
        <List sx={{ pt: 0 }}   subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Section
        </ListSubheader>
      }>  
          <ListItem disableGutters>
            <ListItemButton
              autoFocus
              component={Link}
              
              to={"/Academic/SectionGroups/"+catalog?.id+"/Add-Section"}
            >
              <ListItemAvatar>
                <Avatar>
                  <Add />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add Section" />
            </ListItemButton>
          </ListItem>
          <Divider sx={{my:1}}/>
          <ListItem disableGutters>
            <ListItemButton
              autoFocus
              component={Link}
              
              to={"/Academic/SectionGroups/"+catalog?.id+"/Merge-Sections"}
              // onClick={() => setOpenedDialog("addsection")}
            >
              <ListItemAvatar>
                <Avatar>
                  <Merge />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Merge Sections" />
            </ListItemButton>
          </ListItem>
          <Divider sx={{my:1}}/>
          <ListItem disableGutters>
            <ListItemButton
              autoFocus
              // onClick={() => setOpenedDialog("addsection")}
              component={Link}
              
              to={"/Academic/SectionGroups/"+catalog?.id+"/Split-Section"}
            >
              <ListItemAvatar>
                <Avatar>
                  <Splitscreen />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Split Section" />
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
      <Dialog 
       sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
       onClose={()=>{setOpenedDialog("")}} 
       open={openedDialog=="addcourse"}>
        <DialogTitle> 
        
        <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <ArrowBackIosNewSharp
                                  onClick={() => { setOpenedDialog("sectiongroupmenu")}} 
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />
                              </Grid>
                            }
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {"Add New"}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )} 
                              </MDBox>
                            </Grid>
                          </Grid>
          </DialogTitle>

        {addCourseBox()}
        
        <DialogActions>
          
        <Button autoFocus
            fontWeight="medium"
            onClick={() => {
              handleSubmit();
            }}
          >
            {"Sumbit"}
          </Button>
          <Button 
            variant="button"
            color="text"
            onClose={()=>{setOpenedDialog(false)}}  >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
       sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
       onClose={()=>{setOpenedDialog("")}} 
       open={openedDialog=="addsection"}>
        <DialogTitle> 
        
        <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <ArrowBackIosNewSharp
                                  onClick={() => { setOpenedDialog("sectiongroupmenu")}} 
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />
                              </Grid>
                            }
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {"Add New Section"}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )} 
                              </MDBox>
                            </Grid>
                          </Grid>
          </DialogTitle>
                                  <DialogContent>
                                    
                                  <DialogContentText>
                                  {"Do you want to add one extra section in this group?"}</DialogContentText>
                                  </DialogContent>
        {/* {addSectionBox()}  */}
        
        <DialogActions>
          
        <Button autoFocus
            fontWeight="medium"
            onClick={() => {
              handleSubmit();
            }}
          >
            {"Yes"}
          </Button>
          <Button 
            variant="button"
            color="text"
            onClose={()=>{setOpenedDialog(false)}}  >
            No
          </Button>
        </DialogActions>
      </Dialog>
      {forceRerender}
    </>
  );
}

export default CatalogCourses;
