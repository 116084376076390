import { HttpService } from "../Api-Services/httpService";
import { createContext, useEffect, useState, useContext } from "react";
import { DataManagementService } from "../Api-Services/dataManagementService";
// import { SideBarRoutes } from 'sideBarRoutes';
import { UseAuth } from "Api-Services/useAuth";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const CurrentUserContext = createContext();
//now we have a context object

 function CurrentUserProvider(props) {
  //create the provider and its functionality
 
  const queryClient = useQueryClient()  
  const [myWebsites, setMyWebsites] = useState(null); 
  const [myCurrentProfileLoading, setMyCurrentProfileLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [forceRerender, setForceRerender] = useState(true);
  const [officeUiComponents, setOfficeUiComponents] = useState([]);
  

      let isUserLoggedIn = UseAuth.verifyUser();
      //////////////
      //  getDepartments();
      //  ("mydetails", "/api/department/all")
      const {data:myCurrentProfile} = useQuery({
        queryKey: ["/api/profile/myprofile"],
        queryFn: async () => { 
          setMyCurrentProfileLoading(true); 
          let res = {}; 
            if(isUserLoggedIn){ 
              res= await getDocuments( "/api/profile/myprofile");
              setMyCurrentProfileLoading(false);
            }   
          return res;
        },
      });

      const {data:currentUser} = useQuery({
        queryKey: ["/api/auth/mydetails"],
        queryFn: async () => {  
          let res = {}; 
            if(isUserLoggedIn){ 
              res= await getDocuments( "/api/auth/mydetails"); 
            }   
          return res;
        },
      });

      const {data:myBusinesses} = useQuery({
        queryKey: ["/api/profile/myprofiles"],
        queryFn: async () => {  
          let res = []; 
            if(isUserLoggedIn){ 
              res= await getDocuments( "/api/profile/myprofiles"); 
            }   
          return res;
        },
      });

      const {data:departments} = useQuery({
        queryKey: ["/api/department/all"],
        queryFn: async () => {
          let res = []; 
            if(isUserLoggedIn){ 
              res= await getDocuments( "/api/department/all");
            }   
          return res;
        },
      });

      const {data:programs} = useQuery({
        queryKey: ["/api/program/all"],
        queryFn: async () => {
          let res = []; 
            if(isUserLoggedIn){ 
              res= await getDocuments( "/api/program/all");
            }   
          return res;
        },
      });

      const {data:educationClassifications} = useQuery({
        queryKey: ["/api/educationclassification/all"],
        queryFn: async () => {
          let res = []; 
            if(isUserLoggedIn){ 
              res= await getDocuments( "/api/educationclassification/all");
            }   
          return res;
        },
      });

      const {data:sections} = useQuery({
        queryKey: ["/api/section/all"],
        queryFn: async () => {
          let res = []; 
            if(isUserLoggedIn){ 
              res= await getDocuments( "/api/section/all");
            }   
          return res;
        },
      });

      const {data:offices} = useQuery({
        queryKey: ["/api/office/all"],
        queryFn: async () => {
          let res = []; 
            if(isUserLoggedIn){ 
              res= await getDocuments( "/api/office/all");
            }   
          return res;
        },
      });
      

      const {data:courses} = useQuery({
        queryKey: ["/api/course/all"],
        queryFn: async () => {
          let res = []; 
            if(isUserLoggedIn){ 
              res= await getDocuments( "/api/course/all");
            }   
          return res;
        },
      });
      
      const {data:officeMemeberRoles} = useQuery({
        queryKey: ["/api/officememeberrole/all"],
        queryFn: async () => {
          let res = []; 
            if(isUserLoggedIn){ 
              res= await getDocuments( "/api/officememeberrole/all");
            }   
          return res;
        },
      });
      

      const {data:catalogs} = useQuery({
        queryKey: ["/api/catalog/all"],
        queryFn: async () => {
          let res = []; 
            if(isUserLoggedIn){ 
              res= await getDocuments( "/api/catalog/all");
            }   
          return res;
        },
      });

      const {data:programToDepartments} = useQuery({
        queryKey: ["/api/programtodepartment/all"],
        queryFn: async () => {
          let res = []; 
            if(isUserLoggedIn){ 
              res= await getDocuments( "/api/programtodepartment/all");
            }   
          return res;
        },
      });
      
      const {data:programToDepartmentYears} = useQuery({
        queryKey: ["/api/programtodepartmentyear/all"],
        queryFn: async () => {
          let res = []; 
            if(isUserLoggedIn){ 
              res= await getDocuments( "/api/programtodepartmentyear/all");
            }   
          return res;
        },
      });

      const {data:departmentCategories} = useQuery({
        queryKey: ["/api/departmentcategory/all"],
        queryFn: async () => {
          let res = []; 
            if(isUserLoggedIn){ 
              res= await getDocuments( "/api/departmentcategory/all");
            }   
          return res;
        },
      });

      const {data:educationLevels} = useQuery({
        queryKey: ["/api/educationlevel/all"],
        queryFn: async () => {
          let res = []; 
            if(isUserLoggedIn){ 
              res= await getDocuments( "/api/educationlevel/all");
            }   
          return res;
        },
      });
      
 
 
 
  const getDocumentsAll = async (
    documentName,
    relativePath,
    additionalData
  ) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;
      if (documentName == "allOffices") {
        await setOfficesUIelements(record);
        DataManagementService.setLocalstorageDataSecured(record, "allOffices");
      }
    }
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };


  const getDocuments = async ( 
    relativePath 
  ) => {
    let record = null;  
    let documentsResponse = await HttpService.getService(
      relativePath,  {},
      ""
    );
    if (documentsResponse?.success) { 
      if (documentsResponse?.data?.record) {
        record = documentsResponse?.data?.record; 
      } 
    } 
    return record; 
  };

  const postDocuments = async (
    documentName,
    relativePath,
    data,
    additionalData
  ) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;
    }
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const setOfficesUIelements = async (officesD) => {
    let allUis = [];
    if (officesD.officesRoles) {
      if (officesD.officesRoles.length > 0) {
        //  console.log("setOfficesUIelements",officesD.officesRoles)
        let officesRolesD = officesD.officesRoles;
        officesRolesD.forEach((officeRoleD) => {
          let officeUiComponentsD = officeRoleD.officeUiComponents;
          if (officeUiComponentsD.success) {
            var a = [1, 2, 3],
              b = [101, 2, 1, 10];
            if (allUis.length) {
              allUis = allUis.concat(officeUiComponentsD.record.details);
            } else {
              allUis = officeUiComponentsD.record.details;
            }
            //  console.log("concat UIelements",allUis)
          }
        });
        if (allUis.length) {
          setOfficeUiComponents(allUis);
        }
      }
    }
  };

  async function updateCurrentUser(variableName) {
    if (variableName) {
      if (variableName == "currentUser") { 
        queryClient.invalidateQueries({ queryKey: ["/api/auth/mydetails"] })
      }
      if (variableName == "websiteUsers") {
        queryClient.invalidateQueries({ queryKey: ["/api/websiteuser/all"] })
      }
      if (variableName == "offices") {
        queryClient.invalidateQueries({ queryKey: ["/api/office/all"] })
      }
      if (variableName == "departments") {
        queryClient.invalidateQueries({ queryKey: ["/api/department/all"] })
      }
      if (variableName == "programs") {
        queryClient.invalidateQueries({ queryKey: ["/api/program/all"] })
      }
      if (variableName == "educationClassifications") {
        queryClient.invalidateQueries({ queryKey: ["/api/educationclassification/all"] })
      }
      if (variableName == "sections") {
        queryClient.invalidateQueries({ queryKey: ["/api/section/all"] })
      }
      if (variableName == "catalogs") {
        queryClient.invalidateQueries({ queryKey: ["/api/catalog/all"] })
      }
      if (variableName == "officeMemeberRoles") {
        queryClient.invalidateQueries({ queryKey: ["/api/officememeberrole/all"] })
      }
      if (variableName == "programToDepartments") {
        queryClient.invalidateQueries({ queryKey: ["/api/programtodepartment/all"] })
      }
      if (variableName == "programToDepartmentyear") {
        queryClient.invalidateQueries({ queryKey: ["/api/programtodepartmentyear/all"] })
      }
      if (variableName == "courses") {
        queryClient.invalidateQueries({ queryKey: ["/api/course/all"] })
      }
      if (variableName == "departmentCategories") {
        queryClient.invalidateQueries({ queryKey: ["/api/educationlevel/all"] })
      }
      // if (variableName == "employmentAgencyJobSeekers") {
      //   getCurrentWebsiteEmploymentAgencyJobSeekers();
      // }
      // if (variableName == "myBusinessEmploymentAgency") {
      //   getMyBusinessEmploymentAgency();
      // }
      // if (variableName == "myBusinessEmploymentAgencies") {
      //   getMyBusinessEmploymentAgencies();
      // }
      // if (variableName == "paymentMethods") {
      //   getPaymentMethods();
      // }
      // if (variableName == "employeeHiringRequests") {
      //   getEmployeeHiringRequests();
      // }
      // if (variableName == "agencyContactMessages") {
      //   getAgencyContactMessages();
      // }
      // if (variableName == "agencyJobVacancies") {
      //   getAgencyJobVacancies();
      // }
      // if (variableName == "myJobVacancyPosts") {
      //   getMyJobVacancyPosts();
      // } 
      // if (variableName == "jobCategories") {
      //   getJobCategories();
      // }
      // if (variableName == "employmentAgencyPaymentAccounts") {
      //   getEmploymentAgencyPaymentAccounts();
      // }
      // if (variableName == "employmentAgencyPaymentConfirmationRequests") {
      //   getEmploymentAgencyPaymentConfirmationRequests();
      // }
      // if (variableName == "jobs") {
      //   getJobs();
      // }
      if (variableName == "educationLevels") {    
      queryClient.invalidateQueries({ queryKey: ["/api/educationlevel/all"] })
      }
      // if (variableName == "currentWebsite") {
      //   getCurrentWebsite();
      // }
      // if (variableName == "adminUIComponent") {
      //   // getAdminUIComponent()
      // }
      // if (variableName == "officesUIelements") {
      //   getOfficesUIelements();
      // } 
      if (variableName == "myBusinesses") { 
      queryClient.invalidateQueries({ queryKey: ["/api/profile/myprofiles"] })
      }
      if (variableName == "myCurrentProfile") {
      queryClient.invalidateQueries({ queryKey: ["/api/profile/myprofile"] })
      }     
    } else {
      getStarted();
    }
    // await getDocumentsAll("allOffices","/api/officeUserRole/myoffices",{})
    // await getDocumentsAll("currentloggedwebsite","/api/auth/currentloggedwebsite")
  }

  return (
    <CurrentUserContext.Provider
      value={[
        { 
          departments: departments,
          programs: programs,
          educationClassifications: educationClassifications,
          sections: sections,
          courses: courses,
          catalogs: catalogs,
          offices: offices,
          officeMemeberRoles: officeMemeberRoles,
          programToDepartments: programToDepartments,
          programToDepartmentYears: programToDepartmentYears,
          departmentCategories: departmentCategories, 
          userLoading: userLoading, 
          currentUser: currentUser, 
          educationLevels: educationLevels,
          myWebsites: myWebsites,
          myBusinesses: myBusinesses,
          myCurrentProfile: myCurrentProfile,
          myCurrentProfileLoading: myCurrentProfileLoading,
          sections: sections, 
          forceRerenderContext: forceRerender,
        },
        updateCurrentUser,
      ]}
      {...props}
    />
  );
}

function useCurrentUser() {
  //for pages that want to access the context object's value
  //custom hook use...
  try {
    const context = useContext(CurrentUserContext);
    // console.log('  inside the Provider',context);
    // if (!context) throw new Error('Not inside the Provider');
    return context; // [fav, updateFav]
  } catch (err) {}
}

export { useCurrentUser, CurrentUserProvider };
