 
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

// Material Dashboard 2 PRO React components 

// @mui icons
import Icon from "@mui/material/Icon";

// Images 
import Sales from "layouts/dashboards/sales";
import Analytics from "layouts/dashboards/analytics";
import MyOffices from "layouts/CommonLayouts/MyOffices";
import { Deck } from "@mui/icons-material";
 

const routesCommon = [ 
   
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
      {
        name: "Sales",
        key: "sales",
        route: "/dashboards/sales",
        component: <Sales />,
      },
    ],
  },
  {
    type: "collapse",
    name: "My Offices",
    key: "MyOffices",
    icon: <Deck />,
    route: "/My-Offices",
    component: <MyOffices />,
    noCollapse: true,
  }, 
];

export default routesCommon;
