/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

sectionNumberd by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import MDBadge from "components/MDBadge";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import {
  ArrowBackIosNewSharp,
  Book,
  Difference,
  ListAlt,
} from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import DashboardNavbar2 from "examples/Navbars/DashboardNavbar2";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { HttpService } from "Api-Services/httpService";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
function AllCourses() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const [loading, setLoading] = useState(false);
  const [forceRerender, setForceRerender] = useState(true);
  const [mode, setMode] = useState(""); 
  const [itemFormData, setItemFormData] = useState({});
  const [itemFilterData, setItemFilterData] = useState({});
  const [filteredcourses, setFilteredcourses] = useState([]);
  const [currentContextData, updateContext] = useCurrentUser();
  const { courses,departments,programs } = currentContextData;
  const [currentConversationWith, setCurrentConversationWith] = useState(null);

  React.useEffect(() => {
    (async () => {
      setFilteredcourses(courses);
      setForceRerender(!forceRerender);
      // console.log("courses", courses);
    })();
  }, []);

  React.useEffect(() => {
    (async () => { 
      setForceRerender(!forceRerender); 
    })();
  }, [courses,departments,programs]);

  const initializeForm = (currentItemData) => {
    let data = currentItemData || {};
    try {
      setItemFormData({});
      if (currentItemData) {
        setItemFormData(data);
      }
      setForceRerender(!forceRerender);
    } catch (error) {}
  };

  const deleteItem = async (id) => {
    try {
      let relativePath = "/api/section/course/" + id;
      setLoading(true);
      let postvacancyRes = await getDocuments("postvacancy", relativePath);
      setLoading(false);
      if (postvacancyRes.success) {
        updateContext("courses");
        setMode("");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      let data = itemFormData;
      let relativePath = "/api/course/initialize";
      if (mode == "edit") {
        if (currentConversationWith) {
          relativePath = "/api/course/edit/" + currentConversationWith.id;
        } else return;
      }
      setLoading(true);
      let postvacancyRes = await postDocuments(
        "postvacancy",
        relativePath,
        data
      );
      setLoading(false);
      if (postvacancyRes.success) {
        updateContext("courses");
        setMode("view");
        if (mode == "add" && postvacancyRes.record) {
          setCurrentConversationWith(postvacancyRes.record);
        } else {
          // object.entries()
          setMode("");
        }
      } 
    } catch (error) {
      setLoading(false);
    }
  };

  const getDocuments = async (documentName, relativePath) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    documentsResponse = await HttpService.getService(relativePath, {}, "");
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };
  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };

  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          // let btn = (
          //   <Button
          //     variant="text"
          //     onClick={() => {
          //       setMoreText({ content: str, sectionNumber: lableName });
          //       setOpenedDialog("moreText");
          //       setForceRerender(!forceRerender);
          //     }}
          //   >
          //     {"View more"}
          //   </Button>
          // );
          let txt = str.slice(0, num) + "...";
          res = (
            <>
              {txt}
              {/* {btn} */}
            </>
          );
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };

  const handleFormChange = (field, value) => {
    let itemFormDataD = itemFormData;
    itemFormDataD[field] = value;
    setItemFormData(itemFormDataD);
    setForceRerender(!forceRerender);
  };

  const handleFilterChange = (field, value) => {
    try {
      let itemFormDataD = itemFilterData;
      itemFormDataD[field]=value
      handleFilterData(itemFormDataD)  
      } catch (error) {
    }
  };

  
  const handleFilterData = (data) => {
    try {
      let itemFormDataD = data||{}; 
      let itemFormDataArray=Object.entries(itemFormDataD)
      let coursesD=courses
      let coursesN = coursesD.filter((sec)=>{
        let res=true
        itemFormDataArray.forEach(filterElement => {
          if(filterElement[1]!="Any"){
            if(sec[filterElement[0]]!=filterElement[1]){
              res=false
            }
          }
        });
      //  console.log("sec",sec,field, value) 
        return res
      })
      // console.log("coursesN",coursesN)
      setFilteredcourses(coursesN)
      setItemFilterData(data)
      setMode("")
      setForceRerender(!forceRerender);
    } catch (error) {
      
    }
  };

  

  const onEditClick = (data) => {
    initializeForm(data);
    setMode("edit");
  };

  const onAddClick = () => {
    initializeForm();
    setMode("add");
    setCurrentConversationWith(null);
  };

  const onViewClick = (data) => {
    setCurrentConversationWith(data);
    setMode("view");
    setForceRerender(!forceRerender);
  };

  const editBox = () => {
    return (
      <MDBox p={3}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              // error
              fullWidth
              id="outlined-error"
              label="Title"
              onChange={(e) => handleFormChange("title", e.target.value)}
              defaultValue={itemFormData.title}
            />
            <TextField
              // error
              fullWidth
              id="outlined-error"
              label="Code"
              onChange={(e) => handleFormChange("code", e.target.value)}
              defaultValue={itemFormData.code}
            /> 
            <TextField
              multiline
              fullWidth
              rows={3}
              onChange={(e) => handleFormChange("description", e.target.value)}
              // error
              id="outlined-error-helper-text"
              label={"Description"}
              defaultValue={itemFormData.description}
              // helperText="Incorrect entry."
            />
          </div> 
          {/* <div>
            <InputLabel id="demo-simple-select-autowidth-label">
              catagory
            </InputLabel>
            <Select
              sx={{ p: 1, mt: 1 }}
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={catagoryId}
              defaultValue={itemFormData.DepartmentCatagoryId}
              // label="Age"
              // onChange={handleChange}
              onChange={(e) =>
                handleFormChange("DepartmentCatagoryId", e.target.value)
              }
            >
              {departmentCategories?.map((catagory) => (
                <MenuItem value={catagory.id}>{catagory.sectionNumber}</MenuItem>
              ))}
            </Select>
          </div> */}
            {forceRerender}
        </Box>
      </MDBox>
    );
  };

  const filterBox = () => {
    return (<>

<MDBox p={1}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item md={4}>
                            <MDBox height="100%" mt={0.5} lineHeight={1}>
                            <InputLabel id="demo-simple-select-autowidth-label">
                              Program
                            </InputLabel>
                            <Select
                              sx={{ p: 1, mt: 1 }}
                              fullWidth
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              // value={catagoryId}
                              defaultValue={"Any"}
                              // label="Age"
                              // onChange={handleChange}
                              onChange={(e) =>{
                                handleFilterChange("ProgramId", e.target.value)
                              }
                              }
                            >
                                <MenuItem value={"Any"}>{"Any"}</MenuItem>
                              {programs?.map((program) => (
                                <MenuItem value={program.id}>{program.title}</MenuItem>
                              ))}
                            </Select>
                          
                            </MDBox>
                          </Grid> 
                          <Grid item md={4}>
                            <MDBox height="100%" mt={0.5} lineHeight={1}>
                            <InputLabel id="demo-simple-select-autowidth-label">
                              Department
                            </InputLabel>
                            <Select
                              sx={{ p: 1, mt: 1 }}
                              fullWidth
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              // value={catagoryId}
                              defaultValue={"Any"}
                              // label="Age"
                              // onChange={handleChange}
                              onChange={(e) =>{
                                handleFilterChange("DepartmentId", e.target.value)
                              }
                              }
                            >
                                <MenuItem value={"Any"}>{"Any"}</MenuItem>
                              {departments?.map((department) => (
                                <MenuItem value={department.id}>{department.title}</MenuItem>
                              ))}
                            </Select>
                          
                            </MDBox>
                          </Grid> 
                          <Grid item md={4}>
                            <MDBox height="100%" mt={0.5} lineHeight={1}>
                            <InputLabel id="demo-simple-select-autowidth-label">
                              Year
                            </InputLabel>
                            <Select
                              sx={{ p: 1, mt: 1 }}
                              fullWidth
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              // value={catagoryId}
                              defaultValue={"Any"}
                              // label="Age"
                              // onChange={handleChange}
                              onChange={(e) =>{
                                handleFilterChange("acadamicYear", e.target.value)
                              }
                              }
                            >
                                <MenuItem value={"Any"}>{"Any"}</MenuItem>
                              {[...Array(5)].map((n,i) => (
                                <MenuItem value={i+1}>{i+1}</MenuItem>
                              ))}
                            </Select>
                          
                            </MDBox>
                          </Grid> 
                        </Grid>
                      </MDBox>



      {/* <MDBox p={3}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
          }}
          noValidate
          autoComplete="off"
        > 
            <TextField
              // error
              fullWidth
              id="outlined-error"
              label="sectionNumber"
              onChange={(e) => handleFormChange("sectionNumber", e.target.value)}
              defaultValue={itemFormData.sectionNumber}
            />
            <TextField
              multiline
              fullWidth
              rows={3}
              onChange={(e) => handleFormChange("description", e.target.value)}
              // error
              id="outlined-error-helper-text"
              label={"Description"}
              defaultValue={itemFormData.description}
              // helperText="Incorrect entry."
            />  
            <div>
            <InputLabel id="demo-simple-select-autowidth-label">
              catagory
            </InputLabel>
            <Select
              sx={{ p: 1, mt: 1 }}
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={catagoryId}
              defaultValue={itemFormData.DepartmentCatagoryId}
              // label="Age"
              // onChange={handleChange}
              onChange={(e) =>
                handleFormChange("DepartmentCatagoryId", e.target.value)
              }
            >
              {departmentCategories?.map((catagory) => (
                <MenuItem value={catagory.id}>{catagory.sectionNumber}</MenuItem>
              ))}
            </Select>
          </div>  
            {forceRerender}
        </Box>
      </MDBox> */}
    </>);
  };

  function DetailBox() {
    return (
      <>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            {/* <MDTypography variant="h5">description</MDTypography> */}
            <MDBadge
              variant="contained"
              color="success"
              badgeContent={currentConversationWith.createdAt}
              container
            />
          </MDBox>
  
        </>
        <MDBox p={3}>
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >   
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ListAlt />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  currentConversationWith?.
                  title 
                }
                secondary="Title"
              />
            </ListItem> 
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ListAlt />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  currentConversationWith?.
                  code 
                }
                secondary="Code"
              />
            </ListItem>  
            {/* <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ListAlt />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  currentConversationWith?.preRequest 
                }
                secondary="Pre-request course"
              />
            </ListItem>    */}
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ListAlt />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  currentConversationWith?.
                  description 
                }
                secondary="Description"
              />
            </ListItem> 
          </List>
        </MDBox>
      </>
    );
  }
  
  function CoursesList() {
    let rows=filteredcourses||[]
    const columns = [
      { field: 'id',
         headerName: '#'  },
      {
        field: 'title',
        headerName: 'Title',
        // type: 'date',
        width: 150,
        // valueFormatter: (value) => dateFormatter.format(value),
      },
      {
        field: 'code',
        headerName: 'Code',
        width: 150,
        // type: 'singleSelect',
        // valueOptions: ['Jalapeño'],
      },
    ];
    
    return (
      <>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            {/* <MDTypography variant="h5">description</MDTypography> */}
            {/* <MDBadge
              variant="contained"
              color="success"
              badgeContent={currentConversationWith.createdAt}
              container
            /> */}
          </MDBox>
  
        </>
        <MDBox p={3}>
          <div style={{  width: '100%' }}>
            <DataGrid 
              onCellClick={(rowD)=>{ 
                if(rowD){ 
                  if(rowD.row){ 
                    onViewClick(rowD.row);
                }
              }
            }}
              rows={rows}
              columns={columns}
              // filterModel={filterModel}
              // onFilterModelChange={setFilterModel}
              disableColumnSelector
              disableDensitySelector
              hideFooter
              slots={{ toolbar: GridToolbar }}
              slotProps={{ toolbar: { showQuickFilter: true } }} 
            />
          </div>
        </MDBox>
      </>
    );
  }

  
  const renderSidenavItems = filteredcourses?.map((message, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component="a"
          // href={`#${href}`}
          onClick={() => {
            onViewClick(message);
          }}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
            backgroundColor: currentConversationWith
              ? currentConversationWith.title == message.title
                ? light.main
                : "none"
              : "none",
          })}
        >
          {/* <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}> */}
          <MDBox mr={2}>
            <Avatar alt="something here">
              <Book />
            </Avatar>
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <MDTypography variant="button" fontWeight="medium">
              {message.title +" || "+ message.code}
            </MDTypography>
            <MDTypography variant="caption" color="text">
              {currentConversationWith || isMobile
                ? truncateString(message.description, 40)
                : truncateString(message.description, 200)}
            </MDTypography>
          </MDBox>
          {/* {message.description} */}
        </MDTypography>
      </MDBox>
    );
  });
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar2 />
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox >
                <Grid container >
                  <Grid item xs={12}>
                    <Card id="profile">
                      <MDBox p={1}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item>
                            <MDBox height="100%" mt={0.5} lineHeight={1}>
                              <MDTypography variant="h5" fontWeight="medium">
                                {courses?.length +" Course(s)"}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
                            <MDBox
                              display="flex"
                              justifyContent={{ md: "flex-end" }}
                              alignItems="center"
                              lineHeight={1}
                            >
                              <Button
                                variant="button"
                                color="text"
                                fontWeight="medium"
                                onClick={() => {
                                  onAddClick();
                                }}
                              >
                                Add New
                              </Button>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>

            {/*<Grid item xs={12}>
              <MDBox mb={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card id="profile">
                    {filterBox()}
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>*/}

            {!(currentConversationWith && isMobile) && (
              <Grid item xs={12} lg={mode == "" ? 12 : 5}>
                <Card
                  sx={{
                    maxHeight: isMobile ? "700px" : "420px",
                    overflowY: "auto",
                    borderRadius: ({ borders: { borderRadius } }) =>
                      borderRadius.lg,
                    position: "sticky",
                    top: "1%",
                  }}
                >
                  <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                  >
                    <CoursesList/>
                    {/* {renderSidenavItems} */}
                  </MDBox>
                </Card>
              </Grid>
            )}

            {mode != "" && currentConversationWith && (
              <Grid item xs={12} lg={7}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="profile">
                        <MDBox p={2}>
                          <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <ArrowBackIosNewSharp
                                  onClick={() => {
                                    setCurrentConversationWith(null);
                                    setMode("");
                                  }}
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />
                              </Grid>
                            }
                            {/* <Grid item>
                              <MDAvatar
                                src={currentConversationWith.image}
                                alt="profile-image"
                                size="xl"
                                shadow="sm"
                              />
                            </Grid> */}
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {currentConversationWith.title}
                                </MDTypography>
                                {/* <MDTypography
                                  variant="button"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  {"Edit"}
                                </MDTypography>  */}
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )}
                                {/* <MDTypography
                                  variant="caption"
                                  fontWeight="regular"
                                > 
                                  {currentConversationWith.createdAt}
                                </MDTypography> */}

                                {mode != "edit" ? (
                                  <>
                                    {/* <Button
                                      variant="button"
                                      color="text"
                                      fontWeight="medium"
                                      onClick={() => {
                                        onEditClick(currentConversationWith);
                                      }}
                                    >
                                      {"Edit"}
                                    </Button>
                                    <Button
                                      variant="button"
                                      color="text"
                                      fontWeight="medium"
                                      onClick={() => {
                                        deleteItem(currentConversationWith.id);
                                      }}
                                    >
                                      {"Delete"}
                                    </Button> */}
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      variant="button"
                                      color="text"
                                      fontWeight="medium"
                                      onClick={() => {
                                        handleSubmit();
                                      }}
                                    >
                                      {"Save"}
                                    </Button>
                                    <Button
                                      variant="button"
                                      color="text"
                                      fontWeight="medium"
                                      onClick={() => {
                                        setMode("");
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                )}
                                {/* <MDBox ml={1}>
                            <Switch checked={visible} onChange={handleSetVisible} />
                          </MDBox> */}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card id="2fa" sx={{ overflow: "visible" }}>
                        {mode != "edit" ? (
                          <>
                            <DetailBox />
                          </>
                        ) : (
                          <>{editBox()}</>
                        )}
                      </Card>{" "}
                    </Grid>
                    {forceRerender}
                  </Grid>
                </MDBox>
              </Grid>
            )}

            {mode == "add" && (
              <Grid item xs={12} lg={7}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="profile">
                        <MDBox p={2}>
                          <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <ArrowBackIosNewSharp
                                  onClick={() => {
                                    setCurrentConversationWith(null);
                                    setMode("");
                                  }}
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />
                              </Grid>
                            }
                            {/* <Grid item>
                              <MDAvatar
                                src={currentConversationWith.image}
                                alt="profile-image"
                                size="xl"
                                shadow="sm"
                              />
                            </Grid> */}
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {"Add New"}
                                </MDTypography>
                                {/* <MDTypography
                                  variant="button"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  {"Edit"}
                                </MDTypography>  */}
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )}

                                <>
                                  <Button
                                    variant="button"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => {
                                      handleSubmit();
                                    }}
                                  >
                                    {"Sumbit"}
                                  </Button>
                                  <Button
                                    variant="button"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => {
                                      setMode("");
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </>
                                {/* <MDBox ml={1}>
                            <Switch checked={visible} onChange={handleSetVisible} />
                          </MDBox> */}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card id="2fa" sx={{ overflow: "visible" }}>
                        {editBox()}
                      </Card>{" "}
                    </Grid>
                    {forceRerender}
                  </Grid>
                </MDBox>
              </Grid>
            )}
            
          </Grid>
        </MDBox>
      </DashboardLayout>
      {forceRerender}
    </>
  );
}

export default AllCourses;
