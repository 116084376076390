import { Business, CheckCircle, HomeWork, Message } from "@mui/icons-material";
import Icon from "@mui/material/Icon";
import AllCatalogs from "layouts/Academic/Catalog";
import AllCourses from "layouts/Carriculum/Course";
import MyOffices from "layouts/CommonLayouts/MyOffices";
import AllDepartment from "layouts/OrganizationalUnit/Department";
import AllEmployees from "layouts/OrganizationalUnit/Employee";
import AllOffices from "layouts/OrganizationalUnit/Office";
import AllPrograms from "layouts/OrganizationalUnit/Program";

// Imag
const routesManagementOfficeLevel = [
  { type: "divider", key: "divider-0" },
  { type: "title", title: "Management Office", key: "Management" },
  {
    type: "collapse",
    name: "Organizational Unit",
    key: "Organizational Unit",
    icon: <Business fontSize="midium" />,
    collapse: [
      {
        name: "Classes Group",
        key: "Classes Group",
        route: "/Academic/SectionGroups",
        component: <AllCatalogs />,
      },
      {
        name: "Offices",
        key: "Offices",
        route: "/Organizational-Unit/Offices",
        component: <AllOffices />,
      },
      {
        name: "Employees",
        key: "Employees",
        route: "/Organizational-Unit/Employees",
        component: <AllEmployees />,
      },
      {
        name: "Departments",
        key: "Departments",
        route: "/Organizational-Unit/Departments",
        component: <AllDepartment />,
      },
      {
        name: "Programs",
        key: "Programs",
        route: "/Organizational-Unit/Programs",
        component: <AllPrograms />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Carriculum",
    key: "Carriculum",
    icon: <CheckCircle fontSize="medium" />,
    collapse: [
      {
        name: "Courses",
        key: "Courses",
        route: "/Carriculum/Courses",
        component: <AllCourses />,
      },
    ],
  },

  /////ende
  { type: "divider", key: "divider-0" },
];

export default routesManagementOfficeLevel;
