 
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
 
 
import { AssignmentTurnedIn,  HowToReg,  Message,  Person } from "@mui/icons-material";
 
import AllProfiles from "layouts/Academic/Profile"; 
import StudentAccountRequests from "layouts/Academic/Enrollment/Components/studentRequests";
import MyOffices from "layouts/CommonLayouts/MyOffices";

const routesRegistrarLevel = [
   
  { type: "divider", key: "divider-0" },
  { type: "title", title: "Registrar Office", key: "Registrar" },
   
  { 
    icon: <Person fontSize="medium"/>,
    name: "Profiles",
    key: "Profiles",
    route: "/Academic/Profiles",
    component: <AllProfiles />, 
    type: "collapse", 
    noCollapse: true,
  }, 
  {
    type: "collapse",
    name: "Enrollments",
    key: "Enrollments",
    icon: <AssignmentTurnedIn fontSize="medium" />,
    collapse: [
      {       
        name: "Student Account Requests",
        key: "StudentAccountRequests",
        route: "/Enrollments/Student-Account-Requests",
        component: <StudentAccountRequests />,
      }, 
      {
        name: "Assign Section",
        key: "settings",
        route: "/Enrollment/account/settings",
        component: <Settings />,
      },
      {       
        name: "Assign Course",
        key: "billing",
        route: "/Enrollment/account/billing",
        component: <Billing />,
      }, 
    ],
    
  }, 
  {
    type: "collapse",
    icon: <HowToReg fontSize="medium" />,
    name: "Addmision",
    key: "Addmision",
    collapse: [
      {
        name: "New Addmissons",
        key: "settings",
        route: "/pages/account/settings",
        component: <Settings />,
      },
      {       
        name: "Qualified Addmissons",
        key: "billing",
        route: "/pages/account/billing",
        component: <Billing />,
      },
      {
        name: "Disqualified Addmissons",
        key: "invoice",
        route: "/pages/account/invoice",
        component: <Invoice />,
      },
    ],
  },
  
  




  // {
  //   type: "collapse",
  //   name: "Web Contents",
  //   key: "Web Contents",
  //   icon: <Web/>,
  //   route: "/My-Agency/Web-Contents",
  //   component: <WebContents/>,
  //   noCollapse: true,
  // },  
  // { type: "divider", key: "divider-1" },
 
  // {
  //   type: "collapse",
  //   name: "Job Seekers",
  //   key: "Job Seekers",
  //   icon: <People/>,
  //   route: "/Employment/Job-Seekers",
  //   component: <JobSeekers/>,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Hiring Requests",
  //   key: "Hiring Requests",
  //   icon: <Work/>,
  //   route: "/Employment/Hiring-Requests",
  //   component: <JobHiringRequests/>,
  //   noCollapse: true,
  // }, 
  // {
  //   type: "collapse",
  //   name: "AllPlatforms",
  //   key: "AllPlatforms",
  //   icon: <Message/>,
  //   route: "/Employment/All-Platforms",
  //   component: <AllPlatforms/>,
  //   noCollapse: true,
  // }, 
//   {
// type: "collapse",
// icon: <Icon fontSize="medium">shopping_basket</Icon>,
//     name: "Products",
//     key: "products",
//     collapse: [
//       {
//         name: "All products",
//         key: "all-products",
//         route: "/ecommerce/products/new-products",
//         component: <AllPlatforms />,
//       },
//       {
//         name: "New Product",
//         key: "new-product",
//         route: "/ecommerce/products/new-product",
//         component: <NewProduct />,
//       },
//       {
//         name: "Edit Product",
//         key: "edit-product",
//         route: "/ecommerce/products/edit-product",
//         component: <EditProduct />,
//       },
//       {
//         name: "Product Page",
//         key: "product-page",
//         route: "/ecommerce/products/product-page",
//         component: <ProductPage />,
//       },
//     ],
//   },
//   {
//     type: "collapse",
//     icon: <Icon fontSize="medium">shopping_basket</Icon>,
//     name: "Orders",
//     key: "orders",
//     collapse: [
//       {
//         name: "Order List",
//         key: "order-list",
//         route: "/ecommerce/orders/order-list",
//         component: <OrderList />,
//       },
//       {
//         name: "Order Details",
//         key: "order-details",
//         route: "/ecommerce/orders/order-details",
//         component: <OrderDetails />,
//       },
//     ],
//   }, 
  // { type: "divider", key: "divider-1" },
  // { type: "title", title: "customers management", key: "title-customers" },
  // {
  //   type: "collapse",
  //   name: "Messages",
  //   key: "Messages",
  //   icon: <Message/>,
  //   route: "/crm/messages",
  //   component: <Messages/>,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Contact Messages",
  //   key: "Contact Messages",
  //   icon: <PermPhoneMsg  />,
  //   route: "/crm/contact-us-messages",
  //   component: <ContactUsMessages/>,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "Notifications",
  //   icon: <NotificationsActiveRounded/>,
  //   route: "/pages/notifications",
  //   component: <Notifications />,
  //   noCollapse: true,
  // },
  // { type: "divider", key: "divider-1" },
  // { type: "title", title: "Docs", key: "title-docs" },
  
  // {
  //   type: "collapse",
  //   name: "Support",
  //   key: "Support",
  //   href: "https://bfamilt.et",
  //   icon: <Icon fontSize="medium">receipt_long</Icon>,
  //   noCollapse: true,
  // },
  
  { type: "divider", key: "divider-0" },
];

export default routesRegistrarLevel;
