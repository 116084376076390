  
import { HomeWork, Message } from "@mui/icons-material";
import Icon from "@mui/material/Icon";
import AllCatalogs from "layouts/Academic/Catalog";
import MyOffices from "layouts/CommonLayouts/MyOffices";

// Imag 
const routesDepartmentOfficeLevel = [
   
  // { type: "divider", key: "divider-0" },
  // { type: "title", title: "Department Office", key: "Department" },
  
  // {
  //   type: "collapse",
  //   name: "Department Offices ",
  //   key: "Department Offices", 
  //   icon: <Message/>,
  //   route: "/my-offices/officeType/Department",
  //   component: <MyOffices/>,
  //   noCollapse: true,
  // },
  // { type: "divider", key: "divider-0" },
   
];

export default routesDepartmentOfficeLevel;
