// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import MDBadge from "components/MDBadge";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { Cancel } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import DashboardNavbar2 from "examples/Navbars/DashboardNavbar2";
import {
  Avatar,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HttpService } from "Api-Services/httpService";
import MDButton from "components/MDButton";
import ListItemComp from "components/common-components/listItemComp";
function StudentEnrollmentDetails() {
  const navigate = useNavigate();
  const params = useParams();

  const [controller] = useMaterialUIController();
  const [loading, setLoading] = useState(false);
  const [forceRerender, setForceRerender] = useState(true);
  const [response, setResponse] = useState(null);
  const [mode, setMode] = useState("add");
  const [editItemId, setEditItemId] = useState(false);
  const [student, setStudent] = useState({});
  const [studentLoading, setStudentLoading] = useState(false);
  const [currentConversationWith, setCurrentConversationWith] = useState(null);
  const [currentContextData, updateContext] = useCurrentUser();
  const { departments,programs, departmentCategories } = currentContextData;
  React.useEffect(() => {
    (async () => {
      // setFilteredstudents([]);
      console.log("params", params);
      if (params) {
        if (params.id) {
          setForceRerender(!forceRerender);
          setStudentLoading(true);
          await getData("student", params.id);
          setStudentLoading(false);
        }
      }
      // console.log("students", students);
    })();
  }, []);

  const getData = async (tableName, id) => {
    let details = {};
    try {
      let relativePath = "/api/" + tableName + "/find/" + id;
      // setLoading(true);
      let postvacancyRes = await getDocuments("postvacancy", relativePath);
      if (postvacancyRes.success) {
        details = postvacancyRes.record;
      }
    } catch (error) {}
    // setLoading(false);
    setStudent(details);
  };

  // const deleteItem = async (id) => {
  //   setLoading(true);
  //   try {
  //     let relativePath = "/api/" +data?.table+"/edit/" +id;
  //     let postvacancyRes = await getDocuments("postvacancy", relativePath);
  //     if (postvacancyRes.success) {
  //       // updateContext("students");
  //       // setMode("");
  //     }
  //   } catch (error) {
  //   }
  //   setLoading(false);
  // };

  // };

  const getDocuments = async (documentName, relativePath) => {
    let responseD = { succesft: false, message: "", record: null };
    let documentsResponse = { succesft: false };

    documentsResponse = await HttpService.getService(relativePath, {}, "");
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };

  function ProfileHeaderBox(dataD) {
    let data = dataD?.data || {};
    return (
      <Card id="2fa" sx={{ overflow: "visible" }}>
        <MDBox p={2}>
          <List
            dense
            sx={{
              width: "100%",
              // maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar src={data?.profileImageUrl} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  data.firstName +
                  " " +
                  data?.lastName +
                  " " +
                  data?.middleName +
                  " "
                }
                // secondary="Students "
              />
            </ListItem>
          </List>
        </MDBox>
      </Card>
    );
  }

  function ProfileBox(dataD) {
    let data = dataD?.data || {};
    return (
      <Card id="2fa" sx={{ overflow: "visible" }}>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5">student Info</MDTypography>
            <MDBadge
              variant="contained"
              color="success"
              badgeContent={data?.createdAt}
              container
            />
          </MDBox>
          {/* 
          <MDBox px={3}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{
                xft: "flex-start",
                sm: "center",
              }}
              flexDirection={{ xft: "column", sm: "row" }}
            >
              <MDTypography variant="body2" color="text">
                {currentConversationWith.description}
              </MDTypography>
            </MDBox>
          </MDBox> */}
        </>
        <MDBox p={3}>
          <List
            dense
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >
            {/* <Divider sx={{ my: 1 }} /> */}
            <ListItemComp
              updateData={() => getData("student", data.id)}
              data={{
                table: "student",
                id: data.id,
                fn: "profileImageUrl",
                ft: "student Image",
                v: null,
                av: data?.profileImageUrl,
                actions: { edit: true },
                inputType: "singleimagepicker",
              }}
            />
            <ListItemComp
              updateData={() => getData("student", data.id)}
              data={{
                table: "student",
                id: data.id,
                fn: "firstName",
                ft: "First Name ",
                v: data.firstName,
                actions: { edit: true },
                inputType: "textfield",
              }}
            />
            <ListItemComp
              updateData={() => getData("student", data.id)}
              data={{
                table: "student",
                id: data.id,
                fn: "lastName",
                ft: "Last Name ",
                v: data.lastName,
                actions: { edit: true },
                inputType: "textfield",
              }}
            />
            <ListItemComp
              updateData={() => getData("student", data.id)}
              data={{
                table: "student",
                id: data.id,
                fn: "middleName",
                ft: "Middle Name ",
                v: data.middleName,
                actions: { edit: true },
                inputType: "textfield",
              }}
            />
            <ListItemComp
              updateData={() => getData("student", data.id)}
              data={{
                table: "student",
                id: data.id,
                fn: "birthDate",
                ft: "Birth Date ",
                v: data.birthDate,
                actions: { edit: true },
                inputType: "textfielddate",
              }}
            />
            <ListItemComp
              updateData={() => getData("student", data.id)}
              data={{
                table: "student",
                id: data.id,
                fn: "sex",
                ft: "Gender ",
                v: data.sex,
                actions: { edit: true },
                inputType: "textfield",
              }}
            />
            <ListItemComp
              updateData={() => getData("student", data.id)}
              data={{
                table: "student",
                id: data.id,
                fn: "maritalStatus",
                ft: "Marital Status ",
                v: data.maritalStatus,
                actions: { edit: true },
                inputType: "textfield",
              }}
            />
          </List>
        </MDBox>
      </Card>
    );
  }

  function AddressBox(dataD) {
    let data = dataD?.data || {};
    return (
      <Card id="2fa" sx={{ overflow: "visible" }}>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5">Address </MDTypography>
            <MDBadge
              variant="contained"
              color="success"
              badgeContent={data.createdAt}
              container
            />
          </MDBox>
        </>
        <MDBox p={3}>
          <List
            dense
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >
            <ListItemComp data={{ ft: "Country ", v: data.country, a: null }} />
            <ListItemComp data={{ ft: "Region ", v: data.region, a: null }} />
            <ListItemComp data={{ ft: "Zone ", v: data.zone, a: null }} />
            <ListItemComp data={{ ft: "Woreda ", v: data.woreda, a: null }} />
            <ListItemComp data={{ ft: "City ", v: data.city, a: null }} />
            <ListItemComp data={{ ft: "SubCity ", v: data.subCity, a: null }} />
            <ListItemComp
              data={{ ft: "Specific Area ", v: data.specificArea, a: null }}
            />
          </List>
        </MDBox>
      </Card>
    );
  }

  function StudentBox(dataD) {
    let data = dataD?.data || {};
    return (
      <Card sx={{ overflow: "visible" }}>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5">Student </MDTypography>
            {/* <MDBadge
              variant="contained"
              color="success"
              badgeContent={data.createdAt}
              container
            /> */}
          </MDBox>
        </>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <MDBox p={2}>
              <List
                dense
                sx={{
                  width: "100%",
                  // maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <ListItemComp
                  data={{
                    // table: "student",
                    // id: data.id,
                    ft: "Program ",
                    fn: "ProgramId", 
                    v: data?.Program?.title,
                    // actions: { edit: true },
                    // inputType: "select",
                    // options:programs,
                    // optionfn:"id",
                    // optionDefaultValue:data.ProgramId,
                    // optionft:"title",
                  }}
                />
                <ListItemComp
                  data={{
                    ft: "Department ",
                    // fn: "DepartmentId ",
                    v:
                      data?.Department?.title +
                      " || " +
                      data?.Department?.major +
                      " || " +
                      data?.Department?.minor, 
                    // table: "student",
                    // id: data.id, 
                    // actions: { edit: true },
                    // inputType: "select",  
                    // options:departments,
                    // optionfn:"id",
                    // optionDefaultValue:data.DepartmentId,
                    // optionft:"title",
                  }}
                />
                <ListItemComp
                  updateData={() => getData("student", data.id)}
                  data={{
                    table: "student",
                    id: data.id,
                    fn: "acadamicYear",
                    ft: "Acadamic Year ",
                    v: data?.acadamicYear,
                    actions: { edit: true },
                    inputType: "textfieldnumber",
                  }}
                />
                <ListItemComp
                  data={{
                    ft: "Registered date",
                    v: data?.createdAt,
                  }}
                />

                <Divider orientation="vertical" flexItem />
              </List>
            </MDBox>
          </Grid>
        </Grid>
      </Card>
    );
  }

  function SectionBox(dataD) {
    let data = dataD?.data || [];
    return (
      <Card sx={{ overflow: "visible" }}>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5">Section (main) </MDTypography>
            <MDBadge
              component={Link}
              to={"/Organizational-Unit/Sections/" + data.id}
              variant="contained"
              color="success"
              badgeContent={"Manage"}
              container
            />
          </MDBox>
        </>
        <MDBox p={2}>
          <List
            dense
            sx={{
              width: "100%",
              // maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >
            <ListItemComp
              data={{
                ft: "Section Number ",
                v: "Section " + data?.sectionNumber,
                a: null,
              }}
            />
            <ListItemComp
              data={{
                ft: "Acadamic Year ",
                v: data?.acadamicYear + " year",
                a: null,
              }}
            />
            <ListItemComp
              data={{
                ft: "Department ",
                v:
                  data?.Department?.title +
                  " || " +
                  data?.Department?.major +
                  " || " +
                  data?.Department?.minor,
                a: null,
              }}
            />
            <ListItemComp
              data={{
                ft: "Program ",
                v: data?.Program?.title,
                a: null,
              }}
            />
            <ListItemComp
              data={{
                ft: "Registered date",
                v: data?.createdAt,
                a: null,
              }}
            />
            {/* <ListItemComp
              data={{
                ft: "Courses taking",
                v: data.SectionCatalogCourse?.StudentSectionCatalogCourses
                  ?.length,
                a: null,
              }}
            /> */}

            <Divider orientation="vertical" flexItem />
          </List>
        </MDBox>
      </Card>
    );
  }

  
  function StudentEnrollments(dataD) {
    let data = dataD?.data || [];
    return (
      <Card id="2fa" sx={{ overflow: "visible" }}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          <MDTypography variant="h5">Student Enrollments </MDTypography>
          <MDBadge
            component={Link}
            to={"/Academic/Students/"+student?.id+"/Enrollments/"}
            variant="contained"
            color="success"
            badgeContent={"Manage"}
            container
          />
        </MDBox> 
        <MDBox p={2}>
          <List
            dense
            sx={{
              width: "100%",
              // maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >
         {data?.map((studentEnrollment)=><><ListItem>
              <ListItemAvatar>
                <Avatar src={""} />
              </ListItemAvatar>
              <ListItemText
                primary={<>
                <Chip label={"Course: "+ studentEnrollment?.SectionCourse?.Course?.title+" | "+
                  studentEnrollment?.SectionCourse?.Course?.code+" "} />
                 
                <Chip label={"Section: sec-"+  studentEnrollment?.SectionCourse?.Section?.sectionNumber+" | "
                 + studentEnrollment?.SectionCourse?.Section?.acadamicYear+" year | " 
                 + studentEnrollment?.SectionCourse?.Section?.Department?.title+" " 
              } />
              <Chip label={"Enrolled Date: "+  studentEnrollment?.createdAt+" " 
            } />
                 
                 </> }
                // secondary="Students "
              />
            </ListItem>
              <Divider sx={{pt:0.5,my:0}}/></>  )}
          </List>
        </MDBox>
      </Card>
    );
  }


  return (
    <>
      <DashboardLayout>
        <DashboardNavbar2 />
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              {!studentLoading ? (
                <MDBox mb={3}>
                  {student ? (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Card id="student">
                          <MDBox p={2}>
                            <Grid container spacing={3} alignItems="center">
                              {/* <Grid item>
                              <MDAvatar
                                src={currentConversationWith.image}
                                alt="student-image"
                                size="xl"
                                shadow="sm"
                              />
                            </Grid> */}
                              <Grid item>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                  <MDTypography
                                    variant="h5"
                                    fontWeight="medium"
                                  >
                                    {"student Details"}
                                  </MDTypography>
                                  {/* <MDTypography
                                  variant="button"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  {"Edit"}
                                </MDTypography>  */}
                                </MDBox>
                              </Grid>
                              {/* <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )}

                                <>
                                  <Button
                                    variant="button"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => {
                                      if(addUserStep!=1){
                                        handleSubmit();
                                      }
                                      else{
                                        handleCreateUser()
                                      }
                                    }}
                                  >
                                    {"Sumbit"}
                                  </Button>
                                  <Button
                                    variant="button"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => {
                                      setMode("");
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </> 
                              </MDBox>
                            </Grid> */}
                            </Grid>
                          </MDBox>
                        </Card>
                      </Grid>
                      <Grid item xs={12}>
                        <ProfileHeaderBox data={student.Profile} />
                      </Grid>
                      <Grid item md={6}>
                        <StudentBox data={student} />
                      </Grid>
                      <Grid item md={6}>
                        <SectionBox data={student.Section} />
                      </Grid>
                      <Grid item xs={12}>
                        <StudentEnrollments data={student.StudentEnrollments} />
                      </Grid>
                      {forceRerender}
                    </Grid>
                  ) : (
                    <Grid container spacing={3}></Grid>
                  )}

                  {/* {response&& <Grid item xs={12}>
                      <Card  id="2fa" sx={{mt:2, overflow: "visible" }}>
                       {responseMessageBox()}
                      </Card>{" "}
                    </Grid>} */}
                </MDBox>
              ) : (
                <MDBox p={3} m={3}>
                  <Grid container spacing={3}>
                    <MDBox sx={{ width: 600 }}>
                      <Skeleton height={58} />
                      <Skeleton height={58} animation="wave" />
                      <Skeleton height={58} animation="wave" />
                    </MDBox>
                  </Grid>
                </MDBox>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
      {forceRerender}
    </>
  );
}

export default StudentEnrollmentDetails;
