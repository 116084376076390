/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import MDBadge from "components/MDBadge";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import {
  ArrowBackIosNewSharp,
  Book,
  CheckBox,
  CheckBoxOutlineBlank,
  Delete,
  Difference,
  ListAlt,
} from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import DashboardNavbar2 from "examples/Navbars/DashboardNavbar2";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { HttpService } from "Api-Services/httpService";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CommonApiServices } from "Api-Services/commonApiServices";

 

function AllOffices() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [forceRerender, setForceRerender] = useState(true);
  const [openedDialog, setOpenedDialog] = useState(true);
  const [mode, setMode] = useState(""); 
  const [itemFormData, setItemFormData] = useState({});
  const [officeTypes, setOfficeTypes] = useState([]);
  const [offices, setOffices ] = useState([]);
  const [currentContextData, updateContext] = useCurrentUser();
  const {  departments,programToDepartments, departmentCategories } = currentContextData;
  const [currentConversationWith, setCurrentConversationWith] = useState(null);
  const [forceRerender2, setForceRerender2] = useState(false);
  const [addMemberFormData, setAddMemberFormData] = useState({});
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  
const handleAddMemberFormChange = (field, value) => {
  let itemFormDataD = addMemberFormData;
  itemFormDataD[field] = value;
  setAddMemberFormData(itemFormDataD);
  setForceRerender2(!forceRerender2);
};

const handleOfficeMemberSubmit = async (action,dataD) => {
  try {
    let data = {};
    let relativePath = "";
    if(action=="addmember"){
         relativePath = "/api/office/"+currentConversationWith?.id+"/addmember";   ///changememberrole  /////removemember
         data = addMemberFormData;
         data.OfficeMemberRoleId=1
    }
    if(action=="changememberrole"){
        relativePath = "/api/office/"+currentConversationWith?.id+"/changememberrole";   ///changememberrole  /////removemember
        data = dataD;
        //  data.OfficeId=myOfficeDetails?.Office?.id
    }
    if(action=="removemember"){
        relativePath = "/api/office/"+currentConversationWith?.id+"/removemember";   ///changememberrole  /////removemember
        data = dataD;
        //  data.OfficeId=myOfficeDetails?.Office?.id
    } 
    setLoading(true);
    let postvacancyRes = await postDocuments(
      "postvacancy",
      relativePath,
      data
    );
    if (postvacancyRes.success) {
      getStarted() 
      setOpenedDialog(false)
    } 
  } catch (error) { 
  }
    setLoading(false);
};

  React.useEffect(() => {
    (async () => { 
      setFirstLoading(true);
      await getStarted()
      setFirstLoading(false);   
      console.log("Offices", offices);
    })();
  }, []);
  
  const getStarted= async()=>{
    let officeTypesD=[]
    let officesD=[]
    setMode("");
    setOpenedDialog("")
    setAddMemberFormData({})
    try {
      let relativePath = "/api/office/all"  ; 
      let officeRes = await getDocuments("postvacancy", relativePath);
      if(officeRes?.success){
          if(officeRes?.record?.length){
            officesD=officeRes.record
        }
      }
      setOffices(officesD)
      setForceRerender(!forceRerender);
      
      let relativePath2 = "/api/office/officeType/all"  ; 
      let officeTypeRes = await getDocuments("postvacancy", relativePath2);
      if(officeTypeRes?.success){
        if(officeTypeRes?.record?.length){
        officeTypesD=officeTypeRes.record
        }
      }
      setOfficeTypes(officeTypesD)
      } catch (error) { 
    }  
     setForceRerender(!forceRerender);
  }

  const initializeForm = (currentItemData) => {
    let data = currentItemData || {};
    try {
      setItemFormData({});
      if (currentItemData) {
        setItemFormData(data);
      }
      setForceRerender(!forceRerender);
    } catch (error) {}
  };

  const deleteItem = async (id) => {
    try {
      let relativePath = "/api/department/delete/" + id;
      setLoading(true);
      let postvacancyRes = await getDocuments("postvacancy", relativePath);
      setLoading(false);
      if (postvacancyRes.success) {
        updateContext("departments");
        setMode("");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      let data = itemFormData;
      let relativePath = "/api/office/initialize";
      if (mode == "edit") {
        if (currentConversationWith) {
          relativePath = "/api/office/edit/" + currentConversationWith.id;
        } else return;
      }
      setLoading(true);
      let postvacancyRes = await postDocuments(
        "postvacancy",
        relativePath,
        data
      );
      setLoading(false);
      if (postvacancyRes.success) {
        updateContext("offices");
        setMode("view");
        if (mode == "add" && postvacancyRes.record) {
          setCurrentConversationWith(postvacancyRes.record);
        } else {
          // object.entries()
          setMode("");
        }
      } 
    } catch (error) {
      setLoading(false);
    }
  };

  const getDocuments = async (documentName, relativePath) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    documentsResponse = await HttpService.getService(relativePath, {}, "");
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };
  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }
    return responseD;
  };

  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          // let btn = (
          //   <Button
          //     variant="text"
          //     onClick={() => {
          //       setMoreText({ content: str, title: lableName });
          //       setOpenedDialog("moreText");
          //       setForceRerender(!forceRerender);
          //     }}
          //   >
          //     {"View more"}
          //   </Button>
          // );
          let txt = str.slice(0, num) + "...";
          res = (
            <>
              {txt}
              {/* {btn} */}
            </>
          );
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };

  const handleFormChange = (field, value) => {
    let itemFormDataD = itemFormData;
    itemFormDataD[field] = value;
    setItemFormData(itemFormDataD);
    setForceRerender(!forceRerender);
  };

  const onEditClick = (data) => {
    initializeForm(data);
    setMode("edit");
  };

  const onAddClick = () => {
    initializeForm();
    setMode("add");
    setCurrentConversationWith(null);
  };

  const onViewClick = (data) => {
    setCurrentConversationWith(data);
    setMode("view");
    setForceRerender(!forceRerender);
  };

  const editBox = () => {
    return (
      <MDBox p={3}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              // error
              fullWidth
              id="outlined-error"
              label="Title"
              onChange={(e) => handleFormChange("title", e.target.value)}
              defaultValue={itemFormData.title}
            />
            <TextField
              multiline
              fullWidth
              rows={3}
              onChange={(e) => handleFormChange("description", e.target.value)}
              // error
              id="outlined-error-helper-text"
              label={"Description"}
              defaultValue={itemFormData.description}
              // helperText="Incorrect entry."
            />
          </div> 
          <div>
            {/* <FormControl fullWidth> */}
            <InputLabel id="demo-simple-select-autowidth-label">
            Office Type 
            </InputLabel>
            <Select
              sx={{ p: 1, mt: 1 }}
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={catagoryId}
              defaultValue={itemFormData.OfficeTypeId}
              // label="Age"
              // onChange={handleChange}
              onChange={(e) =>
                handleFormChange("OfficeTypeId", e.target.value)
              }
            >
              {officeTypes?.map((catagory) => (
                <MenuItem value={catagory.id}>{catagory.title}</MenuItem>
              ))}
            </Select>
            {/* </FormControl> */}
            {forceRerender}
          </div>
 

        </Box>
      </MDBox>
    );
  };
 
    
  const detailBox=() =>{
    return (
      <>
        <MDBox p={3}>
          <List
            sx={{
              width: "100%",
              minWidth: 360,
              bgcolor: "background.paper",
            }}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Book />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={currentConversationWith.title}
                secondary="title"
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Book />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={currentConversationWith.OfficeMembersCount}
                secondary="Office Members Count"
              />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Book />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={currentConversationWith.ProgramToDepartmentsCount}
                secondary=" Departments Count"
              />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Difference />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={ currentConversationWith?.OfficeType?.title
                    
                }
                secondary="Office Type"
              />
            </ListItem> 
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ListAlt />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  currentConversationWith.createdAt
                }
                secondary="Created At"
              />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            {/* </Stack> */}
          </List>
          
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5"> Departments Group</MDTypography>
             
            
          </MDBox>
          <MDBox 
          > 
            {/* <Stack direction="row" spacing={1}>  */}
              {currentConversationWith?.ProgramToDepartments?.map((programToDepartment)=><Chip
                label={
                  programToDepartment?.Department?.title+" | "+
                  programToDepartment?.Program?.title+" | "+
                  programToDepartment?.EducationClassification?.title 
                }
                // onClick={handleClick}
                // onDelete={handleDelete}
                deleteIcon={<Delete />}
                variant="outlined"
              />)}
            
          </MDBox>

          <Divider/>
          
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5"> Office managers</MDTypography>
            
        <Button autoFocus
            fontWeight="medium"
            onClick={() => { 
              setOpenedDialog("addmember")
            }}
          >
            {"Add office manager"}
          </Button>
            
          </MDBox>
          {openedDialog=="addmember"&&
          
          <MDBox p={1}>
          <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    

                    // value={currentConversationWith?.sectionCourse?.Instructor}
                    // onChange={(event) => {
                    //   // setNewInstructor(newValue);
                    // }}
                    onChange={(e, newValue) => handleAddMemberFormChange("EmployeeId", newValue?.id)}
                    options={filteredEmployees} 
                    getOptionLabel={(option) =>
                      option?.Profile?.firstName +
                      " " +
                      option?.Profile?.lastName +
                      " " +
                      option?.Profile?.middleName
                    }
                    autoHighlight
                    // getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          src={option?.Profile?.profileImageUrl}
                          alt=""
                        />
                        {option?.Profile?.firstName +
                          " " +
                          option?.Profile?.lastName +
                          " " +
                          option?.Profile?.middleName}
                      </Box>
                    )}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                      placeholder="Search employee name"
                      // onChange={async (e) => {
                      //   let instD = await CommonApiServices?.searchDataByKeywordAndField(
                      //       "employee",
                      //       e.target.value,{}
                      //     );
                      //   setFilteredEmployees(instD);
                      //   setForceRerender2(!forceRerender2);
                      // }}
                      
                        onChange={async (e) => {
                          let instD=[]
                          let instDRes = await CommonApiServices?.searchDataByKeywordAndField(
                              "employee",
                              e.target.value,{}
                            );
                            if(instDRes?.length){
                              instD=instDRes
                            }
                            setFilteredEmployees(instD);
                          // setForceRerender2(!forceRerender2);
                        }}
                        {...params}
                        label="Employee"
                      />
                    )}
                  /> 

          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            
        <Button autoFocus
            fontWeight="medium"
            onClick={() => {
              handleOfficeMemberSubmit("addmember");
            }}
          >
            {"Sumbit"}
          </Button>
          <Button 
            variant="button"
            color="text"
            onClick={()=>{setOpenedDialog(false)}}  >
            Cancel
          </Button>
            
          </MDBox>
          </MDBox>

            
          }
          <MDBox 
          > 
            {/* <Stack direction="row" spacing={1}>  */}
              {currentConversationWith?.OfficeMembers?.map((OfficeMember)=>(OfficeMember.OfficeMemberRoleId==1)&&<Chip
                label={
                  OfficeMember?.Employee?.Profile?.firstName+" "+
                  OfficeMember?.Employee?.Profile?.lastName+" "+
                  OfficeMember?.Employee?.Profile?.middleName
                }
                // onClick={handleClick}
                onDelete={()=>{
                  if(OfficeMember?.Employee?.Profile?.id){
                    handleOfficeMemberSubmit("removemember",{EmployeeId:OfficeMember?.Employee?.id});
                  }  
                }}
               
                deleteIcon={<Delete />}
                variant="outlined"
              />)}
            
          </MDBox>

        </MDBox>
        
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5">description</MDTypography>
            
          </MDBox>

          <MDBox px={3}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{
                xs: "flex-start",
                sm: "center",
              }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <MDTypography variant="body2" color="text">
                {currentConversationWith.description}
              </MDTypography>
            </MDBox>
          </MDBox>
          <Divider />
        </>
      </>
    );
  }

  
  function DepartmentList() {
    let rows=offices||[]
    const columns = [
      { field: 'id',
         headerName: '#'  },
      {
        field: 'title',
        headerName: 'Title',
        // type: 'date',
        width: 350,
        // valueFormatter: (value) => dateFormatter.format(value),
      },
      {
        field: 'OfficeMembersCount',
        headerName: 'Office Members',
        width: 150,
        // type: 'singleSelect',
        // valueOptions: ['Jalapeño'],
      },
      {
        field: 'ProgramToDepartmentsCount',
        headerName: 'Departments',
        width: 150,
        // type: 'singleSelect',
        // valueOptions: ['Jalapeño'],
      },
      // {
      //   field: 'minor',
      //   headerName: 'Minor',
      //   // type: 'singleSelect',
      //   width: 150,
      //   // valueOptions: ['Jalapeño'],
      // },
    ];
    
    return (
      <>
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            {/* <MDTypography variant="h5">description</MDTypography> */}
            {/* <MDBadge
              variant="contained"
              color="success"
              badgeContent={currentConversationWith.createdAt}
              container
            /> */}
          </MDBox>
  
        </>
        <MDBox p={3}>
          <div style={{  width: '100%' }}>
            <DataGrid 
              onCellClick={(rowD)=>{ 
                if(rowD){ 
                  if(rowD.row){ 
                    onViewClick(rowD.row);
                }
              }
            }}
              rows={rows}
              columns={columns}
              // filterModel={filterModel}
              // onFilterModelChange={setFilterModel}
              disableColumnSelector
              disableDensitySelector
              hideFooter
              slots={{ toolbar: GridToolbar }}
              slotProps={{ toolbar: { showQuickFilter: true } }} 
            />
          </div>
        </MDBox>
      </>
    );
  }


  const renderSidenavItems = offices.map((message, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component="a"
          // href={`#${href}`}
          onClick={() => {
            onViewClick(message);
          }}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
            backgroundColor: currentConversationWith
              ? currentConversationWith.title == message.title
                ? light.main
                : "none"
              : "none",
          })}
        >
          {/* <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}> */}
          <MDBox mr={2}>
            <Avatar alt="something here">
              <Book />
            </Avatar>
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <MDTypography variant="button" fontWeight="medium">
              {message.title}
            </MDTypography>
            <MDTypography variant="caption" color="text">
              {currentConversationWith || isMobile
                ? truncateString(message.description, 40)
                : truncateString(message.description, 200)}
            </MDTypography>
          </MDBox>
          {/* {message.description} */}
        </MDTypography>
      </MDBox>
    );
  });
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar2 />
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox mb={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card id="profile">
                      <MDBox p={1}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item>
                            <MDBox height="100%" mt={0.5} lineHeight={1}>
                              <MDTypography variant="h5" fontWeight="medium">
                                {"offices"}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
                            <MDBox
                              display="flex"
                              justifyContent={{ md: "flex-end" }}
                              alignItems="center"
                              lineHeight={1}
                            >
                              <Button
                                variant="button"
                                color="text"
                                fontWeight="medium"
                                onClick={() => {
                                  onAddClick();
                                }}
                              >
                                Add New
                              </Button>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>

            {!(currentConversationWith && isMobile) && (
              <Grid item xs={12} lg={mode == "" ? 12 : 5}>
                <Card
                  sx={{
                    // maxHeight: isMobile ? "700px" : "420px",
                    overflowY: "auto",
                    borderRadius: ({ borders: { borderRadius } }) =>
                      borderRadius.lg,
                    position: "sticky",
                    top: "1%",
                  }}
                >
                  <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                  >
                    {/* {renderSidenavItems} */}
                    <DepartmentList/>
                  </MDBox>
                </Card>
              </Grid>
            )}
            {mode != "" && currentConversationWith && (
              <Grid item xs={12} lg={7}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="profile">
                        <MDBox p={2}>
                          <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <ArrowBackIosNewSharp
                                  onClick={() => {
                                    setCurrentConversationWith(null);
                                    setMode("");
                                  }}
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />
                              </Grid>
                            }
                            {/* <Grid item>
                              <MDAvatar
                                src={currentConversationWith.image}
                                alt="profile-image"
                                size="xl"
                                shadow="sm"
                              />
                            </Grid> */}
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {currentConversationWith.title}
                                </MDTypography>
                                {/* <MDTypography
                                  variant="button"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  {"Edit"}
                                </MDTypography>  */}
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )} 

                                {mode != "edit" ? (
                                  <>
                                    <Button
                                      variant="button"
                                      color="text"
                                      fontWeight="medium"
                                      onClick={() => {
                                        onEditClick(currentConversationWith);
                                      }}
                                    >
                                      {"Edit"}
                                    </Button>
                                    <Button
                                      variant="button"
                                      color="text"
                                      fontWeight="medium"
                                      onClick={() => {
                                        deleteItem(currentConversationWith.id);
                                      }}
                                    >
                                      {"Delete"}
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      variant="button"
                                      color="text"
                                      fontWeight="medium"
                                      onClick={() => {
                                        handleSubmit();
                                      }}
                                    >
                                      {"Save"}
                                    </Button>
                                    <Button
                                      variant="button"
                                      color="text"
                                      fontWeight="medium"
                                      onClick={() => {
                                        setMode("");
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                )}
                                {/* <MDBox ml={1}>
                            <Switch checked={visible} onChange={handleSetVisible} />
                          </MDBox> */}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card id="2fa" sx={{ overflow: "visible" }}>
                        {mode != "edit" ? (
                          <>
                             {detailBox()} 
                          </>
                        ) : (
                          <>{editBox()}</>
                        )}
                      </Card>{" "}
                    </Grid>
                    {forceRerender}
                  </Grid>
                </MDBox>
              </Grid>
            )}
            {mode == "add" && (
              <Grid item xs={12} lg={7}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="profile">
                        <MDBox p={2}>
                          <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <ArrowBackIosNewSharp
                                  onClick={() => {
                                    setCurrentConversationWith(null);
                                    setMode("");
                                  }}
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />
                              </Grid>
                            }
                            {/* <Grid item>
                              <MDAvatar
                                src={currentConversationWith.image}
                                alt="profile-image"
                                size="xl"
                                shadow="sm"
                              />
                            </Grid> */}
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {"Add New"}
                                </MDTypography>
                                {/* <MDTypography
                                  variant="button"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  {"Edit"}
                                </MDTypography>  */}
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )}

                                <>
                                  <Button
                                    variant="button"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => {
                                      handleSubmit();
                                    }}
                                  >
                                    {"Sumbit"}
                                  </Button>
                                  <Button
                                    variant="button"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => {
                                      setMode("");
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </>
                                {/* <MDBox ml={1}>
                            <Switch checked={visible} onChange={handleSetVisible} />
                          </MDBox> */}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card id="2fa" sx={{ overflow: "visible" }}>
                        {editBox()}
                      </Card>{" "}
                    </Grid>
                    {forceRerender}
                  </Grid>
                </MDBox>
              </Grid>
            )}
          </Grid>
        </MDBox>
      </DashboardLayout>
      {forceRerender}


      
      <Dialog 
       sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
       onClose={()=>{setOpenedDialog("")}} 
       open={openedDialog=="addmember2"}>
        <DialogTitle> 
        
        <Grid container spacing={3} alignItems="center">
                            {
                              <Grid item>
                                <ArrowBackIosNewSharp
                                  onClick={() => { setOpenedDialog("sectiongroupmenu")}} 
                                  alt="profile-image"
                                  size="xl"
                                  shadow="sm"
                                />
                              </Grid>
                            }
                            <Grid item>
                              <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {"Add office manager"}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              sx={{ ml: "auto" }}
                            >
                              <MDBox
                                display="flex"
                                justifyContent={{ md: "flex-end" }}
                                alignItems="center"
                                lineHeight={1}
                              >
                                {loading && (
                                  <MDBox>
                                    {" "}
                                    <CircularProgress />
                                  </MDBox>
                                )} 
                              </MDBox>
                            </Grid>
                          </Grid>
          </DialogTitle>

        {/* {addmemberBox()} */}
        
      </Dialog>

       

    </>
  );
}

export default AllOffices;
