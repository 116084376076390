/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { HttpService } from "Api-Services/httpService";
import { CircularProgress } from "@mui/material";
import { UseAuth } from "Api-Services/useAuth";
import axios from "axios";
import queryString from "query-string";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  //global constants
  // const [currentUser ,updateCurrentUser ] = useCurrentUser();
  const [forceRerender, setForceRerender] = useState(true);

  const [loading, setLoading] = useState(false);
  const [userCardId, setUserCardId] = useState("");
  const [isFormValid, setIsFormValid] = useState(true);
  const [invalidResponse, setInvalidResponse] = useState("");
  const [loginResponse, setLoginResponse] = useState(null);

  const [password, setPassword] = useState("");

  useEffect(async () => {
    await UseAuth.logout();
    console.log("location", location);
    let baseUrl = "https://auth.dce.baally.com/login";
    // let redirectTo=""
    let redirectTo = "/pages/my-profiles";
    let searchData = "";
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch) {
        if (locationSearch.redirectTo) {
          redirectTo = locationSearch.redirectTo;
        }
        // setRouteSearch(locationSearch);
      }
    }
    searchData = "?redirectUrl=" + location.origin + redirectTo;
    searchData = searchData + "&authDeepth=businessaccount";

    if (location.hostname) {
      if (location.hostname == "localhost") {
        baseUrl = "http://localhost:5004/login";
      }
    }

    window.location.assign(baseUrl + searchData);
  }, [location.pathname, location.search]);

  //

  const getDocumentsAll = async (
    documentName,
    relativePath,
    additionalData
  ) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    // console.log(documentName, " data: ", documentsResponse   );

    if (documentsResponse.success) {
      responseD.success = true;
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
      let record = responseD.record;

      //responseD.record //write conditions down here

      if (documentName == "officeRole") {
        // setAllOfficeRoles(record.officeRoles)
      }
    }

    //  console.log(documentName, " responseD: ", responseD   );
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      {},
      ""
    );
    // console.log(documentName, " data: ", documentsResponse   );
    setLoading(false);

    if (documentsResponse.success) {
      responseD.success = true;
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
      let record = responseD.record;

      //write codtions down here//

      if (documentName == "login") {
        localStorage.clear();
        setLoginResponse(documentsResponse);

        setLoading(true);
        let AdditionalData = { withCredentials: true, credentials: "include" }; //{  credentials: 'include' }//header can be included here
        await getDocumentsAll(
          "allOffices",
          "/api/officeUserRole/myoffices",
          AdditionalData
        ); //init user offices and data
        setLoading(false);
        let path = "/";
        window.location.assign(path);
      }
    } else {
      responseD.message = documentsResponse.message;

      if (documentName == "login") {
        setLoginResponse(responseD);
      }
    }

    // console.log(documentName, " responseD: ", responseD   );
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const login = async () => {
    localStorage.clear();
    let valid = false;
    setLoginResponse(null);
    setInvalidResponse("");
    setIsFormValid(true);

    if (userCardId && password) {
      setIsFormValid(true);
      valid = true;
    } else {
      setIsFormValid(false);
      valid = false;
      setInvalidResponse(" Please provide an id and password");
    }
    if (valid) {
      setLoading(true);
      let data = {
        // "platformUserName":companyName,
        userEmail: userCardId,
        password: password,
        // "userType":selectedUserType,
        callerSite: "platformadminportal",
      };
      let relativePath = "/api/authorization/userlogin";
      let AdditionalData = { withCredentials: true, credentials: "include" }; //{  credentials: 'include' }//header can be included here
      await postDocuments("login", relativePath, data, AdditionalData);
    }
    setLoading(false);
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          >
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                onChange={(e) => {
                  setUserCardId(e.target.value);
                }}
                type="email"
                label="Email"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                label="Password"
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              {loginResponse && (
                <MDBox mt={4} mb={1}>
                  <p>{loginResponse.message}</p>
                </MDBox>
              )}
              {loading && (
                <MDBox mt={4} mb={1}>
                  <CircularProgress />
                </MDBox>
              )}
              {!isFormValid && (
                <MDBox mt={4} mb={1}>
                  <p>{invalidResponse}</p>
                </MDBox>
              )}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                disable={loading}
                onClick={() => {
                  login();
                }}
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up/cover"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
