import axios from "axios";
import { HttpService } from "./httpService";
import { useHistory } from "react-router-dom";

export class UseAuth {
  // static history=useHistory()
  static getHeaderAndURL = () => {
    let baseURL = "https://api.bfamily.et/"; // Production URL
    let token = localStorage.getItem("token");

    let headers = {
      Authorization: "Bearer " + token, //the token is a variable which holds the token
      // IsDevelopment: true,
      IsDevelopment: false,
      ContentType: "application/json",
      Accept: "application/json",
    };

    return {
      baseUrl: baseURL,
      token: token,
      headers: headers,
    };
  };

  static verifyUser = async () => {
    let res = false;
    let relativePath = "/api/auth/isUserLoggedIn";
    let AdditionalData = {}; //{  credentials: 'include' }//header can be included here
    // console.log("verifyUser req",relativePath,AdditionalData)
    try {
      let Resp= await HttpService.getService(relativePath, AdditionalData)
          // console.log("verifyUser Resp", Resp);
          if (Resp.success) {
            res = true;
          } else {
            let path = "/authentication/sign-in/basic";
            if (location.pathname.indexOf("authentication/sign-in/basic") < 0) {
              // window.location.assign(path);
            }
            this.goToAuthPage("login")
          }
    } catch (error) {
      res = false;
    }
    // console.log("verifyUser http Resp",res)
    return res;
  };

  
  static goToAuthPage = async (pageD) => {
    try {
      let page=pageD||"login"
      // console.log("location", location);
      let baseUrl = "https://auth.dce.baally.com/"+page;
      let searchData = "";
      // searchData="?redirectTo="+location.origin+redirectTo
      searchData = "?redirectTo=" + location.href;
      if (location.hostname) {
        if (location.hostname == "localhost") {
          baseUrl = "http://localhost:8001/"+page;
        }
      }
      // window.location.assign(baseUrl+searchData);
      // window.open(baseUrl+searchData, '_blank');
      window.location.assign(baseUrl + searchData);
    } catch (error) {}
  };

  static verifyWebsite = async () => {
    let res = false;
    let relativePath = "/api/auth/isprofileloggedin";
    // let relativePath="/api/auth/isWebsiteLoggedIn";
    let AdditionalData = {}; //{  credentials: 'include' }//header can be included here
    // console.log("verifyUser req",relativePath,AdditionalData)
    try {
      await HttpService.getService(relativePath, AdditionalData)
        // .then(
        //   function( Resp ) {
        //     if(Resp.success){
        //     res=true
        //     }
        // },
        // function(error) {
        //   res=false
        // })
        .then(
          function (Resp) {
            if (Resp.success) {
              res = true;
            } else {
              let path = "/authentication/sign-in/basic";
              if (
                location.pathname.indexOf("authentication/sign-in/basic") < 0
              ) {
                //  window.location.assign(path);
              }
            }
          },
          function (error) {
            res = false;
            let path = "/authentication/sign-in/basic";
            if (location.pathname.indexOf("authentication/sign-in/basic") < 0) {
              // window.location.assign(path);
            }
          }
        );
    } catch (error) {
      res = false;
    }
    return res;
  };

  static verifyEmploymentAgency = async () => {
    let res = false;
    let relativePath = "/api/auth/isemploymentagencyLoggedIn";
    let AdditionalData = {}; //{  credentials: 'include' }//header can be included here
    // console.log("verifyUser req",relativePath,AdditionalData)
    try {
      await HttpService.getService(relativePath, AdditionalData).then(
        function (Resp) {
          if (Resp.success) {
            res = true;
          }
        },
        function (error) {
          res = false;
        }
      );
    } catch (error) {
      res = false;
    }
    return res;
  };

  static logout = async () => {
    let relativePath = "/api/authorization/logout";
    let AdditionalData = {}; //{  credentials: 'include' }//header can be included here
    await HttpService.getService(relativePath, AdditionalData).then(
      function (Resp) {
        localStorage.clear();
        if (Resp.success) {
          console.log("location", location);
          let baseUrl = "https://auth.baally.et/login";
          // let redirectTo=""
          let redirectTo = "/pages/my-profiles";
          let searchData = "";
          if (location.search) {
            let locationSearch = queryString.parse(location.search);
            if (locationSearch) {
              if (locationSearch.redirectTo) {
                redirectTo = locationSearch.redirectTo;
              }
              // setRouteSearch(locationSearch);
            }
          }
          searchData = "?redirectUrl=" + location.origin + redirectTo;
          searchData = searchData + "&authDeepth=businessaccount";

          if (location.hostname) {
            if (location.hostname == "localhost") {
              baseUrl = "http://localhost:5004/login";
            }
          }

          window.location.assign(baseUrl + searchData);
        }
      },
      function (error) {
        // console.log("usertype")
        // console.log(error)
      }
    );
  };

  static switchProfileById = async (id, navPath) => {
    // console.log(websiteData,"auth websiteData")
    let resp = { success: false, message: "not success", record: null };
    let data;
    let relativePath = "/api/authorization/logintoprofile";
    if (id) {
      // new ||new
      data = {
        ProfileId: id,
        // "userId":websiteData,
        // "command":"new"
        callerSite: "platformadminportal",
      };
    }

    let addUserToWebsiteRes = await this.postDocuments(
      "loginToWebsite",
      relativePath,
      data
    );
    if (addUserToWebsiteRes.success) {
      // let path =navPath|| "/";
      // window.location.assign(path);
      resp.success = true;
      resp.record = addUserToWebsiteRes.record;
    }
    return resp;
  };

  static loginToWebsite = async (websiteData) => {
    // console.log(websiteData,"auth websiteData")
    let resp = { success: false, message: "not success", record: null };
    let data;
    let relativePath = "/api/authorization/logintowebsite";
    if (websiteData.websiteId) {
      // new ||new
      data = {
        websiteId: websiteData.websiteId,
        // "userId":websiteData,
        // "command":"new"
        callerSite: "platformadminportal",
      };
    }

    let addUserToWebsiteRes = await this.postDocuments(
      "loginToWebsite",
      relativePath,
      data
    );
    if (addUserToWebsiteRes.success) {
      localStorage.clear();
      let path = "/";
      window.location.assign(path);
    }
    return resp;
  };

  static getIPService = (ipData) => {
    // const publicIp = require("public-ip");
    const publicIp = "";
    let headerAndURL = this.getHeaderAndURL();
    let baseUrl = headerAndURL.baseUrl + "bfam.et/api/";

    var relativePath2 = "IpDetail/Get?IpAddress=" + ipData;
    return axios.get(baseUrl + relativePath2, {
      headers: headerAndURL.headers,
    });
  };

  static getToken = () => {
    let baseUrl = this.getHeaderAndURL().baseUrl;
    let tokenApiUrl = "bfam/api/OAuth/Generate";
    return axios.post(
      baseUrl + tokenApiUrl,
      { Username: "me@gmail.com", Password: "123456" },
      {
        headers: {
          isdevelopment: "true",
          // isdevelopment: "false",
          ContentType: "application/json",
          Accept: "application/json",
        },
      }
    );
  };

  static checkToken() {
    const token = localStorage.getItem("token");

    if (token == null || token == "null") {
      return this.getToken().then((response) => {
        let newToken = response.data.token;
        // localStorage.setItem("holidaysToken", newToken);
      });
    } else {
      //  var decodedToken=jwt.decode(token, {complete: true});
      // var dateNow = new Date();
      // console.log(decodedToken);
      // console.log(decodedToken.payload.exp);
      // console.log(dateNow.getTime());
      //  if(decodedToken.payload.exp < dateNow.getUTCSeconds())
      //  {
      //   return this.getToken().then((response) => {
      //      let newToken =  response.data.token;
      //      localStorage.setItem("holidaysToken", newToken);
      //    })
      //  }
      return this.getToken().then((response) => {
        let newToken = response.data.token;
        // localStorage.setItem("holidaysToken", newToken);
      });
    }
  }

  static postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = await HttpService.postService(
      relativePath,
      data,
      {},
      ""
    );
    // console.log("documentsResponse ssss",relativePath,data  ,documentsResponse)
    if (documentsResponse.success) {
      responseD.success = true;
      if (documentsResponse.data) {
        responseD.record = documentsResponse.data.record;
        responseD.message = documentsResponse.data.message;
      }
    } else if (documentsResponse.data) {
      responseD.message = documentsResponse.data.message;
    }
    return responseD;
  };
}
